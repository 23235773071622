import React, { useEffect, useState } from 'react';
import './totalMaterials.scss';
import ContentTable from '../../components/contentTable/contentTable';
import { BASE_URL } from '../../constants/config'
import axios from 'axios';
import DownloadPage from '../../components/downloadPage/downloadPage';
import PrintComponent from './../../components/printComponent/printComponent';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { handleError } from '../../utilities/handleError';
import * as errorTypes from "../../utilities/errorTypes";

const TotalMaterials = () => {
    let history = useHistory()
    const [cycloCals, setCycloCals] = useState([])
    const [groupedCyclosTiles, setGroupedCyclosTiles] = useState([])

    const [floorCals, setFloorCals] = useState([])
    const [groupedFloorsTiles, setGroupedFloorsTiles] = useState([])

    const [wallCals, setWallCals] = useState([])
    const [groupedWallsFloorsTiles, setGroupedWallsFloorsTiles] = useState([])
    const [belts, setBelts] = useState([])
    const [groupedBeltsTiles, setGroupedBeltsTiles] = useState([])

    const [spaces, setSpaces] = useState([])
    const [groupedSpacesTiles, setGroupedSpacesTiles] = useState([])

    const [coloring, setColoring] = useState([])


    const getCyclos = () => {
        const arr = []
        axios.get(`${BASE_URL}/buyer/${localStorage.getItem('identifier')}/cycalo_calculations`, {
            headers: {
                "Accept": "application/json",
                Authorization: `Bearer ${localStorage.getItem('token')}`,

                "Accept-Language": 'ar'

            }
        }).then(res => {


            getTilesFromCyclos(res?.data)
            setCycloCals(res.data)
        }).catch(error => {
            const errorResult = handleError(error)
            const errMsg = errorResult.message;
            toast.error(errMsg, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            if (errorResult.type == errorTypes.unauthorized) {
                setTimeout(() => {

                    localStorage.clear();
                    history.push("/home");
                }, 1000);
            }

        })
    }

    const getTilesFromCyclos = (cyclos) => {
        const cyclosArr = []
        cyclos.map(el =>
            cyclosArr.push({
                ...el, 'width-height': `${el.tile_width}*${el.tile_length}`, 'no_of_tiles': el?.output?.no_of_tiles,
                'no_of_cycalos': el.output.no_of_cycalos
                , "quantity_mt": el?.output?.quantity_mt,
                "quantity_square": el?.output?.quantity_square
            })
        )



        let groupedCyclosTilesCpy = {}
        cyclosArr.forEach(el => {
            if ((Object.keys(groupedCyclosTilesCpy))?.includes(el['width-height'])) {
                groupedCyclosTilesCpy[el['width-height']] = [
                    groupedCyclosTilesCpy[el['width-height']][0] + el?.output?.no_of_tiles,
                    groupedCyclosTilesCpy[el['width-height']][1] + el.output.no_of_cycalos,
                    groupedCyclosTilesCpy[el['width-height']][2] + el.output.quantity_mt,
                    groupedCyclosTilesCpy[el['width-height']][3] + el.output.quantity_square,
                ]

            } else {
                groupedCyclosTilesCpy[el['width-height']] = [el?.output?.no_of_tiles, el.output.no_of_cycalos, el.output.quantity_mt, el.output.quantity_square]


            }

        })
        const entries = Object.entries(groupedCyclosTilesCpy);

        setGroupedCyclosTiles(entries)


    }
    const getTilesFromFloors = (floors) => {
        const floorsArr = []
        floors.map(el =>
            floorsArr.push({ ...el, 'width-height': `${el.tile_width}*${el.tile_length}`, "before_waste": el.output.before_waste, 'no_of_tiles': el?.output?.quantity, cyclo: el?.output?.cycalo_mt })
        )


        let groupedFloorsTilesCpy = {}
        floorsArr.forEach(el => {
            if ((Object.keys(groupedFloorsTilesCpy))?.includes(el['width-height'])) {
                groupedFloorsTilesCpy[el['width-height']] =
                    [

                        Number(groupedFloorsTilesCpy[el['width-height']][0]) + Number(el?.output?.before_waste),
                        Number(groupedFloorsTilesCpy[el['width-height']][1]) + Number(el?.output?.quantity)
                        , Number(groupedFloorsTilesCpy[el['width-height']][2]) + Number(el.output.cycalo_mt)]


            } else {

                groupedFloorsTilesCpy[el['width-height']] = [
                    el?.output?.before_waste,
                    el?.output?.quantity,
                    el.output.cycalo_mt]


            }
        })


        const entries = Object.entries(groupedFloorsTilesCpy);


        setGroupedFloorsTiles(entries)

    }
    const getTilesFromWallsFloors = (walls) => {
        const wallsArr = []
        walls.map(el =>
            wallsArr.push({ ...el, 'width-height': `${el.floor_tile_width}*${el.floor_tile_length}`, 'no_of_tiles': el?.output?.floor_quantity, cyclo: el?.output?.floor_cycalo_mt })
        )


        let groupedWallsFloorsTilesCpy = {}
        wallsArr.forEach(el => {
            if ((Object.keys(groupedWallsFloorsTilesCpy))?.includes(el['width-height'])) {
                groupedWallsFloorsTilesCpy[el['width-height']] =
                    [Number(groupedWallsFloorsTilesCpy[el['width-height']][0]) + Number(el?.output?.floor_quantity), Number(groupedWallsFloorsTilesCpy[el['width-height']][1]) + Number(el.output.floor_cycalo_mt)]


            } else {

                groupedWallsFloorsTilesCpy[el['width-height']] = [el?.output?.floor_quantity, el.output.floor_cycalo_mt]


            }
        })


        const entries = Object.entries(groupedWallsFloorsTilesCpy);


        setGroupedWallsFloorsTiles(entries)

    }
    const groupBy = (objectArray, property1, property2) => {
        return objectArray.reduce(function (acc, obj) {
            let key = obj[property1]
            if (!acc[key]) {
                acc[key] = Number(obj[property2])
            } else {
                acc[key] += Number(obj[property2])

            }
            // acc[key].push(obj)
            return acc
        }, {})
    }


    const getFloors = () => {

        axios.get(`${BASE_URL}/buyer/${localStorage.getItem('identifier')}/floors_calculations`, {
            headers: {
                "Accept": "application/json",
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                "Accept-Language": 'ar'

            }
        }).then(res => {
            getTilesFromFloors(res?.data)

            setFloorCals(res.data)
            // setFilteredFloorCals(res.data)
        }).catch(error => {
            const errorResult = handleError(error)
            const errMsg = errorResult.message;
            toast.error(errMsg, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            if (errorResult.type == errorTypes.unauthorized) {
                setTimeout(() => {

                    localStorage.clear();
                    history.push("/home");
                }, 1000);
            }

        })
    }
    const getWalls = () => {
        axios.get(`${BASE_URL}/buyer/${localStorage.getItem('identifier')}/wall_floor_calculations`, {
            headers: {
                "Accept": "application/json",
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                "Accept-Language": 'ar'

            }
        }).then(res => {

            // getTilesFromWallsFloors(res?.data)
            // getWalls(res.data)
            // getBelts(res.data)
            // getٍSpaces(res.data)
            // getٍColoring(res.data)
            setWallCals(res.data)



        }).catch(error => {
            const errorResult = handleError(error)
            const errMsg = errorResult.message;
            toast.error(errMsg, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            if (errorResult.type == errorTypes.unauthorized) {
                setTimeout(() => {

                    localStorage.clear();
                    history.push("/home");
                }, 1000);
            }

        })
    }

    useEffect(() => {
        getCyclos();
        getFloors();
        getWalls()
    }, []);

    const getBelts = (data) => {
        let belts = []
        data.forEach(el => {
            belts = [...belts, ...el.wall_straps]
        })
        let cpyBelts = belts.map(el => {
            return ({ tile_width: el.tile_width, tile_length: el.tile_length, 'width*height': `${el.tile_width}*${el.tile_length}`, id: el.id, tile_name: el.tile_name.name, output: el.output })
        })

        getTilesFromBelts(cpyBelts)
        setBelts(cpyBelts)

    }
    const getTilesFromBelts = (belts) => {
        const beltsArr = []
        belts.map(el =>
            beltsArr.push({ ...el, 'width-height': `${el.tile_width}*${el.tile_length}`, 'no_of_tiles': el?.output })
        )


        let groupedBeltsTilesCpy = {}
        beltsArr.forEach(el => {
            if ((Object.keys(groupedBeltsTilesCpy))?.includes(el['width-height'])) {
                groupedBeltsTilesCpy[el['width-height']] =
                    [Number(groupedBeltsTilesCpy[el['width-height']][0]) + Number(el?.output),
                    ]


            } else {

                groupedBeltsTilesCpy[el['width-height']] = [el?.output]


            }
        })


        const entries = Object.entries(groupedBeltsTilesCpy);


        setGroupedBeltsTiles(entries)

    }
    const getٍSpaces = (data) => {
        let spaces = []
        data.forEach(el => {
            spaces = [...spaces, ...el.wall_special_space]
        })
        let cpySpaces = spaces.map(el => {
            return ({ tile_width: el.tile_width, tile_length: el.tile_length, 'width*height': `${el.tile_width}*${el.tile_length}`, id: el.id, after_waste: el.after_waste, before_waste: el.before_waste })
        })

        getTilesFromSpaces(cpySpaces)
        setSpaces(cpySpaces)

    }
    const getTilesFromSpaces = (spaces) => {
        const spacesArr = []
        spaces.map(el =>
            spacesArr.push({ ...el, 'width-height': `${el.tile_width}*${el.tile_length}`, 'after_waste': el.after_waste, 'before_waste': el.before_waste })
        )


        let groupedSpacesTilesCpy = {}
        spacesArr.forEach(el => {
            if ((Object.keys(groupedSpacesTilesCpy))?.includes(el['width-height'])) {
                groupedSpacesTilesCpy[el['width-height']] =
                    [Number(groupedSpacesTilesCpy[el['width-height']][0]) + Number(el?.after_waste),
                    Number(groupedSpacesTilesCpy[el['width-height']][1]) + Number(el?.before_waste),
                    ]


            } else {

                groupedSpacesTilesCpy[el['width-height']] = [el?.after_waste, el?.before_waste]


            }
        })


        const entries = Object.entries(groupedSpacesTilesCpy);


        setGroupedSpacesTiles(entries)

    }
    const getٍColoring = (data) => {
        let coloring = []
        data.forEach(el => {
            coloring = [...coloring, ...el.coloring]
        })
        let cpyColoring = coloring.map(el => {
            return ({ id: el.id, columns: el.columns, name: el.name, num_of_tiles: el.num_of_tiles, rows: el.rows })
        })


        setColoring(cpyColoring)

    }
    const deleteFloor = (roomId) => {
        axios.delete(`${BASE_URL}/floor/${roomId}`, {
            headers: {
                "Accept": "application/json",
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                "Accept-Language": 'ar'

            }
        }).then(res => {
            let newFloors = floorCals.filter(floorCal => floorCal.id !== roomId)
            getTilesFromFloors(newFloors)
            setFloorCals(floorCals.filter(floorCal => floorCal.id !== roomId))
        }).catch(error => {
            const errorResult = handleError(error)
            const errMsg = errorResult.message;
            toast.error(errMsg, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            if (errorResult.type == errorTypes.unauthorized) {
                setTimeout(() => {

                    localStorage.clear();
                    history.push("/home");
                }, 1000);
            }

        })
    }

    const deleteWallFloor = (roomId) => {

        axios.delete(`${BASE_URL}/wall_floor/${roomId}`, {
            headers: {
                "Accept": "application/json",
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                "Accept-Language": 'ar'

            }
        }).then(res => {

            let newWalls = wallCals.filter(wallCal => wallCal.id !== roomId)
            // getTilesFromFloors(newFloors)
            setWallCals(wallCals.filter(wallCal => wallCal.id !== roomId))
        }).catch(error => {
            const errorResult = handleError(error)
            const errMsg = errorResult.message;
            toast.error(errMsg, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            if (errorResult.type == errorTypes.unauthorized) {
                setTimeout(() => {

                    localStorage.clear();
                    history.push("/home");
                }, 1000);
            }

        })
    }
    const deleteCyclo = (cycloId) => {
        axios.delete(`${BASE_URL}/cycalo/${cycloId}`, {
            headers: {
                "Accept": "application/json",
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                "Accept-Language": 'ar'

            }
        }).then(res => {
            let newCyclos = cycloCals.filter(cycloCal => cycloCal.id !== cycloId)
            getTilesFromCyclos(newCyclos)
            setCycloCals(cycloCals.filter(cycloCal => cycloCal.id !== cycloId))
        }).catch(error => {
            const errorResult = handleError(error)
            const errMsg = errorResult.message;
            toast.error(errMsg, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            if (errorResult.type == errorTypes.unauthorized) {
                setTimeout(() => {

                    localStorage.clear();
                    history.push("/home");
                }, 1000);
            }

        })
    }

    return (

        <><div className="container">
            <div className="d-flex gap-1 justify-content-center">

                {(cycloCals.length > 0 || floorCals.length > 0 || wallCals.length > 0) && <PrintComponent cyclos={cycloCals} floors={floorCals} walls={wallCals} groupedCyclosTiles={groupedCyclosTiles} groupedFloorsTiles={groupedFloorsTiles} />}
                {/* {(cycloCals.length > 0 || floorCals.length > 0) && <DownloadPage cyclos={cycloCals} floors={floorCals} />} */}
            </div>
        </div>
            <div className="table" id="table">
                {/* cyclo table */}
                <div id="cycloTable">
                    {
                        cycloCals.length > 0 &&
                        <>
                            <h3 className="room-total"> اجمالي السيكلو</h3>
                            <div className="total-materials">
                                <div className="total-materials-room">
                                    <ContentTable

                                        parentComponent={'materials'}
                                        deleteItem={deleteCyclo}
                                        refresh={getCyclos}
                                        headers={
                                            [
                                                // 'مسمي البلاطة',
                                                ' قياسات البلاطة',
                                                'عدد حبات السيكلو',
                                                'عدد الحبات المستخدمة',
                                                'الكمية متر طولي',
                                                'الكمية متر مربع',
                                                'تعديل',
                                                'حذف'
                                            ]}
                                        data={cycloCals} />
                                </div>
                            </div>
                        </>
                    }
                    {/* cyclo tiles table */}

                    {
                        groupedCyclosTiles.length > 0 &&
                        <>
                            <h3 className="room-total"> اجمالي الكمية</h3>
                            <div className="total-materials">
                                <div className="total-materials-room" id="cycloTable">
                                    <ContentTable

                                        parentComponent={'groupedCyclosTiles'}
                                        // deleteItem={deleteCyclo}
                                        // refresh={getCyclos}
                                        headers={
                                            [
                                                ' قياسات البلاطة',
                                                '  عدد حبات السيكلو ',
                                                ' عدد الحبات المستخدمه ',
                                                'الكميه متر طولي',
                                                'الكميه متر مربع'
                                            ]}
                                        data={groupedCyclosTiles} />
                                </div>
                            </div>
                        </>
                    }
                </div>


                <div id="floorTable">
                    {/* floor table */}
                    {
                        floorCals.length > 0 &&
                        <>
                            <h3 className="room-total"> اجمالي الارضيات</h3>
                            <div className="total-materials" >
                                <div className="total-materials-room">
                                    <ContentTable


                                        parentComponent={'roomMaterials'}
                                        deleteItem={deleteFloor}
                                        refresh={getFloors}
                                        headers={
                                            [
                                                'اسم الغرفة',
                                                'قياسات الغرفه',
                                                'مساحة الغرفة',
                                                'الكمية بعد الهدر',
                                                'السيكلو',
                                                'قياسات البلاطة',
                                                'زاوية التركيب',
                                                'بداية التركيب',
                                                'تعديل',
                                                'حذف']}
                                        data={floorCals} />
                                </div>
                            </div>
                        </>
                    }
                    {/* floor tiles table */}

                    {
                        groupedFloorsTiles.length > 0 &&
                        <>
                            <h3 className="room-total"> اجمالي البلاطات</h3>
                            <div className="total-materials">
                                <div className="total-materials-room" id="cycloTable">
                                    <ContentTable

                                        parentComponent={'groupedFloorsTiles'}
                                        // deleteItem={deleteCyclo}
                                        // refresh={getCyclos}
                                        headers={
                                            [
                                                'قياسات البلاطة',
                                                "مساحه الغرفه",
                                                'الكميه بعد الهدر ',
                                                '  السيكلو'
                                            ]}
                                        data={groupedFloorsTiles} />
                                </div>
                            </div>
                        </>
                    }
                </div>

                <div id="wallTable">
                    {/* wall table */}
                    {wallCals.length > 0 &&
                        <h3 className="room-total"> اجمالي الارضيات و  الجدران</h3>

                    }
                    {
                        wallCals.length > 0 &&
                        wallCals.map(el =>
                            < div key={el.id} style={{
                                border: '2px solid rgb(227 226 226)',
                                boxShadow: "rgb(223 227 227) 5px 10px 8px",
                                margin: "40px 0", padding: "22px ", borderRadius: "5px"
                            }}>
                                <h3 className="room-total"> اجمالي  الجدران</h3>
                                <div className="total-materials" >
                                    <div className="total-materials-room">
                                        <ContentTable


                                            parentComponent={'wallsMaterials'}
                                            deleteItem={deleteWallFloor}
                                            refresh={getWalls}
                                            headers={
                                                [
                                                    'اسم الغرفة',
                                                    'قياسات الغرفه',
                                                    ' الكمية قبل الهدر',
                                                    'الكمية بعد الهدر',
                                                    // 'السيكو',
                                                    'قياسات البلاطة',
                                                    'زاوية التركيب',
                                                    'بداية التركيب',
                                                    'تعديل',
                                                    'حذف']}
                                            data={el} />
                                    </div>
                                </div>
                                {
                                    el.coloring.length > 0 &&
                                    <>
                                        <h3 className="room-total">
                                            التلوين
                                        </h3>
                                        <h6 style={{ textAlign: "center" }}>
                                            تلوين  {el.coloring_type == 'rowsOnly' ? 'صفوف فقط' : el.coloring_type == 'colsOnly' ? 'اعمده فقط' : 'حر'}
                                        </h6>
                                        <div className="total-materials">
                                            <div className="total-materials-room" id="cycloTable">
                                                <ContentTable

                                                    parentComponent={'coloring'}
                                                    // deleteItem={deleteCyclo}
                                                    // refresh={getCyclos}
                                                    headers={
                                                        [
                                                            "التلوين",
                                                            'المساحه م2',
                                                            // ' طريفه التلوين ',

                                                        ]}
                                                    data={el.coloring} />
                                            </div>
                                        </div>
                                    </>
                                }
                                <h3 className="room-total">  اجمالي  للارضيات</h3>
                                <div className="total-materials">
                                    <div className="total-materials-room" id="cycloTable">
                                        <ContentTable

                                            parentComponent={'WallsFloorsTiles'}
                                            // deleteItem={deleteCyclo}
                                            // refresh={getCyclos}
                                            headers={
                                                [
                                                    'الارضيات قبل الهدر',
                                                    'الارضيات بعد الهدر',
                                                    ' قياسات  البلاطة',
                                                    ' زاويه التركيب',
                                                    'بدايه التركيب  '
                                                ]}
                                            data={{ floor_quantity_after_waste: el.output && el.output.floor_quantity_after_waste, floor_quantity: el.output && el.output.floor_quantity, floor_tile_length: el.floor_tile_length, floor_tile_width: el.floor_tile_width, installation_angle: el.installation_angle.name_ar, installation_start: el.installation_start.name_ar, id: el.id }} />
                                    </div>
                                </div>
                                {
                                    el.wall_straps.length > 0 &&
                                    <>
                                        <h3 className="room-total">
                                            اجمالي الاحزمه
                                        </h3>
                                        <div className="total-materials">
                                            <div className="total-materials-room" id="cycloTable" >
                                                <ContentTable

                                                    parentComponent={'belt'}
                                                    // deleteItem={deleteCyclo}
                                                    // refresh={getCyclos}
                                                    headers={
                                                        [
                                                            "",
                                                            'قياسات البلاطة',
                                                            // " مسمي البلاطه",
                                                            'عدد الحبات المستخدمة',
                                                        ]}
                                                    data={el.wall_straps} />
                                            </div>
                                        </div>
                                    </>
                                }
                                {
                                    el.wall_special_space.length > 0 &&
                                    <>

                                        <h3 className="room-total">
                                            اجمالي المساحات الخاصه
                                        </h3>
                                        <div className="total-materials">
                                            <div className="total-materials-room" id="cycloTable">
                                                <ContentTable

                                                    parentComponent={'spaces'}

                                                    headers={
                                                        [
                                                            "",
                                                            'ارتفاع وعرض المساحه',
                                                            " قياسات البلاطه",
                                                            ' المساحه بعد الهدر   ',
                                                            ' المساحه قبل الهدر   ',
                                                            'زاويه التركيب',
                                                            'بدايه التركيب'
                                                        ]}
                                                    data={el.wall_special_space} />
                                            </div>
                                        </div>
                                    </>
                                }


                            </div>
                        )
                    }



                </div>

            </div>
        </>
    )
}
export default TotalMaterials;