import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap';
import { ReactComponent as Close } from '../../../../assets/Icon ionic-ios-close-circle-outline.svg';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { BASE_URL } from "../../../../constants/config"
import 'react-toastify/dist/ReactToastify.css';
import useSignUpForm from '../../../../hooks/useSignUpForm';

import "./add-users.company.scss"
import { useHistory } from "react-router-dom"
import { handleError } from '../../../../utilities/handleError';
import * as errorTypes from "../../../../utilities/errorTypes"
const AddUsersCompany = (props) => {
  let history = useHistory();
  const { handleInputChange, inputs, clearInputs } = useSignUpForm(
    {
      name: '',
      nameIsValid: false,
      nameIsTouched: false,
      email: '',
      emailIsValid: false,
      emailIsTouched: false,
      password: '',
      passwordIsValid: false,
      passwordIsTouched: false,
      // name: '',
      // nameIsValid: false,
      // nameIsTouched: false,


    }

  )
  const handleModalClose = () => {
    props.handleClose();
    clearInputs()
    setRole(1)
  }
  const [company_id, setCompanyId] = useState(null)
  // let history = useHistory();
  const [role, setRole] = useState(1)

  const checkFormValidation = () => {
    return inputs.nameIsValid && inputs.emailIsValid && inputs.passwordIsValid;
  }
  const handleRoleChange = (e) => {
    setRole(e.target.value)
  }

  const onSubmit = () => {
    const formData = new FormData();
    formData.append('name', inputs.name);
    formData.append('email', inputs.email);

    formData.append('password', inputs.password);
    if (role == 2) {

      formData.append('company_id', props.id);
    }
    formData.append('role_id', role);
    axios.post(`${BASE_URL}/users/create
    `,
      formData, {
      headers: {

        "Accept": "application/json",
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        "Accept-Language": 'ar'
      }

    }).then((res) => {

      handleModalClose()

    })
      .catch(error => {
        const errorResult = handleError(error)
        const errMsg = errorResult.message;
        toast.error(errMsg, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (errorResult.type == errorTypes.unauthorized) {
          setTimeout(() => {

            localStorage.clear();
            history.push("/home");
          }, 1000);
        }

      })
  }
  return (
    // onShow={() => initCompany(props.id, props.mode)} 
    <Modal show={props.show}
      dialogClassName="edit-modal modal-lg add-users-company-modal">
      <ToastContainer />
      <Modal.Header>
        <Modal.Title>
          <Close className="close-icon" onClick={() => handleModalClose()} />
          <h4>اضافه مستخدم</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="add-users-company">
          <div className="form-group">
            <input type="text" name="name" value={inputs.name} onChange={(e) => handleInputChange(e)} />
            <span>الاسم  </span>
            {!inputs.nameIsValid && inputs.nameIsTouched && <span className="error-msg">  الاسم مطلوب</span>}
          </div>
          <div className="form-group">
            <input name="email" value={inputs.email} onChange={(e) => handleInputChange(e)} />
            <span> البريد الالكتروني</span>
            {!inputs.emailIsValid && inputs.emailIsTouched && <span className="error-msg">البريد الالكتروني خطأ</span>}
          </div>
          <div className="form-group">
            <input type="password" name="password" value={inputs.password} onChange={(e) => handleInputChange(e)} />
            <span>كلمة المرور</span>
          </div>
          <div className="form-group ">

            <div className='role'>

              <div>

                <span >
                  ادمن
                </span>
                <input type="radio" name="role" value={1} onChange={(e) => handleRoleChange(e)} checked={role == 1} />
              </div>
              <div>

                <span>
                  شركه</span>
                <input type="radio" name="role" value={2} onChange={(e) => handleRoleChange(e)} checked={role == 2} />
              </div>
            </div>
            <span>

              الدور

            </span>
          </div>
        </div>

        <Button disabled={!checkFormValidation()} onClick={() => onSubmit()}
          className={checkFormValidation() ? "add-btn" : "add-btn disabled"}
          variant="primary">اضافه </Button>
      </Modal.Body>
      <Modal.Footer>

      </Modal.Footer>
    </Modal>
    // <div>add</div>
  )
}
export default AddUsersCompany;