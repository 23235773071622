import React from 'react';
import './installChoices.scss';
// import { ReactComponent as Angel } from '../../assets/Group 3506.svg';
import { ReactComponent as Type } from '../../assets/Rectangle 1051.svg';
import { ReactComponent as Typee2 } from '../../assets/Group 3624.svg';
import { ReactComponent as Start } from '../../assets/Group 3520.svg';
import { ReactComponent as Half } from '../../assets/nos l 8orfa.svg';
import { ReactComponent as Width_45 } from '../../assets/45 3ardy.svg';
import { ReactComponent as Length_45 } from '../../assets/45 tolly.svg';
import { ReactComponent as Length } from '../../assets/tolly.svg';
import { ReactComponent as Width } from '../../assets/3ardy.svg';

const InstallChoices = ({
    parent,
    beltsCount,
    hideStackingMethod,
    installationAngles,
    installationStarts,
    installationStyles,
    installation_angle_id,
    installation_start_id,
    installation_style_id,
    handleInputChange,
    ////
    belt,
    changeBeltCount,
    changeBeltStyle,
    belts,
    //special spaces
    specialSpace,
    specialSpaces,
    changeSpecialSpaceAngle,
    changeSpecialSpaceStart,
    // changeSpecialSpaceCount
}) => {
    return (
        <div className="choices">
            <label>اختيارات التركيب</label>

            {(parent != 'belt' && parent != 'specialSpace') &&
                <div className="choices-angel">
                    {
                        parseInt(installation_angle_id) === 1 ? <Width_45 /> :
                            parseInt(installation_angle_id) === 2 ? <Length_45 /> :
                                parseInt(installation_angle_id) === 3 ? <Width style={{ width: "12%", height: "12%" }} /> : <Length style={{ width: "12%", height: "12%" }} />
                    }
                    <div className="choices-angel-details">
                        <select name="installation_angle_id" value={installation_angle_id} onChange={(e) => handleInputChange(e)} className="select">
                            {
                                installationAngles.map(angle => {
                                    return (
                                        <option key={angle.id} value={angle.id}>{angle.name_ar}</option>
                                    )
                                })
                            }
                        </select>
                        <span>زاوية التركيب</span>
                    </div>
                </div>
            }

            {
                // hideStackingMethod === false &&
                // <div className="choices-type">
                //     {/* <Type /> */}
                //     {parseInt(installation_style_id) === 1 ? <Type /> : "cccc"}

                //     <div className="choices-type-details">
                //         <select className="select" name='installation_style_id' value={installation_style_id}
                //             onChange={(e) => handleInputChange(e)}>
                //             {/* <option>طولي</option>
                //             <option>عرضي</option> */}
                //             {
                //                 installationStyles.map(style => {
                //                     return (
                //                         <option key={style.id} value={style.id}>{style.name_ar}</option>
                //                     )
                //                 })
                //             }
                //         </select>
                //         <span>طريقة الرص</span>
                //     </div>
                // </div>
            }
            {(parent != 'belt' && parent != 'specialSpace') &&
                <div className="choices-start">
                    {
                        parseInt(installation_start_id) === 1 ?
                            <Start className="fig" /> :
                            <Half className="fig" />
                    }
                    <div className="choices-start-details">
                        <select name="installation_start_id" value={installation_start_id} onChange={(e) => handleInputChange(e)} className="select">
                            {
                                installationStarts.map(type => {
                                    return (
                                        <option key={type.id} value={type.id}>{type.name_ar}</option>
                                    )
                                })
                            }
                        </select>
                        <span>بداية التركيب</span>
                    </div>
                </div>}
            {
                parent && parent == 'belt' && belt &&
                <>
                    <div className="choices-type">
                        {/* <Type /> */}
                        {parseInt(belt.installation_style_id) === 1 ? <Type /> : <Typee2 />}

                        <div className="choices-type-details">
                            <select className="select" name={belt.id} value={belt.installation_style_id}

                                onChange={(e) => changeBeltStyle(e.target)}>
                                {/* <option>طولي</option>
                            <option>عرضي</option> */}
                                {
                                    installationStyles.map(style => {
                                        return (
                                            <option key={style.id} value={style.id} disabled={belts.length > 1 && belts[0].installation_style_id != style.id}>{style.name_ar}</option>
                                        )
                                    })
                                }
                            </select>
                            <span>طريقة تركيب الحزام</span>
                        </div>
                    </div>
                    <div>
                        <input type='number' name={belt.id} value={belt.beltsCount} onChange={(e) => changeBeltCount(e.target)} style={{ marginRight: "1rem" }} />
                        <span className="def">تكرار الحزام في الجدار </span>
                        {!belt.beltsCountIsValid && belt.beltsCountIsTouched && <span className="error-msg">العدد   خطأ'</span>}

                    </div>
                </>
            }

            {
                parent && parent == 'specialSpace' && specialSpace &&
                <>


                    <div className="choices-angel">
                        {
                            parseInt(specialSpace.installation_angle_id) === 1 ? <Width_45 /> :
                                parseInt(specialSpace.installation_angle_id) === 2 ? <Length_45 /> :
                                    parseInt(specialSpace.installation_angle_id) === 3 ? <Width style={{ width: "12%", height: "12%" }} /> : <Length style={{ width: "12%", height: "12%" }} />
                        }
                        <div className="choices-angel-details">
                            <select name={specialSpace.id} value={specialSpace.installation_angle_id} onChange={(e) => changeSpecialSpaceAngle(e.target)} className="select">
                                {
                                    installationAngles.map(angle => {
                                        return (
                                            <option key={angle.id} value={angle.id}>{angle.name_ar}</option>
                                        )
                                    })
                                }
                            </select>
                            <span>زاوية التركيب</span>
                        </div>
                    </div>

                    <div className="choices-start">
                        {
                            parseInt(specialSpace.installation_start_id) === 1 ?
                                <Start className="fig" /> :
                                <Half className="fig" />
                        }
                        <div className="choices-start-details">
                            <select name={specialSpace.id} value={specialSpace.installation_start_id} onChange={(e) => changeSpecialSpaceStart(e.target)} className="select">
                                {
                                    installationStarts.map(type => {
                                        return (
                                            <option key={type.id} value={type.id}>{type.name_ar}</option>
                                        )
                                    })
                                }
                            </select>
                            <span>بداية التركيب</span>
                        </div>
                    </div>
                    {/* <div>
                        <input type='number' name={specialSpace.id} value={specialSpace.specialSpacesCount} onChange={(e) => changeSpecialSpaceCount(e.target)} style={{ marginRight: "1rem" }} />
                        <span className="def">عدد الأحزمة</span>
                    </div> */}
                </>
            }
        </div>
    )
}
export default InstallChoices;