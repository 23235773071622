import React, { useState, useEffect } from 'react';
import ContentTable from '../../contentTable/contentTable';
import axios from 'axios';
import { BASE_URL } from '../../../constants/config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./client-list.scss";
import { useHistory } from "react-router-dom";
import { handleError } from '../../../utilities/handleError';
import * as errorTypes from "../../../utilities/errorTypes"

const ClientList = () => {
  let history = useHistory();

  const [clientList, setClientList] = useState([

  ])
  const [filteredData, setFilteredData] = useState([

  ])
  const [searchVal, setSearchVal] = useState("")
  const handleSearch = (e) => {

    setSearchVal(e.target.value)

    let filterData = clientList?.filter(el => (el.identifier).includes(e.target.value) || (el.phone).includes(e.target.value))


    setFilteredData(filterData)

  }
  const getClientsList = () => {
    axios.get(`${BASE_URL}/buyer`, {
      headers: {
        "Accept": "application/json",
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        "Accept-Language": 'ar'

      }
    }).then(res => {
      setClientList(res.data);
      setFilteredData(res.data)
      // let newCyclos = cycloCals.filter(cycloCal => cycloCal.id !== cycloId)
      // getTilesFromCyclos(newCyclos)
      // setCycloCals(cycloCals.filter(cycloCal => cycloCal.id !== cycloId))
    }).catch(error => {
      const errorResult = handleError(error)
      const errMsg = errorResult.message;
      toast.error(errMsg, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (errorResult.type == errorTypes.unauthorized) {
        setTimeout(() => {

          localStorage.clear();
          history.push("/home");
        }, 1000);
      }

    })
  }
  useEffect(() => {
    getClientsList()
  }, [])
  const deleteClient = (id) => {
    // axios.delete(`${BASE_URL}/cycalo/${cycloId}`, {
    //     headers: {
    //         "Accept": "application/json",
    //         Authorization: `Bearer ${localStorage.getItem('token')}`,
    //         "Accept-Language": 'ar'

    //     }
    // }).then(res => {
    //     let newCyclos = cycloCals.filter(cycloCal => cycloCal.id !== cycloId)
    //     getTilesFromCyclos(newCyclos)
    //     setCycloCals(cycloCals.filter(cycloCal => cycloCal.id !== cycloId))
    // })
    let newClients = clientList.filter(item => item.id !== id)

    setClientList(newClients)
  }
  return (<div className='client-list'>
    {clientList &&
      <div className='search-container d-flex justify-content-end'>

        <input className="search-input" placeholder=" رقم النموذج او التليفون" value={searchVal} onChange={(e) => handleSearch(e)} />
        {/* <Search className="search-icon" /> */}
      </div>
    }
    <ContentTable
      parentComponent={'clientsList'}
      deleteItem={deleteClient}
      refresh={getClientsList}
      headers={
        [
          "",
          "identifier",
          "الاسم",
          "البريد الالكتروني",
          "العنوان",
          "رقم الهاتف",
          "rooms_count"
          ,
        ]
      }
      data={filteredData}
    />
  </div>)
}
export default ClientList;