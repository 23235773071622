import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { ReactComponent as Close } from '../../assets/Icon ionic-ios-close-circle-outline.svg';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import "./resetPassword.scss"
const ResetPassword = (props) => {

  //new password
  //props.password
  const [msg, setMsg] = useState("")
  const copyText = () => {

    navigator.clipboard.writeText(props.password);
    setMsg("done")
  }
  return (
    <>
      <ToastContainer />
      <Modal show={props.show} onHide={props.handleClose} dialogClassName="reset-password-modal" dir="ltr">
        <Modal.Header>
          <Close className="close-icon" onClick={() => props.handleClose()} />
          <Modal.Title>
            <h4>كلمة المرور  الجديده</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

            // register section


            <div className="reset-password">
              {/* <h6>انسخ كلمة المرور </h6> */}
              <div className='form-control-item my-2'>
                <button type="button" class="btn btn-transparent" data-toggle="tooltip" data-placement="top" title={msg == 'done' ? "تم النسخ" : "انسخ"}>
                  <FontAwesomeIcon icon={faCopy} onClick={() => copyText()} style={{ cursor: "pointer", color: "#390239" }} />

                </button>
                <input type='text' disabled
                  value={props.password}

                />
                <span>كلمة المرور الجديده </span>
              </div>

            </div>
          }



        </Modal.Body>
        <Modal.Footer>
          {/* {
              modalType === 'login' ?
                  <span>ليس لديك حساب ؟ <Link to="" onClick={() => setModalType('reg')} className="">انشاء حساب</Link></span> :
                  <span>لديك حساب ؟<Link to="" onClick={() => setModalType('login')} className="">تسجيل الدخول</Link></span>
          } */}
        </Modal.Footer>
      </Modal>
    </>

  )
}
export default ResetPassword;