import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import './room.scss';
import * as actionCreators from '../../store/actions/actionCreators';
import { useSelector, useDispatch } from 'react-redux';
import Doors from "../../components/doors/doors"
import { ReactComponent as Shape1 } from '../../assets/Rectangle 1048.svg';
import { ReactComponent as Shape2 } from '../../assets/Rectangle 1056.svg';
import { ReactComponent as Shape3 } from '../../assets/Path 4716.svg';
import { ReactComponent as Shape4 } from '../../assets/Path 4715.svg';
import { ReactComponent as Shape5 } from '../../assets/Path 4717.svg';
import { ReactComponent as Shape55 } from '../../assets/Group 3634.svg';
import { ReactComponent as Shape44 } from '../../assets/Group 3635.svg';
import { ReactComponent as Shape33 } from '../../assets/8l.svg';

import { ReactComponent as Shape22 } from '../../assets/Group 3636.svg';
import ContentTable from '../../components/contentTable/contentTable';
import useSignUpForm from '../../hooks/useSignUpForm'
import axios from 'axios';
import { BASE_URL } from '../../constants/config'
import validate from '../../utilities/validation';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrintComponent from "../../components/printComponent/printComponent";
import { handleError } from "../../utilities/handleError";
import * as errorTypes from "../../utilities/errorTypes"
var doorId = 0;
const Room = () => {
    let history = useHistory();
    const currentRooms = useSelector(state => state.roomReducer.rooms);
    const dispatch = useDispatch();

    const doorUniqueId = () => {
        doorId += 1
        return doorId;
    }

    const { handleInputChange, inputs, clearInputs, setInputsValues } = useSignUpForm(
        {
            name: '',
            nameIsValid: false,
            nameIsTouched: false,
            height: 0,
            heightIsValid: false,
            heightIsTouched: false,
        }
    )

    const checkFormValidation = () => {

        let heightValid = roomHeigthState ? inputs.heightIsValid : true
        return checkWallLengthValidation() &&
            inputs.nameIsValid && doors.map(door => door.widthValidation && door.countValidation) && heightValid
    }

    const [contentTableData, setContentTableData] = useState([]);

    // room heigth section
    const [roomHeigthState, setRoomHeigthState] = useState(false)

    // walls section
    const [walls, setWalls] = useState([]);
    const [selectedShapeComponent, setSelectedShapeComponent] = useState(<Shape1 />);
    const [selectedShapeName, setSelectedShapeName] = useState('Shape1');

    useEffect(() => {
        if (sessionStorage.getItem("rooms")) {
            let rooms = JSON.parse(sessionStorage.getItem("rooms"))
            setContentTableData(rooms)
        }
        setSelectedShape('Shape1', <Shape1 />, 4)
    }, []);
    const checkWallLengthValidation = () => {
        let isValid = walls.every(el => el.length > 0)

        return isValid
    }
    const changeWallLength = (target) => {


        let wallsCopy = JSON.parse(JSON.stringify(walls))


        let index = wallsCopy.findIndex(wall => wall.number == parseInt(target.name))

        wallsCopy[index]['length'] = parseFloat(target.value);

        switch (selectedShapeName) {

            case 'Shape5':


                wallsCopy[0].length = Number.parseFloat(wallsCopy[2].length + wallsCopy[4].length + wallsCopy[6].length).toFixed(2)
                break;
            case 'Shape4':
                wallsCopy[0].length = Number.parseFloat(wallsCopy[2].length + wallsCopy[4].length).toFixed(2);
                wallsCopy[5].length = Number.parseFloat(wallsCopy[1].length + wallsCopy[3].length).toFixed(2);
                break;
            case 'Shape3':
                wallsCopy[0].length = Number.parseFloat(wallsCopy[2].length + wallsCopy[4].length + wallsCopy[6].length).toFixed(2);
                wallsCopy[5].length = wallsCopy[3].length
                wallsCopy[7].length = wallsCopy[1].length
                break;
            case 'Shape2':
                wallsCopy[2].length = wallsCopy[0].length;
                wallsCopy[3].length = wallsCopy[1].length
                break;
            case 'Shape1':
                wallsCopy[1].length = wallsCopy[0].length;
                wallsCopy[2].length = wallsCopy[0].length
                wallsCopy[3].length = wallsCopy[0].length
                break;
            default:
                break;
        }
        setWalls(wallsCopy)
    }
    const setDisabledEdges = (edgeNumber) => {
        let res = false
        if (selectedShapeName === 'Shape5' && edgeNumber === 1) {
            res = true;
        }
        else if (selectedShapeName === 'Shape4' && (edgeNumber === 1 || edgeNumber === 6)) {
            res = true
        }
        else if (selectedShapeName === 'Shape3' && (edgeNumber === 1 || edgeNumber === 6 || edgeNumber === 8)) {
            res = true
        }
        else if (selectedShapeName === 'Shape2' && (edgeNumber === 3 || edgeNumber === 4)) {
            res = true;
        }
        else if (selectedShapeName === 'Shape1' && (edgeNumber === 2 || edgeNumber === 3 || edgeNumber === 4)) {
            res = true;
        }
        else {
            res = false
        }
        return res;
    }

    // doors section
    const [doors, setDoors] = useState([
        {
            id: doorUniqueId(),
            width: 0,
            count: 1,
            widthValidation: true,
            countValidation: true,
        }
    ]);

    const addDoor = () => {
        let doorsCopy = [...doors]
        doorsCopy.push({ id: doorUniqueId(), width: 0, count: 1, widthValidation: true, countValidation: true })
        setDoors(doorsCopy)
    }
    const deleteDoor = (id) => {
        let doorsCopy = JSON.parse(JSON.stringify(doors))
        let filteredDoors = doorsCopy.filter(el => el.id != id)
        setDoors(filteredDoors)

    }
    // const deleteDoor = (id) => {
    //     let doorsCopy = [...doors];
    //     let doorIndex = doorsCopy.findIndex(door => door.id === id);
    // }

    const changeDoorValue = (target) => {
        let doorsCopy = [...doors];
        doorsCopy[doorsCopy.findIndex(door => door.id === parseInt(target.name))].widthValidation = validate('doorsWidth', target.value);
        doorsCopy[doorsCopy.findIndex(door => door.id === parseInt(target.name))].width = parseFloat(target.value);
        setDoors(doorsCopy)
    }

    const changeDoorCount = (target) => {
        let doorsCopy = [...doors]
        doorsCopy[doorsCopy.findIndex(door => door.id === parseInt(target.name))].countValidation = validate('doorsCount', target.value);
        doorsCopy[doorsCopy.findIndex(door => door.id === parseInt(target.name))].count = parseFloat(target.value);
        setDoors(doorsCopy)
    }

    const clearForm = () => {
        setDoors([{
            id: doorUniqueId(),
            width: 0,
            count: 1,
            widthValidation: true,
            countValidation: true
        }])
        setWalls([])
        setSelectedShapeComponent('Shape1')
        setSelectedShapeName('Shape1')
        setSelectedShape('Shape1', <Shape1 />, 4)
        // setRoomHeigthState(false)
        clearInputs()
        // setContentTableData([])
    }

    let show = roomHeigthState;
    const showRoomHeight = () => {
        show = !show;
        setRoomHeigthState(!roomHeigthState)
        if (!show) {
           
            setInputsValues({
                ...inputs,
                height: 0,
                heightIsValid: true,
                heightIsTouched: true

            })
           

        } else {
            setInputsValues({
                ...inputs,
                height: 1,
                heightIsValid: true,
                heightIsTouched: true

            })
           

        }
    }

    const onSaveHandler = () => {
        let wallsObj = {};
        walls.map(wall => {
            wallsObj = Object.assign(wallsObj, { [`wall_${wall.number}`]: wall.length })
        })
        axios.post(BASE_URL + '/room/create',
            {
                buyer_id: localStorage.getItem('id') || sessionStorage.getItem('id'),
                name: inputs.name,
                height: inputs.height,
                shape: selectedShapeName,
                walls: wallsObj,
                doors: doors.map(({ id, ...item }) => item),
                windows: [{
                    "width": 1.0,
                    "height": 1.0,
                    "count": 2
                }]
            },
            {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    "Accept-Language": 'ar'

                }
            }).then(res => {
                let totalWalls = 0;
                let i = 1;
                for (const key in res.data.walls) {
                    if (key === `wall_${i}` && res.data.walls[`wall_${i}`] !== null) {
                        totalWalls = totalWalls + res.data.walls[`wall_${i}`];
                        i = i + 1;
                    }
                }
                let result = [...contentTableData];
                result.push({
                    id: res.data.id,
                    name: res.data.name,
                    cyclo: res.data.area.cyclo,
                    floorArea: res.data.area.floorArea,
                    wallArea: res.data.area.wallArea
                })
                setContentTableData(result);
                sessionStorage.setItem("rooms", JSON.stringify(result))
                dispatch(actionCreators.addRoom({
                    id: res.data.id,
                    name: inputs.name,
                    height: show ? inputs.height : 0,
                    walls: walls,
                    doors: doors,
                    selectedShapeName: selectedShapeName,
                    selectedShapeComponent: selectedShapeComponent
                }))
                toast.success('تم الحفظ', {
                    position: "top-right",
                    autoClose: 0,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch(error => {
                const errorResult = handleError(error)
                const errMsg = errorResult.message;
                toast.error(errMsg, {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                if (errorResult.type == errorTypes.unauthorized) {
                    setTimeout(() => {

                        localStorage.clear();
                        history.push("/home");
                    }, 1000);
                }
          
            })
    }

    const setSelectedShape = (name, component, values) => {
        let obj = [];
        for (let index = 0; index < values; index++) {
            const element = {
                number: index + 1,
                length: ""
            }
            obj.push(element)
        }
        setWalls(obj)
        setSelectedShapeComponent(component)
        setSelectedShapeName(name)
    }

    const saveForm = () => {
        history.push("/client-orders");
    }

    // const addRoomHandler = () => {
    //     dispatch(actionCreators.addRoom({
    //         id: res.data.id,
    //         name: inputs.name,
    //         height: inputs.height,
    //         walls: walls,
    //         doors: doors,
    //         selectedShapeName: selectedShapeName,
    //         selectedShapeComponent: selectedShapeComponent
    //     }))
    //     clearForm();
    // }

    const getRoom = (id) => {
        let roomToBeFound = currentRooms.find(singleRoom => singleRoom.id === id)
        setDoors(roomToBeFound.doors)
        setWalls(roomToBeFound.walls)
        setSelectedShapeName(roomToBeFound.selectedShapeName)
        setSelectedShapeComponent(roomToBeFound.selectedShapeComponent)
        setInputsValues({
            name: roomToBeFound.name,
            nameIsValid: true,
            nameIsTouched: true,
            height: roomToBeFound.height,
            heightIsValid: true,
            heightIsTouched: true,
        })
    }

    const deleteRoom = (id) => {
       

        axios.delete(BASE_URL + '/room/' + id, {
            headers: {
                "Accept-Language": 'ar'

            }
        }).then(res => {
           
            let rooms = contentTableData.filter(room => room.id != id)
            sessionStorage.setItem("rooms", JSON.stringify(rooms))

            dispatch(actionCreators.deleteRoom(id))
            setContentTableData(rooms)
            clearForm();
        }, err => {
           

        })

    }

    return (
        <div className="room-container">
            <ToastContainer />

            <div className="room-container-section">
                <label>بيانات الغرفة</label>
                <div className="room">
                    <div className="room-name">
                        <div style={{ display: "flex", flexDirection: "column", width: "84%" }}>
                            <input
                                name="name"
                                value={inputs.name}
                                onChange={(e) => handleInputChange(e)} />
                            {!inputs.nameIsValid && inputs.nameIsTouched && <span className="error-msg">اسم الغرفة خطأ</span>}
                        </div>
                        <span className="def">اسم الغرفة</span>
                    </div>
                    <div className="room-shape">
                        <Shape5 onClick={() => setSelectedShape('Shape5', <Shape55 />, 8)} className={selectedShapeName === 'Shape5' ? "shape selected" : "shape"} />
                        <Shape4 onClick={() => setSelectedShape('Shape4', <Shape44 />, 6)} className={selectedShapeName === 'Shape4' ? "shape selected" : "shape"} />
                        <Shape3 onClick={() => setSelectedShape('Shape3', <Shape33 />, 8)} className={selectedShapeName === 'Shape3' ? "shape selected" : "shape"} />
                        <Shape2 onClick={() => setSelectedShape('Shape2', <Shape22 />, 4)} className={selectedShapeName === 'Shape2' ? "shape selected" : "shape"} />
                        <Shape1 onClick={() => setSelectedShape('Shape1', <Shape1 />, 4)} className={selectedShapeName === 'Shape1' ? "shape selected" : "shape"} />
                        <span className="def"> اختيار شكل الغرفة </span>
                    </div>
                    <div className="room-measures">
                        <div className="room-measure-shape">
                            {selectedShapeComponent}
                        </div>
                        <div className="room-measures-inputs">
                            <label>قياسات الغرفة</label>
                            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginBottom: "1rem" }}>
                                <span style={{ marginInlineEnd: "1rem" }}>اضافة ارتفاع للغرفة</span>
                                <input onChange={() => showRoomHeight()} style={{ width: "1rem", marginInlineEnd: "unset", cursor: "pointer" }} type="checkbox" />
                            </div>
                            {
                                roomHeigthState &&
                                <div className="room-measures-inputs-single">
                                    <span className="def">ارتفاع الجدار</span>
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                        <input
                                            min={1}
                                            style={{ marginInlineEnd: "1.5rem" }}
                                            name="height"
                                            value={inputs.height}
                                            onChange={(e) => handleInputChange(e)} />
                                        {!inputs.heightIsValid && inputs.heightIsTouched && <span className="error-msg">ارتفاع الغرفة خطأ</span>}
                                    </div>
                                    <span className="unit">متر</span>
                                </div>
                            }
                            {/* {!checkWallLengthValidation() && <span className="error-msg">طول الضلع خطأ</span>
                            } */}
                            {
                                walls.map(wall => {
                                    return (
                                        <div key={wall.number} className="room-measures-inputs-single">
                                            <span className="def">ضلع {wall.number}</span>
                                            <input
                                                // placeholder='1'
                                                min={1}
                                                disabled={setDisabledEdges(wall.number)}
                                                type="number"
                                                onWheel={(e) => e.target.blur()}
                                                name={wall.number}
                                                value={wall.length}
                                                onChange={(e) => changeWallLength(e.target)} />
                                            <span className="unit">متر</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    {!roomHeigthState &&
                        <Doors
                            doors={doors}
                            addDoor={addDoor}
                            deleteDoor={deleteDoor}
                            changeDoorValue={changeDoorValue}
                            changeDoorCount={changeDoorCount}
                            parentComponent={'cyclo'}
                        />}
                </div>
                <div className="room-container-section-btns">
                    {/* <div className="add-new-room">
                        <span>اضافة غرفة جديدة</span>
                        <Plus />
                    </div> */}
                    <button onClick={() => clearForm()} className="room-container-section-btns-clear">مسح البيانات</button>
                    <button disabled={!checkFormValidation()}
                        onClick={() => onSaveHandler()}
                        className={checkFormValidation() ? "room-container-section-btns-calc" : "room-container-section-btns-calc disabled"} >حفظ الغرفة</button>
                </div>
            </div>
            <div className="room-container-total">

                <PrintComponent roomsWithHieght={contentTableData.filter(el => el.wallArea != 0)}
                    roomsWithoutHieght={contentTableData.filter(el => el.wallArea == 0)} />
                {/* <div className="room-container-total-save">
                    <button onClick={() => { saveForm() }}>حفظ النموذج</button>
                </div> */}
                {contentTableData.filter(el => el.wallArea != 0).length > 0 &&
                    <div className="wrapper">
                        <label>اجمالي الجدران</label>
                        <div className="room-container-total-table">
                            <ContentTable
                                parentComponent={'roomWithHieght'}
                                deleteItem={deleteRoom}
                                headers={
                                    [
                                        'مسمي الغرفة',
                                        // 'المحيط',
                                        'مساحة الارضيه (م2)',
                                        'مساحة الجدران (م2)',
                                        // 'تعديل',
                                        'حذف']}
                                data={contentTableData} />
                        </div>
                    </div>
                }
                {contentTableData.filter(el => el.wallArea == 0).length > 0 &&
                    <div className="wrapper">
                        <label>اجمالي الارضيات </label>
                        <div className="room-container-total-table">
                            <ContentTable
                                parentComponent={'roomWithOutHieght'}
                                deleteItem={deleteRoom}
                                headers={
                                    [
                                        'مسمي الغرفة',
                                        'مساحه السيكلو (م ط)',
                                        'مساحة الارضيه (م2)',
                                        //'مساحة الجدران',
                                        // 'تعديل',
                                        'حذف']}
                                data={contentTableData} />
                        </div>
                    </div>
                }
            </div>
        </div >
    )
}
export default Room;