import * as actionTypes from './actionTypes';
import EditWall from './../../pages/wall/editWall/editWall';

// cyclos actions
// export const setCyclos = (cyclos) => {
//     return {
//         type: actionTypes.SETCYCLOS,
//         cyclos: cyclos
//     }
// }
export const setCyclosToStorage = (cyclos) => {
 
    localStorage.setItem("cyclos", JSON.stringify(cyclos))
}
export const getCyclosFromStorage = () => {
    if (localStorage.getItem("cyclos")) {

        return JSON.parse(localStorage.getItem("cyclos"))
    } else {
        return undefined
    }
}



// wall action
export const setWalls = (walls) => {
    return {
        type: actionTypes.SETWALLS,
        walls: walls
    }
}

export const getWall = (wallId) => {
    return {
        type: actionTypes.GETWALL,
        wallId: wallId
    }
}

export const setWallsToStorage = (wall) => {
    let walls = [];
    if (localStorage.getItem("walls")) {
        walls = [...getWallsFromStorage(), wall]

    } else {
        walls.push(wall)
    }
    localStorage.setItem("walls", JSON.stringify(walls))
}
export const getWallsFromStorage = () => {
    if (localStorage.getItem("walls")) {

        return JSON.parse(localStorage.getItem("walls"))
    } else {
        return undefined
    }
}
export const addWall = (wall) => {

    setWallsToStorage(wall)

    return {
        type: actionTypes.ADDWALL,
        wall: wall
    }
}

export const editWall = (wall) => {
    deleteWallFromLocal(wall.id)

    return {
        type: actionTypes.EDITWALL,
        wall: wall
    }
}
export const deleteWallFromLocal = (id) => {
    let walls = getWallsFromStorage();

    if (walls) {

        let filterdWalls = walls.filter(el => el.id != id);
        localStorage.setItem("walls", JSON.stringify(filterdWalls))
    }

}

export const deleteWall = (wallId) => {
    return {
        type: actionTypes.DELETEWALL,
        wallId: wallId
    }
}

// floor action
export const setFloors = (floors) => {
    return {
        type: actionTypes.SETFLOORS,
        floors: floors
    }
}

export const getFloor = (floorId) => {
    return {
        type: actionTypes.GETFLOOR,
        floorId: floorId
    }
}

export const setFloorsToStorage = (floor) => {

    let floors = [];
    if (localStorage.getItem("floors")) {
        floors = [...getFloorsFromStorage(), floor]

    } else {
        floors.push(floor)
    }
    // let floors = [...getFloorsFromStorage(), floor]
    localStorage.setItem("floors", JSON.stringify(floors))
}
export const getFloorsFromStorage = () => {
    if (localStorage.getItem("floors")) {

        return JSON.parse(localStorage.getItem("floors"))
    } else {
        return undefined
    }
}
export const addFloor = (floor) => {
    setFloorsToStorage(floor)
  

    return {
        type: actionTypes.ADDFLOOR,
        floor: floor
    }
}

export const deleteFloorFromLocal = (id) => {
    let floors = getFloorsFromStorage();

    if (floors) {

        let filterdFloors = floors.filter(el => el.id != id);
        localStorage.setItem("floors", JSON.stringify(filterdFloors))
    }

}
export const editFloor = (floor) => {
    deleteFloorFromLocal(floor.id)
    return {
        type: actionTypes.EDITFLOOR,
        floor: floor
    }
}

export const deleteFloor = (floorId) => {
    return {
        type: actionTypes.DELETEFLOOR,
        floorId: floorId
    }
}

// room actions
export const getRoom = (roomId) => {
    return {
        type: actionTypes.GETROOM,
        roomId: roomId
    }
}

export const addRoom = (room) => {
    return {
        type: actionTypes.ADDROOM,
        room: room
    }
}

export const editRoom = (room) => {
    return {
        type: actionTypes.EDITROOM,
        room: room
    }
}

export const deleteRoom = (roomId) => {
   
    return {
        type: actionTypes.DELETEROOM,
        roomId: roomId
    }
}

// users actions
export const setUserType = (userType) => {
    return {
        type: actionTypes.SETUSERTYPE,
        userType: userType
    }
}