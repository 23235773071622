import React from 'react';
import './dashboard.scss';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';

// import SideMenu from '../../components/sideMenu/sideMenu';

import { ProtectedRoute } from '../../auth/protected-route';
import NotFound from '../../components/not-found/not-found';
import CompanyList from './company-list/company-list';
import ClientList from './clientsList/client-list';
const Dashboard = () => {
  let { path } = useRouteMatch();
  return (
    <div className="container m-auto">
      {/* <div className="col-sm-12 col-md-9 col-lg-2">
        <SideMenu></SideMenu> 
      </div> */}
      <div className="col-sm-12 col-md-12 col-lg-12">
        <Switch>
          <Route path={path} exact>
            <Redirect to={`${path}/companies`} />
          </Route>

          <Route path={`${path}/companies`} component={CompanyList} />
          <Route path={`${path}/clients`} component={ClientList} />
          {/* <ProtectedRoute path={`${path}/materials-cal/:id/:clientId`} component={MaterialsCal} />
                    <ProtectedRoute path={`${path}/materials-cal/:id`} component={MaterialsCal} />
                    <ProtectedRoute path={`${path}/total-materials`} component={TotalMaterials} />
                    <Route path={`${path}/room`} component={Room} /> */}

          <Route path="**" component={NotFound}></Route>
        </Switch>
      </div>
    </div>
  )

}
export default Dashboard;