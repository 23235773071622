import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import './clientData.scss'
import useSignUpForm from '../../hooks/useSignUpForm';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../../constants/config'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleError } from '../../utilities/handleError';
import * as errorTypes from "../../utilities/errorTypes"
const ClientData = () => {
    let history = useHistory();
    const currentUser = localStorage.getItem('currentUser')
    const identifier = localStorage.getItem('identifier');
    const id = localStorage.getItem('id');
    const [userData, setuserData] = useState({});
    const { handleInputChange, inputs } = useSignUpForm(
        {
            name: '',
            nameIsValid: false,
            nameIsTouched: false,
            phone: '',
            phoneIsValid: false,
            phoneIsTouched: false,
            address: '',
            addressIsValid: false,
            addressIsTouched: false,
            email: '',
            emailIsValid: false,
            emailIsTouched: false
        }
    )
    useEffect(() => {

        let token = localStorage.getItem("token")
        if (localStorage.getItem("currentUser") == 'client') {
            if (sessionStorage.getItem("clientData")) {

                const user = JSON.parse(sessionStorage.getItem("clientData"))
                setuserData(
                    {
                        name: user.name,
                        nameIsValid: true,
                        nameIsTouched: true,
                        phone: user.phone,
                        phoneIsValid: true,
                        phoneIsTouched: true,
                        address: user.address,
                        addressIsValid: true,
                        addressIsTouched: true,
                        email: user.email,
                        emailIsValid: true,
                        emailIsTouched: true
                    }
                )


            } else {

                localStorage.removeItem("identifier")
                localStorage.removeItem("id")
            }
        } else {

            if (identifier) {
                axios.get(`${BASE_URL}/buyer?identifier=${identifier}`, {
                    headers: {
                        "Accept": "application/json",
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        "Accept-Language": 'ar'

                    }
                }).then(res => {
                    setuserData(
                        {
                            name: res.data.name,
                            nameIsValid: true,
                            nameIsTouched: true,
                            phone: res.data.phone,
                            phoneIsValid: true,
                            phoneIsTouched: true,
                            address: res.data.address,
                            addressIsValid: true,
                            addressIsTouched: true,
                            email: res.data.email,
                            emailIsValid: true,
                            emailIsTouched: true
                        }
                    )
                    // localStorage.setItem('id', res.data.id)
                    // dispatch(actionCreators.setFloors(res.data.rooms))
                }



                )
                    .catch(error => {
                        const errorResult = handleError(error)
                        const errMsg = errorResult.message;
                        toast.error(errMsg, {
                            position: "top-right",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (errorResult.type == errorTypes.unauthorized) {
                            setTimeout(() => {

                                localStorage.clear();
                                history.push("/home");
                            }, 1000);
                        }

                    })
            } else {
                setuserData({
                    name: '',
                    nameIsValid: false,
                    nameIsTouched: false,
                    phone: '',
                    phoneIsValid: false,
                    phoneIsTouched: false,
                    address: '',
                    addressIsValid: false,
                    addressIsTouched: false,
                    email: '',
                    emailIsValid: false,
                    emailIsTouched: false
                })
            }
        }

    }
        , [identifier, id])
    const checkFormValidation = () => {
        return inputs.nameIsValid && inputs.phoneIsValid;
    }

    const saveHandler = () => {
        let body = {
            name: inputs.name,
            phone: inputs.phone,
            company_id: localStorage.getItem('company_id')
        }
        body = {
            ...body,
            ...(inputs.address !== '' && { address: inputs.address }),
            ...(inputs.email !== '' && { email: inputs.email })
        }

        axios.post(BASE_URL + '/buyer/create', body,
            {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }).then(res => {

                if (localStorage.getItem("currentUser") == 'client') {

                    sessionStorage.setItem("clientData", JSON.stringify(res.data))
                    sessionStorage.setItem('identifier', res.data.identifier)
                    sessionStorage.setItem('id', res.data.id)
                }

                toast.success('تم الحفظ', {
                    position: "top-right",
                    autoClose: 0,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                localStorage.setItem('identifier', res.data.identifier)
                localStorage.setItem('id', res.data.id)

                currentUser === 'client' ?
                    history.push("/data/room") :
                    history.push("/data/materials-cal/cyclo");
            }).catch(error => {
                const errorResult = handleError(error)
                const errMsg = errorResult.message;
                toast.error(errMsg, {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                if (errorResult.type == errorTypes.unauthorized) {
                    setTimeout(() => {

                        localStorage.clear();
                        history.push("/home");
                    }, 1000);
                }

            })
    }

    return (
        <div className="client-data">

            <ToastContainer
                position="top-right"
                autoClose={0}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
            {
                (localStorage.getItem("identifier") || sessionStorage.getItem("identifier"))
                //  && localStorage.getItem("currentUser") != 'client'
                && <span className="form-name">نموذج رقم
                    {
                        (localStorage.getItem("identifier") || sessionStorage.getItem("identifier"))

                    }</span>}
            <div className="client-data-name">
                <span>اسم العميل</span>
                <input
                    value={inputs.name || userData.name || ''}
                    onChange={(e) => handleInputChange(e)}
                    name="name"
                    disabled={userData.name}

                />
                {!inputs.nameIsValid && inputs.nameIsTouched && <span className="error-msg">اسم العميل خطأ</span>}
            </div>
            <div className="client-data-phone">
                <span>رقم التليفون</span>
                <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    value={inputs.phone || userData.phone || ''}
                    name="phone"
                    disabled={userData.phone}

                    onChange={(e) => handleInputChange(e)}
                />
                {!inputs.phoneIsValid && inputs.phoneIsTouched && <span className="error-msg">رقم الهاتف خطأ</span>}

            </div>
            <div className="client-data-address">
                <span>العنوان</span>
                <input
                    value={inputs.address || userData.address || ''}
                    name="address"
                    onChange={(e) => handleInputChange(e)}
                    disabled={userData.address}
                />
                {!inputs.addressIsValid && inputs.addressIsTouched && <span className="error-msg">العنوان خطأ</span>}

            </div>
            <div className="client-data-email">
                <span>البريد الالكتروني</span>
                <input
                    value={inputs.email || userData.email || ''}
                    name="email"
                    disabled={userData.email}

                    onChange={(e) => handleInputChange(e)}
                />
                {!inputs.emailIsValid && inputs.emailIsTouched && <span className="error-msg">البريد الالكتروني خطأ</span>}
            </div>
            {!userData.name && <button disabled={!checkFormValidation()} onClick={() => saveHandler()} className={checkFormValidation() ? "client-data-save" : "client-data-save disabled"}>حفظ البيانات</button>
            }
        </div>
    )
}

export default ClientData;