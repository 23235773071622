import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import './editWall.scss';
import { ReactComponent as Shape5 } from '../../../assets/Path 4717.svg';
import { ReactComponent as Shape4 } from '../../../assets/Path 4715.svg';
import { ReactComponent as Shape3 } from '../../../assets/Path 4716.svg';
import { ReactComponent as Shape2 } from '../../../assets/Rectangle 1056.svg';
import { ReactComponent as Shape1 } from '../../../assets/Rectangle 1048.svg';

import { ReactComponent as Shape55 } from '../../../assets/Group 3634.svg';
import { ReactComponent as Shape44 } from '../../../assets/Group 3635.svg';
import { ReactComponent as Shape33 } from '../../../assets/8l.svg';

import { ReactComponent as Shape22 } from '../../../assets/Group 3636.svg';
import { ReactComponent as Close } from '../../../assets/Icon ionic-ios-close-circle-outline.svg';
import { ReactComponent as Plus } from '../../../assets/Icon feather-plus-circle.svg';

import MainSlab from '../../../components/main-slab/mainSlab';
import AddBelt from '../../../components/add-belt/addBelt';
import SpecialSpace from '../../../components/special-space/specialSpace';
import InstallChoices from '../../../components/installChoices/installChoices';
import SlabMeasure from '../../../components/slabMeasure/slabMeasure';
import * as actionCreators from '../../../store/actions/actionCreators';
import * as basicFn from '../../../store/actions/basicFn';
import { useSelector, useDispatch } from 'react-redux';
import useSignUpForm from '../../../hooks/useSignUpForm'
import axios from 'axios';
import { useHistory } from "react-router-dom";

import { BASE_URL } from '../../../constants/config'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from 'react-bootstrap';
// import { getWall } from './../../../store/actions/actionCreators';
import validate from '../../../utilities/validation';
import { handleError } from '../../../utilities/handleError';
import * as errorTypes from "../../../utilities/errorTypes"

var doorId = 0;
var floorId = 0;
var beltId = 0;
var specialSpaceId = 0;
const beltUniqueId = () => {

  beltId++


  return beltId;
}
const specialSpaceUniqueId = () => {


  specialSpaceId++


  return specialSpaceId;
}
var colorId = 0;

const colorUniqueId = () => {
  colorId++;
  return colorId;
}

const EditWall = (props) => {

  //coloring
  const [special_space_type, setSpecial_space_type] = useState(null)

  const [selectedColoringType, setSelectedColoringType] = useState("freeColoring")
  const [beltsOutput, setBeltsOutput] = useState([])
  const [spacesOutput, setSpacesOutput] = useState([])
  const onColoringTypeChange = (type) => {

    setSelectedColoringType(type)
    if (type == 'rowsOnly') {
      let cpyColoring = [...coloring];
      let newColoring = []
      newColoring = cpyColoring.map(el => {
        el.rows = '';

        el.columns = wallResult.wall_main_vertical_count;
        return el
      })
      setColoring(newColoring)
      color = { ...color, columns: wallResult.wall_main_vertical_count }


    } else if (type == 'colsOnly') {

      let cpyColoring = [...coloring];
      let newColoring = []
      newColoring = cpyColoring.map(el => {
        el.rows = wallResult.wall_main_horizontal_count;
        el.columns = ''

        return el
      })
      setColoring(newColoring)
      color = { ...color, rows: wallResult.wall_main_horizontal_count }
    }
    else {
      let cpyColoring = [...coloring];
      let newColoring = []
      newColoring = cpyColoring.map(el => {
        el.rows = '';
        el.columns = ''
        return el
      })
      setColoring(newColoring)
      color = { ...color, rows: '', columns: '' }
      //free coloring
    }

  }
  let color = {
    id: colorUniqueId(),
    name: "", nameIsValid: false, nameIsTouched: false,
    rows: '', rowsIsTouched: false, rowsIsValid: false,
    columns: '', columnsIsTouched: false, columnsIsValid: false,
    num_of_tiles: ''
  }
  const [coloring, setColoring] = useState([
    // { id: colorUniqueId(), name: "", rows: '', columns: '', num_of_tiles: '' }
  ])
  const addNewColor = () => {
    const cpyColoring = [...coloring];
    if (selectedColoringType == 'rowsOnly') {
      color = { ...color, columns: wallResult.wall_main_vertical_count }
    } else if (selectedColoringType == 'colsOnly') {
      color = { ...color, rows: wallResult.wall_main_horizontal_count }

    } else {
      color = { ...color, rows: '', columns: '' }

    }
    cpyColoring.push(color)
    setColoring(cpyColoring)
  }
  const deleteColor = (id) => {

    let colors = JSON.parse(JSON.stringify(coloring))
    let newColors = colors.filter(color => color.id != id);

    setColoring(newColors)
  }
  const onColoringNameChange = (target) => {


    let coloringArr = JSON.parse(JSON.stringify(coloring));

    coloringArr[coloringArr.findIndex(el => el.id == parseInt(target.name))].name = target.value
    coloringArr[coloringArr.findIndex(el => el.id == parseInt(target.name))].nameIsValid = validate('name', target.value)
    coloringArr[coloringArr.findIndex(el => el.id == parseInt(target.name))].nameIsTouched = true


    setColoring(coloringArr)
  }
  const onColoringRowsChange = (target) => {

    let coloringArr = JSON.parse(JSON.stringify(coloring));

    let sumrows = 0;
    coloringArr.map(el => {
      if (el.id != target.name) {

        sumrows += Number(el.rows);
      }
    })



    if (sumrows + Number(target.value) <= Number(wallResult.wall_main_horizontal_count)) {
      coloringArr[coloringArr.findIndex(el => el.id == parseInt(target.name))].rows = target.value
      coloringArr[coloringArr.findIndex(el => el.id == parseInt(target.name))].rowsIsValid = validate('rowQuantity', target.value)
      coloringArr[coloringArr.findIndex(el => el.id == parseInt(target.name))].rowsIsTouched = true

    } else {
      alert("  عدد الصفوف المتبقيه  " + (Number(wallResult.wall_main_horizontal_count) - sumrows))



    }
    setColoring(coloringArr)



  }
  const onColoringColsChange = (target) => {

    let coloringArr = JSON.parse(JSON.stringify(coloring));

    let sumcols = 0;
    coloringArr.map(el => {
      if (el.id != target.name) {

        sumcols += Number(el.columns);
      }
    })



    if (sumcols + Number(target.value) <= Number(wallResult.wall_main_vertical_count)) {
      coloringArr[coloringArr.findIndex(el => el.id == parseInt(target.name))].columns = target.value
      coloringArr[coloringArr.findIndex(el => el.id == parseInt(target.name))].columnsIsValid = validate('colQuantity', target.value)
      coloringArr[coloringArr.findIndex(el => el.id == parseInt(target.name))].columnsIsTouched = true

    } else {
      // alert("not")
      // coloringArr[coloringArr.findIndex(el => el.id == parseInt(target.name))].columnsIsValid = false
      // coloringArr[coloringArr.findIndex(el => el.id == parseInt(target.name))].columnsIsTouched = true
      alert("  عدد الاعمده المتبقيه  " + (Number(wallResult.wall_main_vertical_count) - sumcols))


    }
    setColoring(coloringArr)
  }
  const resetColoring = () => {
    setColoring([
      // color
    ])
    color = {
      id: colorUniqueId(),
      name: "", nameIsValid: false, nameIsTouched: false,
      rows: '', rowsIsTouched: false, rowsIsValid: false,
      columns: '', columnsIsTouched: false, columnsIsValid: false,
      num_of_tiles: ''
    }
    setSelectedColoringType("freeColoring")


  }
  const setMainSlab = (name, length, width, spacing, angle, start) => {
    setMainSlabInputs({
      tile_name: name,
      tile_nameIsValid: true,
      tile_nameIsTouched: true,
      tile_length: length,
      tile_lengthIsValid: true,
      tile_lengthIsTouched: true,
      tile_width: width,
      tile_widthIsValid: true,
      tile_widthIsTouched: true,
      tile_spacing: spacing,
      tile_spacingIsValid: true,
      tile_spacingIsTouched: true,
    })
    setInstallChoicesInputs({
      installation_angle_id: angle,
      installation_angle_idIsValid: true,
      installation_angle_idIsTouched: true,
      installation_start_id: start,
      installation_start_idIsValid: true,
      installation_start_idIsTouched: true
    }
    )
  }
  const setMainInputs = (_name, _height, length, width, spacing, angle_id, start_id) => {
    setInputsValues({
      name: _name,
      nameIsValid: true,
      nameIsTouched: true,
      height: _height,
      heightIsValid: true,
      heightIsTouched: true,
      tile_length: length,
      tile_lengthIsValid: true,
      tile_lengthIsTouched: true,
      tile_width: width,
      tile_widthIsValid: true,
      tile_widthIsTouched: true,
      tile_spacing: spacing,
      tile_spacingIsValid: true,
      tile_spacingIsTouched: true,
      installation_angle_id: angle_id,
      installation_angle_idIsValid: true,
      installation_angle_idIsTouched: true,
      installation_start_id: start_id,
      installation_start_idIsValid: true,
      installation_start_idIsTouched: true
    })

  }
  const getSpecialSpacesArr = (specialArr) => {
    let SpecialArray = specialArr

    let spacessOutputArr = []
    SpecialArray.map(el => {
      spacessOutputArr.push({
        id: el.id, after_waste: el.after_waste,
        before_waste: el.before_waste
      })
    });
    setSpacesOutput(spacessOutputArr)
    let specialSpacesrray = specialArr

    let specials = [];
    if (specialSpacesrray.length > 0) {

      specialSpacesrray.map(el => {
        specialSpaceId = el.id;
        let wallsArr = []

        el.walls.map(el => {
          if (el.wall) {
            //el.wall
            wallsArr.push(el.wall.slice(5))
          } else {
            wallsArr.push(el.slice(5))

          }
        })
        specials.push({
          id: el.id,
          specialSpaceWidth: el.width,
          specialSpaceWidthIsValid: true,
          specialSpaceWidthIsTouched: true,
          specialSpaceLength: el['length'],
          specialSpaceLengthIsValid: true,
          specialSpaceLengthIsTouched: true,
          tile_name: el.tile_name && el.tile_name.name ? el.tile_name.name : el.tile_name,
          nameIsTouched: true,
          nameIsValid: true,
          tile_length: el.tile_length,
          tile_lengthIsTouched: true,
          tile_lengthIsValid: true,
          tile_spacing: el.tile_spacing,
          tile_spacingIsTouched: true,
          tile_spacingIsValid: true,
          tile_width: el.tile_width,
          tile_widthIsTouched: true,
          tile_widthIsValid: true,
          installation_angle_id: el.installation_angle_id,
          installation_angle_idIsValid: true,
          installation_angle_idIsTouched: true,
          installation_start_id: el.installation_start_id,
          installation_start_idIsValid: true,
          installation_start_idIsTouched: true,

          //missing walls
          walls: wallsArr
        })
      })
    }
    else {
      // specials.push({
      //     id: specialSpaceUniqueId(),
      //     specialSpaceWidth: "",
      //     specialSpaceWidthIsValid: false,
      //     specialSpaceWidthIsTouched: false,

      //     specialSpaceLength: inputs.height,

      //     specialSpaceLengthIsValid: inputs.height ? true : false,
      //     specialSpaceLengthIsTouched: inputs.height ? true : false,
      //     tile_name: "",
      //     nameIsTouched: false,
      //     nameIsValid: false,
      //     tile_length: "",
      //     tile_lengthIsTouched: false,
      //     tile_lengthIsValid: false,
      //     tile_spacing: "",
      //     tile_spacingIsTouched: false,
      //     tile_spacingIsValid: false,
      //     tile_width: "",
      //     tile_widthIsTouched: false,
      //     tile_widthIsValid: false,
      //     installation_angle_id: 1,
      //     installation_angle_idIsValid: false,
      //     installation_angle_idIsTouched: false,
      //     installation_start_id: 1,
      //     installation_start_idIsValid: false,
      //     installation_start_idIsTouched: false,


      //     walls: []
      // }
      // )

    }
    return specials;
  }
  const getBeltsArr = (belts) => {

    let beltsArray = belts

    let beltsOutputArr = []
    beltsArray.map(el => {
      beltsOutputArr.push({
        id: el.id, output: el.output
      })
    });
    setBeltsOutput(beltsOutputArr)
    let straps = [];
    if (beltsArray.length > 0) {


      beltsArray.map(el => {
        beltId = el.id;
        let wallsArr = []

        el.walls.map(el => {

          wallsArr.push(el.wall.slice(5))
          // if (wallToBeFound.wall_floor) {
          //     //el.wall
          // } else {
          //     wallsArr.push(el.slice(5))

          // }
        })
        straps.push({
          id: el.id,

          tile_name: el.tile_name && el.tile_name.name ? el.tile_name.name : el.tile_name,
          nameIsTouched: true,
          nameIsValid: true,
          tile_length: el.tile_length,
          tile_lengthIsTouched: true,
          tile_lengthIsValid: true,
          tile_spacing: el.tile_spacing,
          tile_spacingIsTouched: true,
          tile_spacingIsValid: true,
          tile_width: el.tile_width,
          tile_widthIsTouched: true,
          tile_widthIsValid: true,

          beltsCount: el.strap_count,
          beltsCountIsValid: true,
          beltsCountIsTouched: true,
          installation_style_id: el.strap_installation_style_id,
          installation_style_idIsValid: true,
          installation_style_idIsTouched: true,
          //missing
          walls: wallsArr
          // specialSpaceWidth: el.width,
          // specialSpaceWidthIsValid: true,
          // specialSpaceWidthIsTouched: true,
          // specialSpaceLength: el['length'],
          // specialSpaceLengthIsValid: true,
          // specialSpaceLengthIsTouched: true,
          // tile_name: el.tile_name,
          // nameIsTouched: true,
          // nameIsValid: true,
          // tile_length: el.tile_length,
          // tile_lengthIsTouched: true,
          // tile_lengthIsValid: true,
          // tile_spacing: el.tile_spacing,
          // tile_spacingIsTouched: true,
          // tile_spacingIsValid: true,
          // tile_width: el.tile_width,
          // tile_widthIsTouched: true,
          // tile_widthIsValid: true,
          // installation_angle_id: el.installation_angle_id,
          // installation_angle_idIsValid: true,
          // installation_angle_idIsTouched: true,
          // installation_start_id: el.installation_start_id,
          // installation_start_idIsValid: true,
          // installation_start_idIsTouched: true,

          //missing walls
          //  walls: []
        })

      })
    } else {
      straps.push({
        id: beltUniqueId(),
        nameIsTouched: false,
        nameIsValid: false,
        tile_length: "",
        tile_lengthIsTouched: false,
        tile_lengthIsValid: false,
        tile_name: "",
        tile_spacing: "",
        tile_spacingIsTouched: false,
        tile_spacingIsValid: false,
        tile_width: "",
        tile_widthIsTouched: false,
        tile_widthIsValid: false,

        beltsCount: 0,
        beltsCountIsValid: false,
        beltsCountIsTouched: false,
        installation_style_id: 1,
        installation_style_idIsValid: false,
        installation_style_idIsTouched: false,
        walls: []
      })


    }

    return straps;
  }
  useEffect(() => {

    const cuttingStyle = basicFn.getCutting_style();
    if (!cuttingStyle) {

      axios.get(`${BASE_URL}/cutting_style`, {
        headers: {
          "Accept": "application/json",
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          "Accept-Language": 'ar'

        }
      }).then(res => {
        basicFn.setCutting_style(res.data)
        setCutTypes(res.data)
      }).catch(error => {
        const errorResult = handleError(error)
        const errMsg = errorResult.message;
        toast.error(errMsg, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (errorResult.type == errorTypes.unauthorized) {
          setTimeout(() => {

            localStorage.clear();
            history.push("/home");
          }, 1000);
        }

      })
    } else {
      setCutTypes(basicFn.getCutting_style())
    }
    const strapStyles = basicFn.getInstlationStyle();
    if (!strapStyles) {

      axios.get(`${BASE_URL}/strap_installation_style`, {
        headers: {
          "Accept": "application/json",
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          "Accept-Language": 'ar'

        }
      }).then(res => {
        basicFn.setInstlationStyle(res.data)
        setInstallationStyles(res.data)
      }).catch(error => {
        const errorResult = handleError(error)
        const errMsg = errorResult.message;
        toast.error(errMsg, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (errorResult.type == errorTypes.unauthorized) {
          setTimeout(() => {

            localStorage.clear();
            history.push("/home");
          }, 1000);
        }

      })
    }
    else {
      setInstallationStyles(strapStyles)

    }
    const tilesArr = basicFn.getTiles();
    if (!tilesArr) {

      axios.get(`${BASE_URL}/tile?company_id=${localStorage.getItem('company_id')}`, {
        headers: {
          "Accept": "application/json",
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          "Accept-Language": 'ar'

        }
      }).then(res => {
        setTiles(res.data)
        basicFn.setTiles(res.data)
      }).catch(error => {
        const errorResult = handleError(error)
        const errMsg = errorResult.message;
        toast.error(errMsg, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (errorResult.type == errorTypes.unauthorized) {
          setTimeout(() => {

            localStorage.clear();
            history.push("/home");
          }, 1000);
        }

      })
    } else {
      setTiles(tilesArr)
    }
    const angles = basicFn.getInstlationAngle()
    if (!angles) {

      axios.get(`${BASE_URL}/installation_angle`, {
        headers: {
          "Accept": "application/json",
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          "Accept-Language": 'ar'

        }
      }).then(res => {
        basicFn.setInstlationAngle(res.data)
        setInstallationAngles(res.data)
      }).catch(error => {
        const errorResult = handleError(error)
        const errMsg = errorResult.message;
        toast.error(errMsg, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (errorResult.type == errorTypes.unauthorized) {
          setTimeout(() => {

            localStorage.clear();
            history.push("/home");
          }, 1000);
        }
      })
    } else {
      setInstallationAngles(angles)
    }
    const starts = basicFn.getInstlationStart()
    if (!starts) {

      axios.get(`${BASE_URL}/installation_start`, {
        headers: {
          "Accept": "application/json",
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          "Accept-Language": 'ar'

        }
      }).then(res => {
        basicFn.setInstlationStart(res.data)
        setInstallationStarts(res.data)
      }).catch(error => {
        const errorResult = handleError(error)
        const errMsg = errorResult.message;
        toast.error(errMsg, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (errorResult.type == errorTypes.unauthorized) {
          setTimeout(() => {

            localStorage.clear();
            history.push("/home");
          }, 1000);
        }

      })
    } else {
      setInstallationStarts(starts)

    }
  }, [])
  const handleModalClose = () => {
    props.handleClose();
  }
  let params = useParams();
  let [userId, setUserId] = useState("")

  let history = useHistory()
  const currentWalls = useSelector(state => state.wallReducer.walls);

  const dispatch = useDispatch();
  const [selectedShapeComponent, setSelectedShapeComponent] = useState(<Shape1 />);
  const [selectedShapeName, setSelectedShapeName] = useState('Shape1');
  const [installationAngles, setInstallationAngles] = useState([]);
  const [installationStarts, setInstallationStarts] = useState([]);
  const [installationStyles, setInstallationStyles] = useState([]);
  const [WallNav, setWallNav] = useState('slab');
  const [walls, setWalls] = useState([]);
  // const [wallsToBelt, setWallsToBelt] = useState([])
  // const [wallsToSpecialSpace, setWallsToSpecialSpace] = useState([])
  const [cutTypes, setCutTypes] = useState([]);
  const [tiles, setTiles] = useState([]);
  // const [belts, setBelts] = useState([]);
  const [savedBtnStatus, setSavedBtnStatus] = useState({ id: null, saved: false });
  // const [addBeltInputs, setAddBeltInputs] = useState([])
  const {
    handleInputChange: handleMainSlabInputChange,
    inputs: MainSlabInputs,
    clearInputs: clearMainSlabInputs,
    setInputsValues: setMainSlabInputs
  } = useSignUpForm({
    tile_name: '',
    tile_nameIsValid: false,
    tile_nameIsTouched: false,
    tile_length: '',
    tile_lengthIsValid: false,
    tile_lengthIsTouched: false,
    tile_width: '',
    tile_widthIsValid: false,
    tile_widthIsTouched: false,
    tile_spacing: '',
    tile_spacingIsValid: false,
    tile_spacingIsTouched: false,
  })

  // main slab install choices object
  const {
    handleInputChange: handleInstallChoicesInputChange,
    inputs: InstallChoicesInputs,
    clearInputs: clearInstallChoicesInputs,
    setInputsValues: setInstallChoicesInputs
  } = useSignUpForm({
    installation_angle_id: 1,
    installation_angle_idIsValid: false,
    installation_angle_idIsTouched: false,
    installation_start_id: 1,
    installation_start_idIsValid: false,
    installation_start_idIsTouched: false
  })

  const { handleInputChange, inputs, clearInputs, setInputsValues } = useSignUpForm(
    {
      name: '',
      nameIsValid: false,
      nameIsTouched: false,
      height: 1,
      heightIsValid: true,
      heightIsTouched: false,
      tile_length: '',
      tile_lengthIsValid: false,
      tile_lengthIsTouched: false,
      tile_width: '',
      tile_widthIsValid: false,
      tile_widthIsTouched: false,
      tile_spacing: '',
      tile_spacingIsValid: false,
      tile_spacingIsTouched: false,
      cycalo_height: '',
      cycalo_heightIsValid: false,
      cycalo_heightIsTouched: false,
      cutting_style_id: 1,
      cutting_style_idIsValid: false,
      cutting_style_idIsTouched: false,
      installation_angle_id: 1,
      installation_angle_idIsValid: false,
      installation_angle_idIsTouched: false,
      installation_start_id: 1,
      installation_start_idIsValid: false,
      installation_start_idIsTouched: false,
      specialSpaceLength: 1,
      specialSpaceLengthIsValid: false,
      specialSpaceLengthIsTouched: false,
      specialSpaceWidth: 1,
      specialSpaceWidthIsValid: false,
      specialSpaceWidthIsTouched: false,
    }
  )


  const setDisabledEdges = (edgeNumber) => {
    let res = false
    if (selectedShapeName === 'Shape5' && edgeNumber === 1) {
      res = true;
    }
    else if (selectedShapeName === 'Shape4' && (edgeNumber === 1 || edgeNumber === 6)) {
      res = true
    }
    else if (selectedShapeName === 'Shape3' && (edgeNumber === 1 || edgeNumber === 6 || edgeNumber === 8)) {
      res = true
    }
    else if (selectedShapeName === 'Shape2' && (edgeNumber === 3 || edgeNumber === 4)) {
      res = true;
    }
    else if (selectedShapeName === 'Shape1' && (edgeNumber === 2 || edgeNumber === 3 || edgeNumber === 4)) {
      res = true;
    }
    else {
      res = false
    }
    return res;
  }

  // const changeWallLength = (target) => {
  //   // let wallsCopy = [...walls];
  //   let wallsCopy = JSON.parse(JSON.stringify(walls))
  //   wallsCopy[wallsCopy.findIndex(wall => wall.number == parseInt(target.name))].length = parseFloat(target.value);
  //   switch (selectedShapeName) {
  //     case 'Shape5':
  //       wallsCopy[0].length = wallsCopy[2].length + wallsCopy[4].length + wallsCopy[6].length;
  //       break;
  //     case 'Shape4':
  //       wallsCopy[0].length = wallsCopy[2].length + wallsCopy[4].length;
  //       wallsCopy[5].length = wallsCopy[1].length + wallsCopy[3].length;
  //       break;
  //     case 'Shape3':
  //       wallsCopy[0].length = wallsCopy[2].length + wallsCopy[4].length + wallsCopy[6].length;
  //       wallsCopy[5].length = wallsCopy[3].length
  //       wallsCopy[7].length = wallsCopy[1].length
  //       break;
  //     case 'Shape2':
  //       wallsCopy[2].length = wallsCopy[0].length;
  //       wallsCopy[3].length = wallsCopy[1].length
  //       break;
  //     case 'Shape1':
  //       wallsCopy[1].length = wallsCopy[0].length;
  //       wallsCopy[2].length = wallsCopy[0].length
  //       wallsCopy[3].length = wallsCopy[0].length
  //       break;
  //     default:
  //       break;
  //   }
  //   setWalls(wallsCopy)
  //   setSpecialSpaceArr([]);

  // }
  const changeWallLength = (target) => {


    let wallsCopy = JSON.parse(JSON.stringify(walls))


    let index = wallsCopy.findIndex(wall => wall.number == parseInt(target.name))

    wallsCopy[index]['length'] = parseFloat(target.value);

    switch (selectedShapeName) {

      case 'Shape5':


        wallsCopy[0].length = Number.parseFloat(wallsCopy[2].length + wallsCopy[4].length + wallsCopy[6].length).toFixed(2)
        break;
      case 'Shape4':
        wallsCopy[0].length = Number.parseFloat(wallsCopy[2].length + wallsCopy[4].length).toFixed(2);
        wallsCopy[5].length = Number.parseFloat(wallsCopy[1].length + wallsCopy[3].length).toFixed(2);
        break;
      case 'Shape3':
        wallsCopy[0].length = Number.parseFloat(wallsCopy[2].length + wallsCopy[4].length + wallsCopy[6].length).toFixed(2);
        wallsCopy[5].length = wallsCopy[3].length
        wallsCopy[7].length = wallsCopy[1].length
        break;
      case 'Shape2':
        wallsCopy[2].length = wallsCopy[0].length;
        wallsCopy[3].length = wallsCopy[1].length
        break;
      case 'Shape1':
        wallsCopy[1].length = wallsCopy[0].length;
        wallsCopy[2].length = wallsCopy[0].length
        wallsCopy[3].length = wallsCopy[0].length
        break;
      default:
        break;
    }
    setWalls(wallsCopy)
    setSpecialSpaceArr([]);
    setSpacesOutput([])
  }
  const setSelectedShape = (name, component, values) => {
    let obj = [];
    for (let index = 0; index < values; index++) {
      const element = {
        number: index + 1,
        length: ""
      }
      obj.push(element)
    }
    setWalls(obj)
    setSelectedShapeComponent(component)
    setSelectedShapeName(name)
  }

  const [wallResult, setWallResult] = useState({
    quantity: 0,
    cycalo_mt: 0,
    waste: 0,
    wall_main_quantity_after_waste: '',
    wall_main_quantity: '',
    wall_main_vertical_count: '', wall_main_horizontal_count: '',
    floor_quantity_after_waste: '', floor_quantity: '',
    columns_remaining_count: '',
    rows_remaining_count: '', tiles_remaining_count: ''
  });

  const getWall = () => {


    let wallsResult = [];


    if (props.data.room.shape) {
      Object.keys(props.data.room.walls).map(key => {
        if (key.includes('wall_')) {
          wallsResult.push({ [`number`]: key.substr(5, 1), ['length']: props.data.room.walls[key] })
        }
      })
      wallsResult = wallsResult.slice(0, props.data.room.shape.number_of_walls);
    }




    setWalls(wallsResult)

    setSelectedShapeName(props.data.room.shape ? props.data.room.shape.name : props.data.selectedShapeName)
    setSelectedShapeComponent(props.data.room.shape ? getShape(props.data.room.shape.name) : getShape(props.data.selectedShapeName))
    // setInputsValues(props.data.room.name, props.data.room.height, props.data.floor_tile_length, props.data.floor_tile_width,
    //   props.data.floor_tile_spacing,
    //   props.data.installation_angle_id, props.data.installation_start_id)

    setMainInputs(props.data.room.name, props.data.room.height,
      props.data.floor_tile_length, props.data.floor_tile_width,
      props.data.floor_tile_spacing,
      props.data.installation_angle_id,
      props.data.installation_start_id)

    // setInputsValues({
    //   name: props.data.room.name,
    //   nameIsValid: true,
    //   nameIsTouched: true,
    //   height: props.data.room.height,
    //   heightIsValid: true,
    //   heightIsTouched: true,
    //   // tile_name: wallToBeFound.tile_name ? wallToBeFound.tile_name : '',
    //   // tile_nameIsValid: wallToBeFound.tile_name !== '' ? true : false,
    //   // tile_nameIsTouched: false,
    //   // tile_name_text: '',
    //   // tile_length: wallToBeFound.tile_length ? wallToBeFound.tile_length : '',
    //   tile_length: props.data.wall_floor && props.data.wall_floor.floor_tile_length ? props.data.wall_floor.floor_tile_length : props.data.floor_tile_length || '',
    //   tile_lengthIsValid: props.data.wall_floor && props.data.wall_floor.floor_tile_length ? true : props.data.floor_tile_length ? true : false,
    //   tile_lengthIsTouched: props.data.wall_floor && props.data.wall_floor.floor_tile_length ? true : props.data.floor_tile_length ? true : false,
    //   tile_width: props.data.wall_floor && props.data.wall_floor.floor_tile_width ? props.data.wall_floor.floor_tile_width : props.data.floor_tile_width || '',
    //   tile_widthIsValid: props.data.wall_floor && props.data.floor_tile_width ? true : props.data.floor_tile_width ? true : false,
    //   tile_widthIsTouched: props.data.wall_floor && props.data.floor_tile_width ? true : props.data.floor_tile_width ? true : false,
    //   tile_spacing: props.data.floor_tile_spacing,
    //   tile_spacingIsValid: true,
    //   tile_spacingIsTouched: false,
    //   // cycalo_height: props.data.cycalo_height,
    //   // cycalo_heightIsValid: true,
    //   // cycalo_heightIsTouched: true,
    //   // cutting_style_id: props.data.cutting_style_id ? props.data.cutting_style_id : 1,
    //   // cutting_style_idIsValid: props.data.cutting_style_id ? true : false,
    //   // cutting_style_idIsTouched: false,
    //   installation_angle_id: props.data.installation_angle_id || 1,
    //   installation_angle_idIsValid: true,
    //   installation_angle_idIsTouched: false,
    //   installation_start_id: props.data.wall_floor && props.data.wall_floor.installation_start_id ? props.data.wall_floor.installation_start_id : props.data.installation_start_id || 1,
    //   installation_start_idIsValid: true,
    //   installation_start_idIsTouched: false
    // })
    // setWallResult(props.data.wall_floor || props.data.output ? (props.data.wall_floor && props.data.wall_floor.output) || props.data.output :
    //   {
    //     floor_quantity: '',
    //     floor_quantity_after_waste: '',
    //     wall_main_quantity: '',
    //     // cycalo_mt: '',
    //     // waste: '',
    //     // before_waste: '',
    //     wall_main_vertical_count: '',
    //     wall_main_horizontal_count: '',
    //     wall_main_quantity_after_waste: '',
    //   }




    // )
    setWallResult(props.data.output)


    //main slab
    setMainSlab(props.data.wall_main_tile?.tile_name?.name, props.data.wall_main_tile.tile_length, props.data.wall_main_tile.tile_width, props.data.wall_main_tile.tile_spacing, props.data.wall_main_tile.installation_angle_id, props.data.wall_main_tile.installation_start_id)

    //belts
    setBeltsArr(getBeltsArr(props.data.wall_straps))

    //coloring
    setSelectedColoringType(props.data?.coloring_type)

    if (props.data.coloring) {

      setColoring(props.data.coloring)


      // }
    } else {
      resetColoring()
    }
    //set main slab vlues
    setWallNav("slab")
    // setMainSlabInputs(props.data.wall_floor ? {
    //   tile_name: props.data.wall_floor.wall_main_tile.tile_name.name,
    //   tile_nameIsValid: true,
    //   tile_nameIsTouched: true,
    //   tile_length: props.data.wall_floor.wall_main_tile.tile_length,
    //   tile_lengthIsValid: true,
    //   tile_lengthIsTouched: true,
    //   tile_width: props.data.wall_floor.wall_main_tile.tile_width,
    //   tile_widthIsValid: true,
    //   tile_widthIsTouched: true,
    //   tile_spacing: props.data.wall_floor.wall_main_tile.tile_spacing,
    //   tile_spacingIsValid: true,
    //   tile_spacingIsTouched: true,

    // } :

    //   {
    //     tile_name: props.data.wall_main_tile && props.data.wall_main_tile.tile_name.name || '',
    //     tile_nameIsValid: props.data.wall_main_tile && props.data.wall_main_tile.tile_name.name ? true : false,
    //     tile_nameIsTouched: props.data.wall_main_tile && props.data.wall_main_tile.tile_name.name ? true : false,
    //     tile_length: props.data.wall_main_tile && props.data.wall_main_tile.tile_length || '',
    //     tile_lengthIsValid: props.data.wall_main_tile && props.data.wall_main_tile.tile_length ? true : false,
    //     tile_lengthIsTouched: props.data.wall_main_tile && props.data.wall_main_tile.tile_length ? true : false,
    //     tile_width: props.data.wall_main_tile && props.data.wall_main_tile.tile_width || '',
    //     tile_widthIsValid: props.data.wall_main_tile && props.data.wall_main_tile.tile_width ? true : false,
    //     tile_widthIsTouched: props.data.wall_main_tile && props.data.wall_main_tile.tile_width ? true : false,
    //     tile_spacing: props.data.wall_main_tile && props.data.wall_main_tile.tile_spacing,
    //     tile_spacingIsValid: true,
    //     tile_spacingIsTouched: props.data.wall_main_tile && props.data.wall_main_tile.tile_spacing ? true : false,
    //   })
    // setInstallChoicesInputs(props.data.wall_floor ? {
    //   installation_angle_id: props.data.wall_floor.installation_angle_id,
    //   installation_angle_idIsValid: true,
    //   installation_angle_idIsTouched: true,
    //   installation_start_id: props.data.wall_floor.installation_start_id,
    //   installation_start_idIsValid: true,
    //   installation_start_idIsTouched: true
    // } : {
    //   installation_angle_id: props.data.wall_main_tile && props.data.wall_main_tile.installation_angle_id || 1,
    //   installation_angle_idIsValid: true,
    //   installation_angle_idIsTouched: false,
    //   installation_start_id: props.data.wall_main_tile && props.data.wall_main_tile.installation_start_id || 1,
    //   installation_start_idIsValid: true,
    //   installation_start_idIsTouched: false
    // })

    //set special space

    // if (wallToBeFound.wall_floor && wallToBeFound.wall_floor.wall_special_space && wallToBeFound.wall_floor.wall_special_space.length > 0) {

    setSpecialSpaceArr(getSpecialSpacesArr(props.data.wall_special_space))
    setSpecial_space_type(props.data.special_space_type)

    // let specialSpacesrray = props.data.wall_special_space;
    // setSpecial_space_type(props.data.special_space_type)


    // if (specialSpacesrray.length > 0) {
    //   let specials = [];

    //   specialSpacesrray.map(el => {
    //     specialSpaceId = el.id;
    //     let wallsArr = []

    //     el.walls.map(el => {
    //       // if (wallToBeFound.wall_floor) {
    //       //el.wall
    //       wallsArr.push(el.wall.slice(5))
    //       // } else {
    //       // wallsArr.push(el.slice(5))

    //       // }
    //     })
    //     specials.push({
    //       id: el.id,
    //       specialSpaceWidth: el.width,
    //       specialSpaceWidthIsValid: true,
    //       specialSpaceWidthIsTouched: true,
    //       specialSpaceLength: el['length'],
    //       specialSpaceLengthIsValid: true,
    //       specialSpaceLengthIsTouched: true,
    //       tile_name: el.tile_name && el.tile_name.name ? el.tile_name.name : el.tile_name,
    //       nameIsTouched: true,
    //       nameIsValid: true,
    //       tile_length: el.tile_length,
    //       tile_lengthIsTouched: true,
    //       tile_lengthIsValid: true,
    //       tile_spacing: el.tile_spacing,
    //       tile_spacingIsTouched: true,
    //       tile_spacingIsValid: true,
    //       tile_width: el.tile_width,
    //       tile_widthIsTouched: true,
    //       tile_widthIsValid: true,
    //       installation_angle_id: el.installation_angle_id,
    //       installation_angle_idIsValid: true,
    //       installation_angle_idIsTouched: true,
    //       installation_start_id: el.installation_start_id,
    //       installation_start_idIsValid: true,
    //       installation_start_idIsTouched: true,

    //       //missing walls
    //       walls: wallsArr
    //     })
    //     setSpecialSpaceArr(specials)
    //   })
    // }
    // else {
    //   setSpecialSpaceArr([
    //     // {
    //     //   id: specialSpaceUniqueId(),
    //     //   specialSpaceWidth: 1,
    //     //   specialSpaceWidthIsValid: false,
    //     //   specialSpaceWidthIsTouched: false,
    //     //   specialSpaceLength: 1,
    //     //   specialSpaceLengthIsValid: false,
    //     //   specialSpaceLengthIsTouched: false,
    //     //   tile_name: "",
    //     //   nameIsTouched: false,
    //     //   nameIsValid: false,
    //     //   tile_length: "",
    //     //   tile_lengthIsTouched: false,
    //     //   tile_lengthIsValid: false,
    //     //   tile_spacing: "",
    //     //   tile_spacingIsTouched: false,
    //     //   tile_spacingIsValid: false,
    //     //   tile_width: "",
    //     //   tile_widthIsTouched: false,
    //     //   tile_widthIsValid: false,
    //     //   installation_angle_id: 1,
    //     //   installation_angle_idIsValid: false,
    //     //   installation_angle_idIsTouched: false,
    //     //   installation_start_id: 1,
    //     //   installation_start_idIsValid: false,
    //     //   installation_start_idIsTouched: false,


    //     //   walls: []
    //     // }

    //   ])
    // }


    // } else {



    // }


    //set wall straps
    // if (wallToBeFound.wall_floor && wallToBeFound.wall_floor.wall_straps && wallToBeFound.wall_floor.wall_straps.length > 0) {
    let beltsArray = props.data.wall_floor && props.data.wall_floor.wall_straps ? props.data.wall_floor.wall_straps : props.data.wall_straps || []
    if (beltsArr.length > 0) {

      let straps = [];

      beltsArray.map(el => {
        beltId = el.id;
        let wallsArr = []

        el.walls.map(el => {
          // if (props.data.wall_floor) {
          //el.wall
          wallsArr.push(el.wall.slice(5))
          // } else {
          // wallsArr.push(el.slice(5))

          // }
        })
        straps.push({
          id: el.id,

          tile_name: el.tile_name && el.tile_name.name ? el.tile_name.name : el.tile_name,
          nameIsTouched: true,
          nameIsValid: true,
          tile_length: el.tile_length,
          tile_lengthIsTouched: true,
          tile_lengthIsValid: true,
          tile_spacing: el.tile_spacing,
          tile_spacingIsTouched: true,
          tile_spacingIsValid: true,
          tile_width: el.tile_width,
          tile_widthIsTouched: true,
          tile_widthIsValid: true,

          beltsCount: el.strap_count,
          beltsCountIsValid: true,
          beltsCountIsTouched: true,
          installation_style_id: el.strap_installation_style_id,
          installation_style_idIsValid: true,
          installation_style_idIsTouched: true,
          //missing
          walls: wallsArr
          // specialSpaceWidth: el.width,
          // specialSpaceWidthIsValid: true,
          // specialSpaceWidthIsTouched: true,
          // specialSpaceLength: el['length'],
          // specialSpaceLengthIsValid: true,
          // specialSpaceLengthIsTouched: true,
          // tile_name: el.tile_name,
          // nameIsTouched: true,
          // nameIsValid: true,
          // tile_length: el.tile_length,
          // tile_lengthIsTouched: true,
          // tile_lengthIsValid: true,
          // tile_spacing: el.tile_spacing,
          // tile_spacingIsTouched: true,
          // tile_spacingIsValid: true,
          // tile_width: el.tile_width,
          // tile_widthIsTouched: true,
          // tile_widthIsValid: true,
          // installation_angle_id: el.installation_angle_id,
          // installation_angle_idIsValid: true,
          // installation_angle_idIsTouched: true,
          // installation_start_id: el.installation_start_id,
          // installation_start_idIsValid: true,
          // installation_start_idIsTouched: true,

          //missing walls
          //  walls: []
        })
        setBeltsArr(straps)
      })
    } else {

      setBeltsArr([
        {
          id: beltUniqueId(),
          nameIsTouched: false,
          nameIsValid: false,
          tile_length: "",
          tile_lengthIsTouched: false,
          tile_lengthIsValid: false,
          tile_name: "",
          tile_spacing: "",
          tile_spacingIsTouched: false,
          tile_spacingIsValid: false,
          tile_width: "",
          tile_widthIsTouched: false,
          tile_widthIsValid: false,

          beltsCount: 0,
          beltsCountIsValid: false,
          beltsCountIsTouched: false,
          installation_style_id: 1,
          installation_style_idIsValid: false,
          installation_style_idIsTouched: false,
          walls: []
        }

      ])
    }



  }

  const getShape = (shapeName) => {
    switch (shapeName) {
      case 'Shape1':
        return <Shape1 />

      case 'Shape2':
        return <Shape22 />

      case 'Shape3':
        return <Shape33 />

      case 'Shape4':
        return <Shape44 />

      case 'Shape5':
        return <Shape55 />
    }
  }

  //belts
  const changeBeltName = (target) => {

    let beltsCopy = JSON.parse(JSON.stringify(beltsArr))

    beltsCopy[beltsCopy.findIndex(belt => belt.id === parseInt(target.name))].nameIsValid = validate('beltsName', target.value);
    beltsCopy[beltsCopy.findIndex(belt => belt.id === parseInt(target.name))].tile_name = target.value
    setBeltsArr(beltsCopy)
  }

  const changeBeltTileLength = (target) => {
    let beltsCopy = JSON.parse(JSON.stringify(beltsArr))
    beltsCopy[beltsCopy.findIndex(belt => belt.id === parseInt(target.name))].tile_lengthIsTouched = true
    beltsCopy[beltsCopy.findIndex(belt => belt.id === parseInt(target.name))].tile_lengthIsValid = validate('beltsLength', target.value);
    beltsCopy[beltsCopy.findIndex(belt => belt.id === parseInt(target.name))].tile_length = (target.value);
    setBeltsArr(beltsCopy)
  }

  const changeBeltTileWidth = (target) => {
    let beltsCopy = JSON.parse(JSON.stringify(beltsArr))
    beltsCopy[beltsCopy.findIndex(belt => belt.id === parseInt(target.name))].tile_widthIsTouched = true
    beltsCopy[beltsCopy.findIndex(belt => belt.id === parseInt(target.name))].tile_widthIsValid = validate('beltsWidth', target.value);
    beltsCopy[beltsCopy.findIndex(belt => belt.id === parseInt(target.name))].tile_width = (target.value);
    setBeltsArr(beltsCopy)
  }
  const changeBeltTileSpacing = (target) => {
    let beltsCopy = JSON.parse(JSON.stringify(beltsArr))
    beltsCopy[beltsCopy.findIndex(belt => belt.id === parseInt(target.name))].tile_spacingIsTouched = true
    beltsCopy[beltsCopy.findIndex(belt => belt.id === parseInt(target.name))].tile_spacingIsValid = validate('beltsSpacing', target.value);
    beltsCopy[beltsCopy.findIndex(belt => belt.id === parseInt(target.name))].tile_spacing = (target.value);
    setBeltsArr(beltsCopy)
  }

  const changeBeltCount = (target) => {
    let beltsCopy = JSON.parse(JSON.stringify(beltsArr))
    beltsCopy[beltsCopy.findIndex(belt => belt.id === parseInt(target.name))].beltsCountIsTouched = true
    beltsCopy[beltsCopy.findIndex(belt => belt.id === parseInt(target.name))].beltsCountIsValid = validate('beltsCount', target.value);
    beltsCopy[beltsCopy.findIndex(belt => belt.id === parseInt(target.name))].beltsCount = (target.value);
    setBeltsArr(beltsCopy)
  }

  const changeBeltStyle = (target) => {

    let beltsCopy = JSON.parse(JSON.stringify(beltsArr))
    if (target.value == 2) {

      let cpyWalls = []
      walls.map(wall => cpyWalls.push(wall.number))
      beltsCopy[beltsCopy.findIndex(belt => belt.id === parseInt(target.name))].walls = cpyWalls;
    } else {
      beltsCopy[beltsCopy.findIndex(belt => belt.id === parseInt(target.name))].walls = []
    }
    beltsCopy[beltsCopy.findIndex(belt => belt.id === parseInt(target.name))].installation_style_idIsTouched = true
    beltsCopy[beltsCopy.findIndex(belt => belt.id === parseInt(target.name))].installation_style_idIsValid = validate('cutting_style_id', target.value);
    beltsCopy[beltsCopy.findIndex(belt => belt.id === parseInt(target.name))].installation_style_id = (target.value);
    setBeltsArr(beltsCopy)
  }

  const addWallToBelt = (id, value) => {
    let beltsCopy = JSON.parse(JSON.stringify(beltsArr))

    let cpyWalls = beltsCopy[beltsCopy.findIndex(belt => belt.id == id)].walls;

    beltsCopy[beltsCopy.findIndex(belt => belt.id == id)].walls = [...cpyWalls, value]

    setBeltsArr(beltsCopy)



  };

  const checkWallLengthValidation = () => {
    let isValid = walls.every(el => el.length > 0)

    return isValid
  }
  const deleteWallFromBelt = (id, value) => {
    let beltsCopy = JSON.parse(JSON.stringify(beltsArr))

    let cpyWalls = beltsCopy[beltsCopy.findIndex(belt => belt.id == id)].walls;

    beltsCopy[beltsCopy.findIndex(belt => belt.id == id)].walls = (cpyWalls).filter(el => el != value)

    setBeltsArr(beltsCopy)
  }

  const addNewBelt = () => {
    let belt = {

      id: beltUniqueId(),
      nameIsTouched: false,
      nameIsValid: false,
      tile_length: "",
      tile_lengthIsTouched: false,
      tile_lengthIsValid: false,
      tile_name: "",
      tile_spacing: "",
      tile_spacingIsTouched: false,
      tile_spacingIsValid: false,
      tile_width: "",
      tile_widthIsTouched: false,
      tile_widthIsValid: false,

      beltsCount: 0,
      beltsCountIsValid: false,
      beltsCountIsTouched: false,
      installation_style_id: beltsArr && beltsArr.length > 0 ? beltsArr[0].installation_style_id : 1,
      installation_style_idIsValid: false,
      installation_style_idIsTouched: false,
      walls: []
    }
    let beltsCopy = JSON.parse(JSON.stringify(beltsArr))
    setBeltsArr([...beltsCopy, belt])

  }

  const deleteBelt = (id) => {

    let beltsCopy = JSON.parse(JSON.stringify(beltsArr))
    let newBelts = beltsCopy.filter(belt => belt.id != id);

    setBeltsArr(newBelts);
    let cpyoutputs = [...beltsOutput];
    let newOutputs = cpyoutputs.filter(el => el.id !== id)
    setBeltsOutput(newOutputs)
  }

  //special Space
  const changeSpecialSpaceWidth = (target) => {

    let spacesCopy = JSON.parse(JSON.stringify(specialSpaceArr))
    spacesCopy[spacesCopy.findIndex(space => space.id === parseInt(target.name))].specialSpaceWidthIsValid = validate('width', target.value);
    spacesCopy[spacesCopy.findIndex(space => space.id === parseInt(target.name))].specialSpaceWidthIsTouched = true;
    spacesCopy[spacesCopy.findIndex(space => space.id === parseInt(target.name))].specialSpaceWidth = target.value
    setSpecialSpaceArr(spacesCopy)
  }

  const changeSpecialSpaceLength = (target) => {


    let spacesCopy = JSON.parse(JSON.stringify(specialSpaceArr))
    spacesCopy[spacesCopy.findIndex(space => space.id === parseInt(target.name))].specialSpaceLength = target.value
    spacesCopy[spacesCopy.findIndex(space => space.id === parseInt(target.name))].specialSpaceLengthIsTouched = true;
    let sumHieghts = 0;
    spacesCopy.forEach(element => {
      sumHieghts += Number(element.specialSpaceLength)
    });

    let availableHieght = inputs.height - sumHieghts;


    spacesCopy[spacesCopy.findIndex(space => space.id === parseInt(target.name))].specialSpaceLengthIsValid = validate('Spacelength', target.value) && availableHieght >= 0;


    setSpecialSpaceArr(spacesCopy)
  }
  const getRoomWalls = () => {

    switch (selectedShapeName) {
      case 'Shape5':
        return [1, 2, 3, 4, 5, 6, 7, 8];
      case 'Shape4':
        return [1, 2, 3, 4, 5, 6]
      case 'Shape3':
        return [1, 2, 3, 4, 5, 6, 7, 8]
      case 'Shape2':
        return [1, 2, 3, 4]
      case 'Shape1':

        return [1, 2, 3, 4]

      default:
        break;
    }
  }
  const changeSpecialSpaceName = (target) => {

    let spacesCopy = JSON.parse(JSON.stringify(specialSpaceArr))
    spacesCopy[spacesCopy.findIndex(space => space.id === parseInt(target.name))].nameIsValid = validate('name', target.value);
    spacesCopy[spacesCopy.findIndex(space => space.id === parseInt(target.name))].tile_name = target.value
    setSpecialSpaceArr(spacesCopy)
  }

  const changeSpecialSpaceTileLength = (target) => {
    let spacesCopy = JSON.parse(JSON.stringify(specialSpaceArr))
    spacesCopy[spacesCopy.findIndex(space => space.id === parseInt(target.name))].tile_lengthIsTouched = true
    spacesCopy[spacesCopy.findIndex(space => space.id === parseInt(target.name))].tile_lengthIsValid = validate('length', target.value);
    spacesCopy[spacesCopy.findIndex(space => space.id === parseInt(target.name))].tile_length = (target.value);
    setSpecialSpaceArr(spacesCopy)
  }

  const changeSpecialSpaceTileWidth = (target) => {
    let spacesCopy = JSON.parse(JSON.stringify(specialSpaceArr))
    spacesCopy[spacesCopy.findIndex(space => space.id === parseInt(target.name))].tile_widthIsTouched = true
    spacesCopy[spacesCopy.findIndex(space => space.id === parseInt(target.name))].tile_widthIsValid = validate('width', target.value);
    spacesCopy[spacesCopy.findIndex(space => space.id === parseInt(target.name))].tile_width = (target.value);
    setSpecialSpaceArr(spacesCopy)
  }
  const changeSpecialSpaceTileSpacing = (target) => {
    let spacesCopy = JSON.parse(JSON.stringify(specialSpaceArr))
    spacesCopy[spacesCopy.findIndex(space => space.id === parseInt(target.name))].tile_spacingIsTouched = true
    spacesCopy[spacesCopy.findIndex(space => space.id === parseInt(target.name))].tile_spacingIsValid = validate('space', target.value);
    spacesCopy[spacesCopy.findIndex(space => space.id === parseInt(target.name))].tile_spacing = (target.value);
    setSpecialSpaceArr(spacesCopy)
  }

  const getWidthBasedOnRooms = () => {

    switch (selectedShapeName) {
      case 'Shape5':
        return Number.parseFloat(walls[0].length + walls[1].length + walls[2].length + walls[3].length + walls[4].length + walls[5].length + walls[6].length + walls[7].length).toFixed(2);

        break;
      case 'Shape4':
        // wallsCopy[0].length = wallsCopy[2].length + wallsCopy[4].length;
        // wallsCopy[5].length = wallsCopy[1].length + wallsCopy[3].length;
        return Number.parseFloat(walls[0].length + walls[1].length + walls[2].length + walls[3].length + walls[4].length + walls[5].length).toFixed(2)
        break;
      case 'Shape3':
        return Number.parseFloat(walls[0].length + walls[1].length + walls[2].length + walls[3].length + walls[4].length + walls[5].length + walls[6].length + walls[7].length).toFixed(2);

      case 'Shape2':
        return Number.parseFloat(walls[0].length + walls[1].length + walls[2].length + walls[3].length).toFixed(2);

      case 'Shape1':
        return Number.parseFloat(walls[0].length + walls[1].length + walls[2].length + walls[3].length).toFixed(2);

      default:
        break;
    }
  }
  // const changeSpecialSpaceCount = (target) => {
  //     let spacesCopy = JSON.parse(JSON.stringify(specialSpaceArr))
  //     spacesCopy[spacesCopy.findIndex(space => space.id === parseInt(target.name))].specialSpacesCountIsTouched = true
  //     spacesCopy[spacesCopy.findIndex(space => space.id === parseInt(target.name))].specialSpacesCountIsValid = validate('spacesCount', target.value);
  //     spacesCopy[spacesCopy.findIndex(space => space.id === parseInt(target.name))].specialSpacesCount = (target.value);
  //     setSpecialSpaceArr(spacesCopy)
  // }

  const changeSpecialSpaceAngle = (target) => {

    let spacesCopy = JSON.parse(JSON.stringify(specialSpaceArr))
    spacesCopy[spacesCopy.findIndex(space => space.id === parseInt(target.name))].installation_angle_idIsTouched = true
    spacesCopy[spacesCopy.findIndex(space => space.id === parseInt(target.name))].installation_angle_idIsValid = validate('angle', target.value);
    spacesCopy[spacesCopy.findIndex(space => space.id === parseInt(target.name))].installation_angle_id = (target.value);
    setSpecialSpaceArr(spacesCopy)

  }
  const changeSpecialSpaceStart = (target) => {

    let spacesCopy = JSON.parse(JSON.stringify(specialSpaceArr))
    spacesCopy[spacesCopy.findIndex(space => space.id === parseInt(target.name))].installation_start_idIsTouched = true
    spacesCopy[spacesCopy.findIndex(space => space.id === parseInt(target.name))].installation_start_idIsValid = validate('start', target.value);
    spacesCopy[spacesCopy.findIndex(space => space.id === parseInt(target.name))].installation_start_id = (target.value);
    setSpecialSpaceArr(spacesCopy)

  }
  const addWallToSpecialSpace = (id, value) => {
    let spacesCopy = JSON.parse(JSON.stringify(specialSpaceArr))

    let cpyWalls = spacesCopy[spacesCopy.findIndex(space => space.id == id)].walls;

    spacesCopy[spacesCopy.findIndex(space => space.id == id)].walls = [...cpyWalls, value]

    setSpecialSpaceArr(spacesCopy)



  };

  const deleteWallFromSpecialSpace = (id, value) => {
    let spacesCopy = JSON.parse(JSON.stringify(specialSpaceArr))

    let cpyWalls = spacesCopy[spacesCopy.findIndex(space => space.id == id)].walls;

    spacesCopy[spacesCopy.findIndex(space => space.id == id)].walls = (cpyWalls).filter(el => el != value)

    setSpecialSpaceArr(spacesCopy)
  }

  const addNewSpecialSpace = () => {

    if (special_space_type != 'part_height' && special_space_type != "all_height") {


      alert("يجب الاختيار النوع  ")
      return;
    }
    let specialSpace = {

      id: specialSpaceUniqueId(),
      specialSpaceWidth: special_space_type == 'part_height' ? getWidthBasedOnRooms() : '',
      specialSpaceWidthIsValid: special_space_type == 'part_height' ? (getWidthBasedOnRooms() ? true : false) : false,
      specialSpaceWidthIsTouched: true,

      specialSpaceLength: special_space_type == "all_height" ? inputs.height : "",

      specialSpaceLengthIsValid: special_space_type == "all_height" ? true : false,
      specialSpaceLengthIsTouched: special_space_type == "all_height" ? true : false,
      tile_name: "",
      nameIsTouched: false,
      nameIsValid: false,
      tile_length: "",
      tile_lengthIsTouched: false,
      tile_lengthIsValid: false,
      tile_spacing: "",
      tile_spacingIsTouched: false,
      tile_spacingIsValid: false,
      tile_width: "",
      tile_widthIsTouched: false,
      tile_widthIsValid: false,
      installation_angle_id: 1,
      installation_angle_idIsValid: false,
      installation_angle_idIsTouched: false,
      installation_start_id: 1,
      installation_start_idIsValid: false,
      installation_start_idIsTouched: false,
      // specialSpacesCount: 0,
      // specialSpacesCountIsValid: false,
      // specialSpacesCountIsTouched: false,

      walls: special_space_type == "all_height" ? [] : getRoomWalls()
    }
    let spacesCopy = JSON.parse(JSON.stringify(specialSpaceArr))
    setSpecialSpaceArr([...spacesCopy, specialSpace])

  }

  const deleteSpecialSpace = (id) => {
    let spacesCopy = JSON.parse(JSON.stringify(specialSpaceArr))
    let newSpaces = spacesCopy.filter(space => space.id != id);
    setSpecialSpaceArr(newSpaces)
    let spacesoutputCopy = JSON.parse(JSON.stringify(spacesOutput))
    let newOutput = spacesoutputCopy.filter(space => space.id != id);
    setSpacesOutput(newOutput)
  }
  const checkFormValidation = () => {
    let coloringValid = true;

    if (coloring && coloring.length >= 1) {
      if (coloring.length == 1) {
        coloringValid = (!coloring[0].columns && !coloring[0].name && !coloring[0].rows) || (coloring[0].columns && coloring[0].name && coloring[0].rows) ? true : false
      } else {
        for (let color of coloring) {
          if (color.columns && color.name && color.rows) {
            coloringValid = true
          } else {
            coloringValid = false;
            break;
          }
        }
      }
    } else {
      coloringValid = true;
    }
    let beltsValid = true;

    if (beltsArr.length >= 1) {

      if (beltsArr.length == 1) {

        beltsValid = (
          // !beltsArr[0].tile_nameIsTouched &&
          !beltsArr[0].tile_lengthIsTouched && !beltsArr[0].tile_spacingIsTouched
          && !beltsArr[0].tile_widthIsTouched && !beltsArr[0].beltsCountIsTouched && beltsArr[0].walls.length == 0) ||
          (
            // beltsArr[0].nameIsValid &&
            beltsArr[0].tile_lengthIsValid && beltsArr[0].tile_spacingIsValid
            && beltsArr[0].tile_widthIsValid && beltsArr[0].beltsCountIsValid && beltsArr[0].walls.length >= 1)


      } else {

        for (let belt of beltsArr) {

          if (belt.tile_lengthIsValid
            //  && belt.nameIsValid 
            && belt.tile_spacingIsValid && belt.tile_widthIsValid
            && belt.beltsCountIsValid && belt.walls.length >= 1) {
            beltsValid = true
          } else {
            beltsValid = false;
            break;
          }
        }
      }

    } else {
      beltsValid = true


    }

    let specialSpaceValid = true;

    if (specialSpaceArr.length >= 1) {

      if (specialSpaceArr.length == 1) {

        specialSpaceValid = (!specialSpaceArr[0].specialSpaceLengthIsTouched && !specialSpaceArr[0].specialSpaceLengthIsTouched && !specialSpaceArr[0].tile_nameIsTouched && !specialSpaceArr[0].tile_lengthIsTouched && !specialSpaceArr[0].tile_spacingIsTouched
          && !specialSpaceArr[0].tile_widthIsTouched && specialSpaceArr[0].walls.length == 0) ||
          (specialSpaceArr[0].specialSpaceLengthIsValid && specialSpaceArr[0].specialSpaceWidthIsValid
            //  && specialSpaceArr[0].nameIsValid 
            && specialSpaceArr[0].tile_lengthIsValid && specialSpaceArr[0].tile_spacingIsValid
            && specialSpaceArr[0].tile_widthIsValid && specialSpaceArr[0].walls.length >= 1)


      } else {

        for (let space of specialSpaceArr) {

          if (space.specialSpaceWidthIsValid
            && space.specialSpaceLengthIsValid
            && space.tile_lengthIsValid
            // && space.nameIsValid
            &&
            space.tile_spacingIsValid && space.tile_widthIsValid
            && space.walls.length >= 1) {
            specialSpaceValid = true
          } else {
            specialSpaceValid = false;
            break;
          }
        }
      }

    } else {
      specialSpaceValid = true


    }


    return checkWallLengthValidation() && inputs.nameIsValid &&
      inputs.heightIsValid &&
      // (inputs.tile_nameIsValid || inputs.tile_name_text !== '') &&
      inputs.tile_lengthIsValid &&
      inputs.tile_widthIsValid &&
      inputs.tile_spacingIsValid
      && MainSlabInputs.tile_nameIsValid &&
      MainSlabInputs.tile_lengthIsValid &&
      MainSlabInputs.tile_widthIsValid &&
      MainSlabInputs.tile_spacingIsValid && beltsValid && specialSpaceValid && coloringValid
    // inputs.cycalo_heightIsValid &&
    // inputs.cutting_style_idIsValid &&
    // inputs.installation_angle_idIsValid &&
    // inputs.installation_start_idIsValid
  }
  const [beltsArr, setBeltsArr] = useState([
    {
      id: beltUniqueId(),
      nameIsTouched: false,
      nameIsValid: false,
      tile_length: "",
      tile_lengthIsTouched: false,
      tile_lengthIsValid: false,
      tile_name: "",
      tile_spacing: "",
      tile_spacingIsTouched: false,
      tile_spacingIsValid: false,
      tile_width: "",
      tile_widthIsTouched: false,
      tile_widthIsValid: false,

      beltsCount: 0,
      beltsCountIsValid: false,
      beltsCountIsTouched: false,
      installation_style_id: 1,
      installation_style_idIsValid: false,
      installation_style_idIsTouched: false,
      walls: []
    },
  ])

  const [specialSpaceArr, setSpecialSpaceArr] = useState([
    //   {
    //   id: specialSpaceUniqueId(),
    //   specialSpaceWidth: 1,
    //   specialSpaceWidthIsValid: false,
    //   specialSpaceWidthIsTouched: false,
    //   specialSpaceLength: 1,
    //   specialSpaceLengthIsValid: false,
    //   specialSpaceLengthIsTouched: false,
    //   tile_name: "",
    //   nameIsTouched: false,
    //   nameIsValid: false,
    //   tile_length: "",
    //   tile_lengthIsTouched: false,
    //   tile_lengthIsValid: false,
    //   tile_spacing: "",
    //   tile_spacingIsTouched: false,
    //   tile_spacingIsValid: false,
    //   tile_width: "",
    //   tile_widthIsTouched: false,
    //   tile_widthIsValid: false,
    //   installation_angle_id: 1,
    //   installation_angle_idIsValid: false,
    //   installation_angle_idIsTouched: false,
    //   installation_start_id: 1,
    //   installation_start_idIsValid: false,
    //   installation_start_idIsTouched: false,


    //   walls: []

    // }
  ])
  const resetResults = () => {
    setWallResult({
      quantity: 0,
      cycalo_mt: 0,
      waste: 0,
      wall_main_quantity_after_waste: '',
      wall_main_quantity: '',
      wall_main_vertical_count: '', wall_main_horizontal_count: '',
      floor_quantity_after_waste: '', floor_quantity: '',
      columns_remaining_count: '',
      rows_remaining_count: '', tiles_remaining_count: ''
    });
  }
  const handleInputHeightChange = (e) => {

    let cpyInputs = { ...inputs, height: e.target.value, heightIsTouched: true, heightIsValid: validate('height', e.target.value) }

    setInputsValues(cpyInputs)
    setSpecialSpaceArr([]);
    setSpacesOutput([]);
    resetResults();
    resetColoring()
  }
  const onSpecialSpaceTypeChange = (val) => {
    setSpecial_space_type(val);
    setSpecialSpaceArr([]);
    // setSpacesOutput([])

  }
  const saveHandler = (saveInputs) => {
    let wallsObj = {};
    walls.map(wall => {
      wallsObj = Object.assign(wallsObj, { [`wall_${wall.number}`]: wall.length })
    })
    let wallsTobelt = [];
    let wallsStrap = []
    let wallsSpecialSpace = []

    let cpyBeltsArr = beltsArr;
    let cpyColoring = coloring
    let coloringArr = []


    let cpySpecialSpacesArr = specialSpaceArr;
    if (cpyColoring.length > 0) {
      if (cpyColoring.length == 1 && !cpyColoring[0].name && !cpyColoring[0].rows && !cpyColoring[0].columns) {
        // setBeltsArr([])
        cpyColoring = []
        coloringArr = []
      }
      for (let item of cpyColoring) {
        // let walls = item.walls.map(wall => "wall_" + wall)
        coloringArr.push({
          "name": item.name,
          // "name": tiles[0].name,
          "columns": item.columns,
          "rows": item.rows,


          // output: item.output
        })
      }

    }
    if (cpyBeltsArr.length > 0) {
      if (cpyBeltsArr.length == 1 && !cpyBeltsArr[0].tile_nameIsTouched && !cpyBeltsArr[0].tile_lengthIsTouched && !cpyBeltsArr[0].tile_widthIsTouched && !cpyBeltsArr[0].tile_spacingIsTouched
        && !cpyBeltsArr[0].beltsCountIsTouched && cpyBeltsArr[0].walls.length == 0) {
        // setBeltsArr([])
        cpyBeltsArr = []
        wallsStrap = []
      }
      for (let item of cpyBeltsArr) {
        let walls = item.walls.map(wall => "wall_" + wall)
        wallsStrap.push({
          // "tile_name": item.tile_name,
          "tile_name": tiles[0]?.name || "",

          "tile_length": item.tile_length,
          "tile_width": item.tile_width,
          "tile_spacing": item.tile_spacing,
          "strap_installation_style_id": item.installation_style_id,
          "strap_count": item.beltsCount,
          "walls": walls
        })
      }
      // if (beltsArr[0].tile_name) {
      //     let walls = beltsArr[0].walls.map(wall => "wall_" + wall)
      //     wallsStrap = [{
      //         "tile_name": beltsArr[0].tile_name,
      //         "tile_length": beltsArr[0].tile_length,
      //         "tile_width": beltsArr[0].tile_width,
      //         "tile_spacing": beltsArr[0].tile_spacing,
      //         "strap_installation_style_id": beltsArr[0].installation_style_id,
      //         "strap_count": beltsArr[0].beltsCount,
      //         "walls": walls
      //     }]
      // } else {
      //     wallsStrap = []

      // }
    }
    if (cpySpecialSpacesArr.length > 0) {
      if (cpySpecialSpacesArr.length == 1 && !cpySpecialSpacesArr[0].tile_nameIsTouched && !cpySpecialSpacesArr[0].tile_lengthIsTouched && !cpySpecialSpacesArr[0].tile_widthIsTouched && !cpySpecialSpacesArr[0].tile_spacingIsTouched
        && !cpySpecialSpacesArr[0].beltsCountIsTouched && cpySpecialSpacesArr[0].walls.length == 0) {
        // setBeltsArr([])
        cpySpecialSpacesArr = []
        wallsSpecialSpace = []
      }
      for (let item of cpySpecialSpacesArr) {
        let walls = item.walls.map(wall => "wall_" + wall)
        wallsSpecialSpace.push({
          "width": item.specialSpaceWidth,
          "length": item.specialSpaceLength,
          // "tile_name": item.tile_name,
          "tile_name": tiles[0]?.name || "",

          "tile_length": item.tile_length,
          "tile_width": item.tile_width,
          "tile_spacing": item.tile_spacing,
          "installation_angle_id": item.installation_angle_id,
          "installation_start_id": item.installation_start_id,
          "walls": walls
        })
      }
      // if (beltsArr[0].tile_name) {
      //     let walls = beltsArr[0].walls.map(wall => "wall_" + wall)
      //     wallsStrap = [{
      //         "tile_name": beltsArr[0].tile_name,
      //         "tile_length": beltsArr[0].tile_length,
      //         "tile_width": beltsArr[0].tile_width,
      //         "tile_spacing": beltsArr[0].tile_spacing,
      //         "strap_installation_style_id": beltsArr[0].installation_style_id,
      //         "strap_count": beltsArr[0].beltsCount,
      //         "walls": walls
      //     }]
      // } else {
      //     wallsStrap = []

      // }
    }


    ///update api  Done
    axios.put(BASE_URL + `/wall_floor/${props.id}`,

      {
        buyer_id: localStorage.getItem('id'),
        save_inputs: true,
        room: {
          name: inputs.name,
          height: inputs.height,
          shape: selectedShapeName,
          walls: wallsObj,
          windows: [
            {
              "width": 1.0,
              "height": 1.0,
              "count": 2
            }
          ]
        },
        // tile_name: inputs.tile_name_text !== '' ? inputs.tile_name_text : inputs.tile_name,
        floor_tile_length: inputs.tile_length,
        floor_tile_width: inputs.tile_width,
        floor_tile_spacing: inputs.tile_spacing,
        // cycalo_height: inputs.cycalo_height,//
        // cutting_style_id: inputs.cutting_style_id,
        installation_angle_id: inputs.installation_angle_id,
        installation_start_id: inputs.installation_start_id,
        wall_main_tile: {
          tile_name: MainSlabInputs.tile_name,
          tile_length: MainSlabInputs.tile_length,
          tile_width: MainSlabInputs.tile_width,
          tile_spacing: MainSlabInputs.tile_spacing,

          //////////////////////
          installation_angle_id: InstallChoicesInputs.installation_angle_id,
          installation_start_id: InstallChoicesInputs.installation_start_id,
        },
        wall_straps: wallsStrap,
        "wall_special_space": wallsSpecialSpace,
        special_space_type: special_space_type,


        coloring: coloringArr,
        coloring_type: selectedColoringType,

      },
      {
        headers: {
          "Accept": "application/json",
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          "Accept-Language": 'ar'

        }
      }
    ).then(res => {
      let beltsOutputArr = []
      let specialSpaceOutputArr = [];
      res.data.wall_special_space.map(el => {
        specialSpaceOutputArr.push({
          id: el.id, after_waste: el.after_waste,
          before_waste: el.before_waste
        })
      });
      setSpacesOutput(specialSpaceOutputArr)

      res.data.wall_straps.map(el => {
        beltsOutputArr.push({
          id: el.id, output: el.output
        })
      });

      setBeltsOutput(beltsOutputArr)
      setWallResult(res.data.output)
      setColoring(res.data.coloring)
      setSelectedColoringType(res.data.coloring_type)
      toast.success('تم الحفظ', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    }).catch(error => {
      const errorResult = handleError(error)
      const errMsg = errorResult.message;
      toast.error(errMsg, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (errorResult.type == errorTypes.unauthorized) {
        setTimeout(() => {

          localStorage.clear();
          history.push("/home");
        }, 1000);
      }

    })
  }
  return (
    <Modal show={props.show}
      onShow={() => getWall()} dialogClassName="edit-modal modal-lg edit-wall">
      <ToastContainer />
      <Modal.Header>
        <Modal.Title>
          <Close className="close-icon" onClick={() => handleModalClose()} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <div className="floor-container">
          body
        </div> */}
        <div className="edit-wall-container"
        // style={{ display: "block" }}
        >

          <div className="section" >

            <label>حساب الكميات المستخدمه للجدران </label>

            <div className="wall">
              <div className="wall-name">
                <div style={{ display: "flex", flexDirection: "column", width: "84%", position: "relative" }}>
                  <input
                    className="floor-name-input"
                    name="name"
                    value={inputs.name}
                    onChange={(e) => handleInputChange(e)} />
                  {!inputs.nameIsValid && inputs.nameIsTouched && <span className="error-msg">اسم الغرفة خطأ</span>}
                </div>
                <span className="def">اسم الغرفة</span>
              </div>
              <div className="wall-shape">
                <Shape5 onClick={() => setSelectedShape('Shape5', <Shape55 />, 8)} className={selectedShapeName === 'Shape5' ? "shape selected" : "shape"} />
                <Shape4 onClick={() => setSelectedShape('Shape4', <Shape44 />, 6)} className={selectedShapeName === 'Shape4' ? "shape selected" : "shape"} />
                <Shape3 onClick={() => setSelectedShape('Shape3', <Shape33 />, 8)} className={selectedShapeName === 'Shape3' ? "shape selected" : "shape"} />
                <Shape2 onClick={() => setSelectedShape('Shape2', <Shape22 />, 4)} className={selectedShapeName === 'Shape2' ? "shape selected" : "shape"} />
                <Shape1 onClick={() => setSelectedShape('Shape1', <Shape1 />, 4)} className={selectedShapeName === 'Shape1' ? "shape selected" : "shape"} />
                <span className="def"> اختيار شكل الغرفة </span>
              </div>
              <div className="wall-details">
                <div className="wall-details-selected-shape">
                  {selectedShapeComponent}
                </div>
                <div className="wall-details-selected-measure">
                  <label>قياسات الغرفة</label>
                  <div className="wall-side">
                    <span className="def">ارتفاع الجدار</span>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <input
                        // min={1}
                        type='number'
                        style={{ marginInlineEnd: "1.5rem" }}
                        name="height"
                        value={inputs.height}
                        onChange={(e) => handleInputHeightChange(e)} />
                      {!inputs.heightIsValid && inputs.heightIsTouched && <span className="error-msg">ارتفاع الغرفة خطأ</span>}
                    </div>
                    <span className="unit">متر</span>
                  </div>
                  {/* {!checkWallLengthValidation() && <span className="error-msg">طول الضلع خطأ</span>
                  } */}
                  {
                    walls.map(wall => {
                      return (
                        <div key={wall.number} className="wall-side">
                          <ToastContainer />
                          <span className="def">ضلع {wall.number}</span>
                          <input
                            min={0}
                            disabled={setDisabledEdges(wall.number)}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            name={wall.number}
                            value={wall.length}
                            onChange={(e) => changeWallLength(e.target)} />
                          <span className="unit">متر</span>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <SlabMeasure
              parent='floor'
              // tile_name={inputs.tile_name}
              // tile_name_text={inputs.tile_name_text}
              tile_length={inputs.tile_length}
              tile_lengthIsValid={inputs.tile_lengthIsValid}
              tile_lengthIsTouched={inputs.tile_lengthIsTouched}
              tile_width={inputs.tile_width}
              tile_widthIsValid={inputs.tile_widthIsValid}
              tile_widthIsTouched={inputs.tile_widthIsTouched}
              tile_spacing={inputs.tile_spacing}
              tile_spacingIsValid={inputs.tile_spacingIsValid}
              tile_spacingIsTouched={inputs.tile_spacingIsTouched}
              cycalo_height={inputs.cycalo_height}
              cutting_style_id={inputs.cutting_style_id}
              cutTypes={cutTypes}
              tiles={tiles}
              handleInputChange={handleInputChange} />

            <InstallChoices
              // hideStackingMethod={false}
              hideStackingMethod={true}
              installationAngles={installationAngles}
              installationStarts={installationStarts}
              installation_start_id={inputs.installation_start_id}
              installation_angle_id={inputs.installation_angle_id}
              handleInputChange={handleInputChange}
            />

            <label className="wall-label">حساب كمية البلاط للجدران</label>
            <div className="wall-slab">
              <div className="wall-slab-nav">
                <button className={WallNav === 'special' ? 'active' : ''} onClick={() => setWallNav('special')}>اضافة مساحة خاصة</button>
                <button className={WallNav === 'belt' ? 'active' : ''} onClick={() => setWallNav('belt')}>اضافة حزام</button>
                <button className={WallNav === 'slab' ? 'active' : ''} onClick={() => setWallNav('slab')}>بلاطة أساسية</button>
              </div>
              {
                WallNav === 'slab' &&
                <MainSlab
                  slab={MainSlabInputs}
                  tiles={tiles}
                  handleSlabInputChange={handleMainSlabInputChange}
                  mainSlabChoices={InstallChoicesInputs}
                  installationAngles={installationAngles}
                  installationStarts={installationStarts}
                  handleSlabInstallChange={handleInstallChoicesInputChange}
                />
              }

              {

                WallNav === 'belt' &&
                <>

                  {beltsArr && beltsArr.length > 0 && beltsArr.map((beltObj, i) =>

                    <div key={beltObj.id} style={{ "border": "1px solid rgba(85,85,85,0.11)", borderRadius: "20px", margin: "1rem" }}>
                      <button className='btn btn-danger' style={{ float: "left", margin: "0.5rem" }} onClick={() => deleteBelt(beltObj.id)}> X </button>
                      <AddBelt
                        belts={beltsArr}
                        belt={beltObj}
                        // handleBeltInputChange={handleAddBeltInputChange}
                        changeBeltName={changeBeltName}
                        changeBeltTileLength={changeBeltTileLength}
                        changeBeltTileWidth={changeBeltTileWidth}
                        changeBeltTileSpacing={changeBeltTileSpacing}
                        changeBeltCount={changeBeltCount}
                        changeBeltStyle={changeBeltStyle}
                        tiles={tiles}
                        walls={walls}
                        // wallsToBelt={wallsToBelt}
                        // addBeltChoices={InstallChoicesAddBeltInputs}
                        // installationAngles={installationAngles}
                        // installationStarts={installationStarts}
                        installationStyles={installationStyles}
                        // handleInstallChoicesChange={handleAddBeltInstallChoicesInputChange}
                        addWallToBelt={addWallToBelt}
                        // addNewBelt={addNewBelt}
                        deleteWallFromBelt={deleteWallFromBelt}
                      />


                    </div>
                  )}
                  <div className="add-belt-choices-btns"
                  >
                    <button className='btn btn-transparent'
                      onClick={addNewBelt}
                    >
                      <span className="add-label">اضافة حزام</span>
                      <Plus />
                    </button>
                  </div>
                </>


              }

              {
                WallNav === 'special' &&
                <>
                  <div className='radioBtns gap-5'>
                    <div className='radioBtn'>

                      <label htmlFor="part_height">جزء من  الارتفاع </label>
                      <input type="radio" name='special_space_type' id='part_height'
                        value={"part_height"}
                        checked={special_space_type == 'part_height'}
                        onChange={() => onSpecialSpaceTypeChange('part_height')}
                      />
                    </div>
                    <div className='radioBtn'>
                      <label htmlFor="all_height"> الارتفاع كله</label>
                      <input type="radio" name='special_space_type' id='all_height'
                        value={"all_height"}
                        checked={special_space_type == 'all_height'}
                        onChange={() => onSpecialSpaceTypeChange('all_height')}
                      />
                    </div>

                  </div>
                  {specialSpaceArr && specialSpaceArr.length > 0 && specialSpaceArr.map((spaceObj, i) =>

                    <div key={spaceObj.id} style={{ "border": "1px solid rgba(85,85,85,0.11)", borderRadius: "20px", margin: "1rem" }}>
                      <button className='btn btn-danger' style={{ float: "left", margin: "0.5rem" }} onClick={() => deleteSpecialSpace(spaceObj.id)}> X </button>
                      <SpecialSpace
                        height={inputs.height}

                        specialSpace={spaceObj}
                        special_space_type={special_space_type}

                        specialSpaces={specialSpaceArr}
                        changeSpecialSpaceLength={changeSpecialSpaceLength}
                        changeSpecialSpaceWidth={changeSpecialSpaceWidth}
                        changeSpecialSpaceName={changeSpecialSpaceName}
                        changeSpecialSpaceTileLength={changeSpecialSpaceTileLength}
                        changeSpecialSpaceTileWidth={changeSpecialSpaceTileWidth}
                        changeSpecialSpaceTileSpacing={changeSpecialSpaceTileSpacing}
                        // changeSpecialSpaceCount={changeSpecialSpaceCount}
                        changeSpecialSpaceAngle={changeSpecialSpaceAngle}
                        changeSpecialSpaceStart={changeSpecialSpaceStart}
                        tiles={tiles}
                        walls={walls}
                        deleteWallFromSpecialSpace={deleteWallFromSpecialSpace}
                        addWallToSpecialSpace={addWallToSpecialSpace}
                        installationStarts={installationStarts}
                        installationAngles={installationAngles}
                      // wallsToSpecialSpace={wallsToSpecialSpace}
                      />
                    </div>
                  )}
                  <div className="add-belt-choices-btns"
                  >
                    <button className='btn btn-transparent'
                      onClick={addNewSpecialSpace}
                    >
                      <span className="add-label">اضافة مساحة خاصة</span>
                      <Plus />
                    </button>
                  </div>

                </>
              }
            </div>

          </div>
          <div>


            <div className="wall-wanted">
              <label> الكمية المطلوبة من البلاط للأرضية</label>
              <div className="floor-wanted-section">
                <div className="floor-wanted-section-labels">
                  <span className="def">الكمية قبل الهدر  </span>
                  <span className="def">الكمية بعد الهدر</span>
                </div>
                <div className="floor-wanted-section-inputs">
                  <input value={wallResult.floor_quantity} disabled={true} />
                  <input value={wallResult.floor_quantity_after_waste} disabled={true} />
                </div>
                <div className="floor-wanted-section-units">
                  <span className="unit">(م2)</span>
                  <span className="unit">(م2)</span>
                </div>
              </div>
              <label> الكمية المطلوبة من البلاط للجدران</label>
              <div className="wall-wanted-section">
                <label>البلاطات الأساسية</label>
                <div>
                  <span className="def">مساحة البلاطة الأساسية قبل الهدر  </span>
                  <input value={wallResult.wall_main_quantity} disabled={true} />
                  <span>(م2)</span>
                </div>
                <label>صفوف البلاطات الأساسية</label>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    <input value={wallResult.wall_main_vertical_count} disabled={true} style={{ width: "5rem", margin: "0rem 0.5rem 0rem 0.5rem" }} />
                    <span className='def'>اجمالي الاعمده الرأسية</span>
                  </div>
                  <div>
                    <input disabled={true} value={wallResult.wall_main_horizontal_count} style={{ width: "5rem", margin: "0rem 0.5rem 0rem 0.5rem" }} />
                    <span className='def'>اجمالي الصفوف الأفقية</span>
                  </div>
                </div>
                <div>
                  <span className="def">مساحة الجدارن   بعد الهدر</span>
                  <input disabled={true} value={wallResult.wall_main_quantity_after_waste} />
                  <span>(م2)</span>
                </div>

              </div>



            </div>
            <div className="wall-wanted">
              {spacesOutput.length > 0 && <div className='speical-spaces'>
                <label style={{ textAlign: "right" }}>المساحات الخاصة</label>

                {spacesOutput.map((el, i) => (<>

                  <div key={i} style={{ border: '1px solid rgb(217 205 205 / 62%)', padding: '1rem', marginBottom: "1rem" }}>

                    <div className='div-unit' >
                      <span className="def"> المساحه قبل الهدر   {i + 1}</span>
                      <input value={el.before_waste} disabled={true} />
                      <span>بلاطة</span>
                    </div>
                    <div>
                      <span className="def"> المساحه بعد الهدر {i + 1}</span>
                      <input value={el.after_waste} disabled={true} />
                      <span>بلاطة</span>
                    </div>
                  </div>
                </>))}
              </div>}
              {beltsOutput.length > 0 &&
                <div className='belts-slabs'>
                  <label>بلاطات الأحزمة</label>
                  {beltsOutput.map((belt, i) =>


                    <div className='div-unit' key={belt.id} >
                      <span className="def"> عدد حبات  الحزام   {i + 1} </span>
                      <input value={belt.output} disabled={true} />
                      <span>بلاطة</span>
                    </div>
                  )}
                  {/* <div>
        <span className="def">مجموع الهدر</span>
        <input />
        <span>بلاطة</span>
    </div> */}
                </div>}
            </div>
            <div className="wall-wanted">

              <label> الكمية المتبقيه من البلاط للجدران</label>
              <div className="wall-wanted-section">
                <label>البلاطات الأساسية</label>
                <div>
                  <span className="def">كمية البلاطات الأساسية</span>
                  <input value={wallResult.tiles_remaining_count} disabled={true} />
                  <span>بلاطة</span>
                </div>
                <label>صفوف البلاطات الأساسية</label>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    <input value={wallResult.columns_remaining_count} disabled={true} style={{ width: "5rem", margin: "0rem 0.5rem 0rem 0.5rem" }} />
                    <span className='def'>اجمالي الاعمده الرأسية</span>
                  </div>
                  <div>
                    <input disabled={true} value={wallResult.rows_remaining_count} style={{ width: "5rem", margin: "0rem 0.5rem 0rem 0.5rem" }} />
                    <span className='def'>اجمالي الصفوف الأفقية</span>
                  </div>
                </div>
                {/* <div>
        <span className="def">الكمية بعد الهدر</span>
        <input disabled={true} value={wallResult.wall_main_quantity_after_waste} />
        <span>بلاطة</span>
    </div> */}
                {coloring && coloring.length > 0 &&
                  <div>
                    <label>  التلوين</label>
                    <br />
                    <label style={{
                      textAlign: "center", fontSize: "14px"

                      , display: "block",
                      right: "0",
                      margin: "0",
                    }}>  طريقة التلوين </label>

                    <div className='radioBtns'>
                      <div className='radioBtn'>

                        <label htmlFor="rowsOnly" >

                          صفوف
                        </label>
                        <input id="rowsOnly" type='radio'
                          name='coloringType' value="rowsOnly"
                          checked={selectedColoringType == 'rowsOnly'}
                          onChange={() => onColoringTypeChange('rowsOnly')}
                        />
                      </div>
                      <div className='radioBtn'>

                        <label htmlFor="colsOnly">

                          اعمده
                        </label>
                        <input id="colsOnly" type='radio' name='coloringType' value="" checked={selectedColoringType == 'colsOnly'}
                          onChange={() => onColoringTypeChange('colsOnly')}

                        />
                      </div>
                      <div className='radioBtn'>

                        <label for="freeColoring">

                          تلوين حر
                        </label>
                        <input id="freeColoring" type='radio' name='coloringType' value="freeColoring"
                          checked={selectedColoringType == 'freeColoring'}
                          onChange={() => onColoringTypeChange('freeColoring')}
                        />
                      </div>
                    </div>

                    {
                      coloring.map((el, i) =>

                        <div className='color' key={el.id} >
                          <div>
                            <label>اللون {i + 1}
                            </label>
                            <button className='btn btn-sm btn-danger' style={{ float: 'left' }} onClick={() => deleteColor(el.id)}>X</button >

                          </div>
                          <div>
                            <input disabled={wallResult.floor_quantity ? false : true}
                              value={el.name}
                              name={el.id}
                              onChange={(e) => onColoringNameChange(e.target)}
                            />
                            <span className='def'>مسمي البلاطة </span>

                            {!el.nameIsValid && el.nameIsTouched && <span className="error-msg" style={{ color: 'red' }}>مسمي بلاطة اللون  خطأ</span>}

                          </div>
                          <div style={{ display: "flex", flexDirection: "row", justifyContent: "right" }}>
                            <div>

                              <input
                                type='number'
                                min={1}
                                style={{ width: "5rem", margin: "0rem 0.5rem 0rem 0.5rem" }}
                                className={selectedColoringType == 'rowsOnly' ? 'disable' : ''}
                                name={el.id}
                                value={el.columns}
                                onChange={(e) => onColoringColsChange(e.target)}
                                disabled={(wallResult.floor_quantity ? false : true) || (selectedColoringType == 'rowsOnly')} />
                              <span className='def'>الاعمده الرأسية</span>


                              {!el.columnsIsValid && el.columnsIsTouched && <span className="error-msg" style={{ color: 'red' }}>عدد  خطأ</span>}

                            </div>
                            <div>
                              <input
                                type='number'
                                min={1}
                                style={{ width: "5rem", margin: "0rem 0.5rem 0rem 0.5rem" }}
                                className={selectedColoringType == 'colsOnly' ? 'disable' : ''}
                                name={el.id}
                                value={el.rows}
                                onChange={(e) => onColoringRowsChange(e.target)}
                                disabled={(wallResult.floor_quantity ? false : true) || (selectedColoringType == 'colsOnly')} />
                              <span className='def'>الصفوف الأفقية</span>
                              {!el.rowsIsValid && el.rowsIsTouched && <span className="error-msg" style={{ color: 'red' }}>عدد  خطأ</span>}

                            </div>
                          </div>
                          <div>
                            <span className="def"> مساحة اللون </span>
                            <input disabled={true} value={el.num_of_tiles} />
                            <span>(م2)</span>
                          </div>
                          {/* <div>
                    <span className="def">الكمية بعد الهدر</span>
                    <input disabled={true} />
                    <span>بلاطة</span>
                </div> */}
                        </div>

                      )
                    }
                  </div>
                }
                <div className='color-btns'>
                  {/* <button className='clear'>
            <span>مسح</span>
        </button> */}
                  {coloring && coloring.length > 0 && <button
                    className={checkFormValidation() ? "calc" : "calc disabled"}

                    // className='calc'
                    onClick={() => saveHandler(false)}>
                    <span>
                      احسب
                    </span>
                  </button>}
                </div>
                {wallResult.floor_quantity &&
                  <div className="add-belt-choices-btns"
                  >
                    <button className='btn btn-transparent'
                      onClick={addNewColor}
                    >
                      <span className="add-label">اضافة لون </span>
                      <Plus />
                    </button>
                  </div>}
              </div>


              <button
                //  class="floor-wanted-btn"
                onClick={() => saveHandler(true)}
                className={!checkFormValidation() ? "floor-wanted-btn calc disabled" : "floor-wanted-btn calc "}

                disabled={!checkFormValidation()}

              >حفظ</button>

            </div>
          </div>

        </div >
      </Modal.Body>
    </Modal>
  )

}
export default EditWall;