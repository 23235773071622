import React, { useState } from 'react';
import { useParams } from "react-router-dom"
import axios from 'axios';
import { BASE_URL } from '../../constants/config'
import "./user-info.scss"
import { useEffect } from 'react';
import PrintComponent from '../../components/printComponent/printComponent';
import ContentTable from '../../components/contentTable/contentTable';
// import contentTableData
const UserInfo = () => {
  const [contentTableData, setContentTableData] = useState([]);

  let slug = useParams();
  const [err, setErr] = useState(false)
  let loading = true;
  useEffect(() => {
    
    axios.get(`${BASE_URL}/buyer/${slug.id}/rooms`,
      {
        headers: {
          "Accept": "application/json",
          "Accept-Language": 'ar'

          // Authorization: `Bearer ${localStorage.getItem('token')}`
        },

      }).then(res => {
        setErr(false)
        setContentTableData(res.data.rooms.map(el => {
          return {
            id: el.id, cyclo: el.area.cyclo, floorArea: el.area.floorArea, wallArea: el.area.wallArea, name: el.name, height: el.height
          }
        }))
        loading = false
  

      

      }).catch(error => {
        setErr(true)
        loading = false
      
      })
  }, [slug.id])
  return (<>
    {
 
      err ?
        <>
          {<div className='not-exist'>
            <h2>

              النموذج غير موجود برجاء ادخال رقم صحيح
            </h2>
          </div>}
        </> :

        contentTableData.length > 0 ?
          <div className="container room-container-total ">

            <PrintComponent roomsWithHieght={contentTableData.filter(el => el.wallArea != 0)}
              roomsWithoutHieght={contentTableData.filter(el => el.wallArea == 0)} />

            {/* <div className="room-container-total-save">
    <button onClick={() => { saveForm() }}>حفظ النموذج</button>
</div> */}
            {contentTableData.filter(el => el.height != 0).length > 0 &&
              <div className="wrapper">
                <label>اجمالي الجدران</label>
                <div className="room-container-total-table">
                  <ContentTable
                    parentComponent={'roomWithHieght2'}
                    // deleteItem={deleteRoom}
                    headers={
                      [
                        'مساحة الجدران',
                        'مساحة الغرفة',
                        'مسمي الغرفة',
                        // 'المحيط',
                        // 'تعديل',
                        // 'حذف'
                      ]}
                    data={contentTableData} />
                </div>
              </div>
            }
            {contentTableData.filter(el => el.height == 0).length > 0 &&
              <div className="wrapper">
                <label>اجمالي الارضيات </label>
                <div className="room-container-total-table">
                  <ContentTable
                    parentComponent={'roomWithOutHieght2'}
                    // deleteItem={deleteRoom}
                    headers={
                      [
                        'مساحة الغرفة',
                        'المحيط',
                        'مسمي الغرفة',
                        //'مساحة الجدران',
                        // 'تعديل',
                        // 'حذف'
                      ]}
                    data={contentTableData} />
                </div>
              </div>
            }
          </div> :
          <>
            {
              <div className="container room-container-total ">لا  يوجد غرف</div>}
          </>
    }


  </>)

}
export default UserInfo