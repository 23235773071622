import React, { useEffect, useState } from 'react';
import './clientOrders.scss';
import ContentTable from '../../components/contentTable/contentTable';
import axios from 'axios';
import { BASE_URL } from '../../constants/config'
import FilterOrder from './filterOrders/filterOrder';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { handleError } from '../../utilities/handleError';
import * as errorTypes from "../../utilities/errorTypes"
const ClientOrders = () => {
    let history = useHistory();

    const ref = React.createRef()
    const [headers, setHeaders] = useState([])
    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [searchVal, setSearchVal] = useState("")
    const handleSearch = (e) => {

        setSearchVal(e.target.value)

        let filterData = data.filter(el => (el.identifier).includes(e.target.value) || (el.phone).includes(e.target.value))

        setFilteredData(filterData)
    }
    useEffect(() => {
        axios.get(`${BASE_URL}/buyer`, {
            headers: {
                "Accept": "application/json",
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                "Accept-Language": 'ar'

            }
        }).then(res => {


            setHeaders(Object.keys(res.data[0]))
            setData(res.data)
            setFilteredData(res.data)
        }).catch(error => {
            const errorResult = handleError(error)
            const errMsg = errorResult.message;
            toast.error(errMsg, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            if (errorResult.type == errorTypes.unauthorized) {
                setTimeout(() => {

                    localStorage.clear();
                    history.push("/home");
                }, 1000);
            }

        })
    }, []);

    return (
        <div className="client-orders">
            <div className='container'>
                <div className='colors'>

                    <div className='d-flex gap-2'>

                        <span className=' out-company'>
                        </span>
                        خارج الشركه
                    </div>
                    <div className='d-flex gap-2'>

                        <span className=' in-company'>

                        </span>
                        داخل الشركه
                    </div>
                </div>

            </div>
            {data && data.length >= 1 && <FilterOrder searchVal={searchVal} handleSearch={handleSearch} />}
            {/* <DownloadPage id="table" /> */}


            <ContentTable
                className="table"
                // ref={ref}
                parentComponent={'orders'}
                headers={headers}
                data={filteredData} />
        </div>
    );
}
export default ClientOrders;