import React from 'react'
import './not-found.scss';

import { Link } from "react-router-dom"
function NotFound() {


  return (
    <div className='not-found'>
      <div>

        <h2>

          الصفحة غير موجودة
        </h2>
        <p>
          <Link className='btn btn-transparent' to="/">الرئيسيه</Link> الرجوع الي
        </p>
      </div>
    </div>
  );
}

export default NotFound;
