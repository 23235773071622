export const getInstlationStart = () => {
  return JSON.parse(localStorage.getItem("instlationStart"))
}
export const setInstlationStart = (instalations) => {
  localStorage.setItem("instlationStart", JSON.stringify(instalations));

}
export const getInstlationAngle = () => {
  return JSON.parse(localStorage.getItem("instlationAngle"))
}

export const setInstlationStyle = (instalations) => {
  localStorage.setItem("instlationStyle", JSON.stringify(instalations));

}
export const getInstlationStyle = () => {
  return JSON.parse(localStorage.getItem("instlationStyle"))
}

export const setInstlationAngle = (instalations) => {
  localStorage.setItem("instlationAngle", JSON.stringify(instalations));

}
export const getCutting_style = () => {
  return JSON.parse(localStorage.getItem("cutting_style"))
}
export const setCutting_style = (cutting) => {
  localStorage.setItem("cutting_style", JSON.stringify(cutting));

}
export const getTiles = () => {
  return JSON.parse(localStorage.getItem("tiles"))
}
export const setTiles = (tiles) => {
  localStorage.setItem("tiles", JSON.stringify(tiles));

}