import React, { useState, useEffect } from "react";
import './editcyclo.scss';
import { Modal } from "react-bootstrap";
import { ReactComponent as Close } from '../../../assets/Icon ionic-ios-close-circle-outline.svg';
import Doors from '../../../components/doors/doors';
import useSignUpForm from '../../../hooks/useSignUpForm'
import axios from 'axios';
import { BASE_URL } from '../../../constants/config'
import { ReactComponent as Width } from '../../../assets/Group 3508.svg';
import { ReactComponent as Length } from '../../../assets/tall.svg';
import validate from '../../../utilities/validation';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as basicFn from '../../../store/actions/basicFn';

import { useHistory } from 'react-router-dom'
import { handleError } from "../../../utilities/handleError";
import * as errorTypes from "../../../utilities/errorTypes"
var doorId = 0;

const EditCyclo = (props) => {
    let history = useHistory();
    const handleModalClose = () => {
        props.handleClose();
    }

    const doorUniqueId = () => {
        doorId += 1
        return doorId;
    }

    const { handleInputChange, inputs, clearInputs, setInputsValues } = useSignUpForm(
        {
            tile_length: '',
            tile_lengthIsValid: false,
            tile_lengthIsTouched: false,
            tile_width: '',
            tile_widthIsValid: false,
            tile_widthIsTouched: false,
            cutting_style_id: '1',
            cutting_style_idIsValid: true,
            cutting_style_idIsTouched: false,
            cycalo_height: '',
            cycalo_heightIsValid: false,
            cycalo_heightIsTouched: false,
            quantity: '',
            quantityIsValid: false,
            quantityIsTouched: false
        }
    )

    const [doors, setDoors] = useState([
        {
            id: doorUniqueId(),
            width: '',
            count: 1,
            widthValidation: true,
            countValidation: true,
        }
    ]);

    const [cutTypes, setCutTypes] = useState([]);


    const addDoor = () => {
        let doorsCopy = JSON.parse(JSON.stringify(doors))
        doorsCopy.push({
            id: doorUniqueId(), width: '', count: '', widthValidation: true, countValidation: true,
        })
        setDoors(doorsCopy)
    }

    const changeDoorValue = (target) => {
        let doorsCopy = JSON.parse(JSON.stringify(doors))
        doorsCopy[doorsCopy.findIndex(door => door.id === parseInt(target.name))].widthValidation = validate('doorsWidth', target.value);
        doorsCopy[doorsCopy.findIndex(door => door.id === parseInt(target.name))].width = parseFloat(target.value);
        setDoors(doorsCopy)
    }

    const changeDoorCount = (target) => {
        let doorsCopy = JSON.parse(JSON.stringify(doors))
        doorsCopy[doorsCopy.findIndex(door => door.id === parseInt(target.name))].countValidation = validate('doorsCount', target.value);
        doorsCopy[doorsCopy.findIndex(door => door.id === parseInt(target.name))].count = parseFloat(target.value);
        setDoors(doorsCopy)
    }

    const getCyclo = () => {
        let cycloToBeFound = props.data.find(singleCyclo => singleCyclo.id === props.id)
        cycloToBeFound.doors.map(door => {
            door.widthValidation = true
            door.countValidation = true
        })
        setDoors(cycloToBeFound.doors)
        setInputsValues({
            tile_length: cycloToBeFound.tile_length,
            tile_lengthIsValid: true,
            tile_lengthIsTouched: true,
            tile_width: cycloToBeFound.tile_width,
            tile_widthIsValid: true,
            tile_widthIsTouched: true,
            cutting_style_id: cycloToBeFound.cutting_style_id,
            cutting_style_idIsValid: true,
            cutting_style_idIsTouched: true,
            cycalo_height: cycloToBeFound.cycalo_height,
            cycalo_heightIsValid: true,
            cycalo_heightIsTouched: true,
            quantity: cycloToBeFound.quantity,
            quantityIsValid: true,
            quantityIsTouched: true
        })
    }

    const saveHandler = (() => {
        axios.put(BASE_URL + `/cycalo/${props.id}`,
            {
                quantity: inputs.quantity,
                tile_length: inputs.tile_length,
                tile_width: inputs.tile_width,
                cycalo_height: inputs.cycalo_height,
                cutting_style_id: inputs.cutting_style_id,
                buyer_id: localStorage.getItem('id'),
                doors: doors.map(({ id, ...item }) => item),
            },
            {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    "Accept-Language": 'ar'

                }
            })
            .then(res => {
                toast.success('تم الحفظ', {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }).catch(error => {
                const errorResult = handleError(error)
                const errMsg = errorResult.message;
                toast.error(errMsg, {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                if (errorResult.type == errorTypes.unauthorized) {
                    setTimeout(() => {

                        localStorage.clear();
                        history.push("/home");
                    }, 1000);
                }

            })
    })

    const checkFormValidation = () => {
        return inputs.tile_lengthIsValid &&
            inputs.tile_widthIsValid &&
            inputs.cutting_style_idIsValid &&
            inputs.cycalo_heightIsValid &&
            inputs.quantityIsValid &&
            doors.map(door => door.widthValidation && door.countValidation)[0];
    }

    useEffect(() => {
        const cuttingStyle = basicFn.getCutting_style();
        if (!cuttingStyle) {

            axios.get(`${BASE_URL}/cutting_style`, {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    "Accept-Language": 'ar'

                }
            }).then(res => {
                basicFn.setCutting_style(res.data)
                setCutTypes(res.data)
            })
                .catch(error => {
                    const errorResult = handleError(error)
                    const errMsg = errorResult.message;
                    toast.error(errMsg, {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (errorResult.type == errorTypes.unauthorized) {
                        setTimeout(() => {

                            localStorage.clear();
                            history.push("/home");
                        }, 1000);
                    }

                })
        } else {
            setCutTypes(basicFn.getCutting_style())
        }
    }, []);

    return (
        <Modal show={props.show} onShow={() => getCyclo(props.data.id)} dialogClassName="edit-modal modal-lg">
            <ToastContainer />
            <Modal.Header>
                <Modal.Title>
                    <Close className="close-icon" onClick={() => handleModalClose()} />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="cyclo-edit">
                    <div className="cyclo-labels">
                        <label>حساب كمية السيكلو</label>
                    </div>
                    <div className="quantity-doors-container" style={{ backgroundColor: "white" }}>
                        <div className="quantity">
                            <span className="def">كمية السيكلو</span>
                            <input
                                name="quantity"
                                value={inputs.quantity}
                                onChange={(e) => handleInputChange(e)} />
                            <span style={{ marginInlineEnd: "1rem" }} className="unit">متر طولي</span>
                            {!inputs.quantityIsValid && inputs.quantityIsTouched && <span className="error-msg">طول البلاطة خطأ</span>}

                        </div>
                        <Doors
                            doors={doors}
                            addDoor={addDoor}
                            changeDoorValue={changeDoorValue}
                            changeDoorCount={changeDoorCount}
                            parentComponent={'cyclo'}
                        />
                    </div>
                    <div className="slab-measure">
                        <label>مقاس البلاطة</label>
                        <div className="slab-measure-length">
                            <span className="def">طول الوحدة</span>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <input
                                    value={inputs.tile_length}
                                    name="tile_length"
                                    onChange={(e) => handleInputChange(e)} />
                                {!inputs.tile_lengthIsValid && inputs.tile_lengthIsTouched && <span className="error-msg">طول البلاطة خطأ</span>}
                            </div>
                            <span className="unit">متر</span>
                        </div>
                        <div className="slab-measure-width">
                            <span className="def">عرض الوحدة</span>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <input
                                    value={inputs.tile_width}
                                    name="tile_width"
                                    onChange={(e) => handleInputChange(e)} />
                                {!inputs.tile_widthIsValid && inputs.tile_widthIsTouched && <span className="error-msg">عرض البلاطة خطأ</span>}
                            </div>
                            <span className="unit">متر</span>
                        </div>
                        <div className="slab-measure-cut-type">
                            <span className="def">طريقة القص</span>
                            <select
                                className="select"
                                name="cutting_style_id"
                                value={inputs.cutting_style_id}
                                onChange={(e) => handleInputChange(e)} >
                                {
                                    cutTypes.length > 0 &&
                                    cutTypes.map(type => {
                                        return (
                                            <option value={type.id} key={type.id}>{type.name_ar}</option>
                                        )
                                    })
                                }
                            </select>
                            {
                                parseInt(inputs.cutting_style_id) === 1 ?
                                    <Width className="fig" /> :
                                    <Length className="fig" />
                            }
                            {/* <span className="unit">متر</span> */}
                        </div>
                        <div className="cyclo-measure">
                            <label>مقاس وحدة السيكلو</label>
                            <div className="cyclo-measure-height">
                                <span className="def">ارتفاع الوحدة</span>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <input
                                        value={inputs.cycalo_height}
                                        name="cycalo_height"
                                        onChange={(e) => handleInputChange(e)} />
                                    {!inputs.cycalo_heightIsValid && inputs.cycalo_heightIsTouched && <span className="error-msg">ارتفاع السيكلو خطأ</span>}
                                </div>
                                <span className="unit">متر</span>
                            </div>
                        </div>
                    </div>
                    <div className="cyclo-wanted-btns">
                        {/* <button
                            onClick={() => clearForm()}
                            className="cyclo-wanted-btns-clear">مسح البيانات</button> */}
                        <button onClick={() => saveHandler()}
                            disabled={!checkFormValidation()}
                            className={checkFormValidation() ? "cyclo-wanted-btns-calc" : "cyclo-wanted-btns-calc disabled"}>احسب</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default EditCyclo;
