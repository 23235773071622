import * as errorTypes from './errorTypes'
export const handleError = (error) => {
  const response = error.response
  if (response) {
    if (response.status == 401) {
      return {
        type: errorTypes.unauthorized,
        message: response.data.message
      }
    } else {
      return {
        type: errorTypes.responseError,
        message: response.data.message

      }
    }
  } else {
    return {
      type: errorTypes.unKnownError,
      message: error.message
    }
  }
}