import * as actionTypes from '../actions/actionTypes';

const initialState = {
    user: 'client'
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SETUSERTYPE:
            state.user = action.userType
            return state;
        default:
            return state;
    }
}
export default reducer;