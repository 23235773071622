import * as actionTypes from '../actions/actionTypes';
import * as actions from '../actions/actionCreators';

const initialState = {
  walls: []
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SETWALLS:
      return {
        walls: action.walls
      }

    case actionTypes.GETWALL:
      return [...state.walls].find(wall => wall.id === action.wallId);

    // case actionTypes.ADDWALL:
    //   let wallsCopy = [...state.walls];

    //   wallsCopy.push(action.wall)
    //   return {
    //     ...state,
    //     walls: wallsCopy
    //   }

    case actionTypes.EDITWALL:
      let wallsCopyEdit = [...state.walls];
      wallsCopyEdit.push(action.wall)
      

      return {
        ...state,
        walls: wallsCopyEdit
      }
   
   

    case actionTypes.DELETEWALL:
      let wallsCopyDelete = [...state.walls];
      let toBeDeletedWallIndex = wallsCopyDelete.findIndex(wall => wall.id === action.wallId)
      wallsCopyDelete.splice(toBeDeletedWallIndex, 1)
      if (actions.getWallsFromStorage()) {
        let wallsCopyEditFromStorage = [...actions.getWallsFromStorage()];
        let newWallsCopyEditFromStorage = wallsCopyEditFromStorage.filter(el => el.id != action.wall.id)
       
        localStorage.setItem("walls", JSON.stringify(newWallsCopyEditFromStorage))
      }
      return {
        ...state,
        walls: wallsCopyDelete
      }
    default:
      return state;
  }
}
export default reducer;