// import { ReactComponent as Search } from '../../../assets';
import { ReactComponent as Search } from '../../../assets/search-line.svg';
import React from 'react'
import './filterOrder.scss';

const FilterOrder = (props) => {

  return (<>
    <div className='search-container d-flex justify-content-end'>

      <input className="search-input" placeholder=" رقم النموذج او التليفون" value={props.searchVal} onChange={props.handleSearch} />
      {/* <Search className="search-icon" /> */}
    </div>

  </>)
}

export default FilterOrder;