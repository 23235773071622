import React from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import Floor from '../../pages/floor/floor';
import Wall from '../../pages/wall/wall';
import Cyclo from '../../pages/cyclo/cyclo';

const MaterialsCal = () => {
    let slug = useParams();
    let location = useLocation();

    return (
        <>
            {
                <div className="materails-cal-container">
                    <ul className="nav nav-tabs">
                        <li className={location.pathname.includes('cyclo') ? "nav-item active" : "nav-item"}>
                            <Link to="/data/materials-cal/cyclo">السيكلو</Link>
                        </li>
                        <li className={location.pathname.includes('floor') ? "nav-item active" : "nav-item"}>
                            <Link to="/data/materials-cal/floor">الأرضيات</Link>
                        </li>
                        <li className={location.pathname.includes('wall') ? "nav-item active" : "nav-item"}>
                            <Link to="/data/materials-cal/wall">الأرضيات و الجدران</Link>
                        </li>
                    </ul>
                    {
                        slug.id === 'cyclo' ?
                            <Cyclo /> : slug.id === 'floor' ? <Floor /> : <Wall />
                    }
                </div>
            }
        </>
    )
}
export default MaterialsCal;