import React, { } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './profile.scss'

import axios from 'axios';
import { BASE_URL } from '../../constants/config';
import { ToastContainer, toast } from 'react-toastify';
import useSignUpForm from '../../hooks/useSignUpForm'
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { useState } from "react";
import { useEffect } from "react";
import ResetPassword from "../../components/resetPassword/resetPassword";
import { useHistory } from "react-router-dom"
import { handleError } from "../../utilities/handleError";
import * as errorTypes from "../../utilities/errorTypes"
const Profile = () => {
  let history = useHistory();
  const [packages, setPackages] = useState([])
  const [role, setRole] = useState("");
  const [company_users, setCompany_users] = useState([]);
  const [company_id, setCompany_id] = useState("");
  const [company_data, setCompany_data] = useState({});
  const [loading, setLoading] = useState(true)
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState({ id: null, plan: {} })
  const { handleInputChange, inputs, clearInputs } = useSignUpForm(
    {
      password: '',
      passwordIsValid: false,
      passwordIsTouched: false,
      current_password: '',
      current_passwordIsValid: false,
      current_passwordIsTouched: false,
      // newPassword: '',
      // newPasswordIsValid: false,
      // newPasswordIsTouched: false
    }
  )
  const handleResetPasswordModalClose = () => {
    setShowResetPasswordModal(false);
  }
  const handleResetPasswordModalShow = () => setShowResetPasswordModal(true);
  const [newPassword, setNewPAssword] = useState("")

  // const {
  //   handleInputChange: handleChangePasswordInputChange,
  //   inputs: ChangePasswordInputs,
  //   clearInputs: clearChangePasswordInputs,
  //   setInputsValues: setChangePasswordInputs
  // } = useSignUpForm({
  //   password: '',
  //   passwordIsValid: false,
  //   passwordIsTouched: false,
  //   current_password: '',
  //   current_passwordIsValid: false,
  //   current_passwordIsTouched: false,

  // })
  const checkChangePasswordFormValidation = () => {
    return inputs.passwordIsValid && inputs.current_passwordIsValid;
  }
  const resetPassword = (id) => {
    if (window.confirm(" هل تريد اعاده تعيين كلمه المرور ؟")) {
      axios.put(`${BASE_URL}/users/${id}/reset_password`, {

      }, {
        headers: {
          "Accept": "application/json",
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          "Accept-Language": 'ar'

        }
      }).then(res => {

        setNewPAssword(res.data.new_password)
        handleResetPasswordModalShow()

      }).catch(error => {
        const errorResult = handleError(error)
        const errMsg = errorResult.message;
        toast.error(errMsg, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (errorResult.type == errorTypes.unauthorized) {
          setTimeout(() => {

            localStorage.clear();
            history.push("/home");
          }, 1000);
        }
      })
    }
  }
  useEffect(() => {

    const packs = [
      { id: 1, name: "Package 1", peroid: '6', mainPrices: { 4: 600, 6: 810, 8: 1020 }, extra: { 1: 150, 3: 250, 5: 450 } },
      { id: 2, name: "Package 2", peroid: '12', mainPrices: { 4: 1000, 6: 1350, 8: 1700 }, extra: { 1: 100, 3: 300, 5: 500 } },
    ]


    setPackages(packs)
    let key = Object.entries(packs[0].mainPrices)[0][0]
    let val = Object.entries(packs[0].mainPrices)[0][1]
    const sellected = { id: packs[0].id, plan: { [key]: val } };

    setSelectedPackage(sellected)
    setRole(localStorage.getItem("role"))
    setCompany_id(localStorage.getItem("company_id"))
    if (localStorage.getItem("role") == 'company_owner') {
      axios.get(`${BASE_URL}/company/${localStorage.getItem("company_id")}`, {
        headers: {
          "Accept": "application/json",
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          "Accept-Language": 'ar'

        }
      }).then(res => {

        setCompany_data(res.data);
        setLoading(false)
      }).catch(error => {
        const errorResult = handleError(error)
        const errMsg = errorResult.message;
        toast.error(errMsg, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        if (errorResult.type == errorTypes.unauthorized) {
            setTimeout(() => {

                localStorage.clear();
                history.push("/home");
            }, 1000);
        }

      })


    } else {
      setLoading(false)
    }
  }, [])

  const changePassword = () => {


    axios.post(`${BASE_URL}/users/profile/edit`, {
      password: inputs.password,
      current_password: inputs.current_password,
    }, {
      headers: {
        "Accept": "application/json",
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        "Accept-Language": 'ar'

      }
    }).then(res => {
      clearInputs();

      let msg = "تم تعديل كلمه المرور بنجاح"

      toast.success(msg, {
        position: "top-right",
        autoClose: 0,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
      .catch(error => {
        const errorResult = handleError(error)
        const errMsg = errorResult.message;
        toast.error(errMsg, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        if (errorResult.type == errorTypes.unauthorized) {
            setTimeout(() => {

                localStorage.clear();
                history.push("/home");
            }, 1000);
        }

      })

  }
  const onPlanChange = (e, el, p) => {

    e.stopPropagation()

    // let plan={p[0]:p[1]}
    let key = p[0]
    let val = p[1]
    setSelectedPackage({
      id: el.id,
      plan: { [key]: val }
    })


  }
  const onPackageChange = (pack) => {

    let key = Object.entries(pack.mainPrices)[0][0]
    let val = Object.entries(pack.mainPrices)[0][1]
    setSelectedPackage({ id: pack.id, plan: { [key]: val } })
  }
  const selectPlan = (e) => {

  }
  return (<>
    <ResetPassword
      show={showResetPasswordModal}
      handleClose={handleResetPasswordModalClose}
      password={newPassword}
    />
    <div className="container profile-container" dir="rtl">

      {!loading && <Tabs
        defaultActiveKey={role == 'company_owner' ? 'profile' : "change-pass"}
        id="uncontrolled-tab-example"
        className="mb-3"
      >

        {role == 'company_owner' && <Tab eventKey="profile" title="البيانات الرئيسيه">
          <div className="container">
            <Form className="change-password-form">
              <Form.Group className="mb-3" controlId="name">
                <Form.Label> اسم الشركه</Form.Label>
                <Form.Control type="text" disabled name="name" value={company_data?.name} />
                {/* <Form.Error className="Error-muted">
                  We'll never share your email with anyone else.
                </Form.Error> */}

              </Form.Group>

              <Form.Group className="mb-3" controlId="alias">
                <Form.Label>  الاسم المستعار</Form.Label>
                <Form.Control type="text" name="alais" disabled value={company_data?.alias} />


              </Form.Group>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>  البريد الالكتروني </Form.Label>
                <Form.Control type="text" name="email" disabled value={company_data?.email} />


              </Form.Group>

              <Form.Group className="mb-3" controlId="phone_number">
                <Form.Label>  تليفون ثابت  </Form.Label>
                <Form.Control type="text" name="phone_number" disabled value={company_data?.phone_number} />


              </Form.Group>
              <Form.Group className="mb-3" controlId="mobile">
                <Form.Label>  رقم الجوال   </Form.Label>
                <Form.Control type="text" name="mobile" disabled value={company_data?.mobile} />


              </Form.Group>
              {/* <Form.Group className="mb-3" controlId="">
                <Form.Label>  اسم المفوض    </Form.Label>
                <Form.Control type="text" name="" disabled />



              </Form.Group> */}
              <Form.Group className="mb-3" controlId="num_of_branches">
                <Form.Label>   عدد الفروع    </Form.Label>
                <Form.Control type="text" name="num_of_branches" disabled value={company_data?.num_of_branches} />


              </Form.Group>
              <Form.Group className="mb-3" controlId="country">
                <Form.Label>   عدد الفروع    </Form.Label>
                <Form.Control type="text" name="country" disabled value={company_data?.country} />


              </Form.Group>
            </Form>
          </div>
        </Tab>}
        <Tab eventKey="change-pass" title="تغيير كلمه المرور">
          <div className="container">
            <Form className="change-password-form">
              <Form.Group className="mb-3" controlId="current_password">
                <Form.Label>كلمة المرور</Form.Label>
                <Form.Control type="password" placeholder=" ادخل كلمه المرور  الحاليه" name="current_password" value={inputs.current_password} onChange={(e) => handleInputChange(e)} />
                {/* <Form.Error className="Error-muted">
                  We'll never share your email with anyone else.
                </Form.Error> */}
                {!inputs.current_passwordIsValid && inputs.current_passwordIsTouched && <span className="error-msg">  مطلوب</span>}

              </Form.Group>
              <Form.Group className="mb-3" controlId="password">
                <Form.Label>كلمه المرور الجديده</Form.Label>
                <Form.Control type="password" placeholder="ادخل كلمه المرور الجديده" value={inputs.password} name="password" onChange={(e) => handleInputChange(e)} />
                {!inputs.passwordIsValid && inputs.passwordIsTouched && <span className="error-msg">  مطلوب</span>}

              </Form.Group>

              <Button variant="primary" type="button" onClick={() => changePassword()} disabled={!checkChangePasswordFormValidation()}>
                حفظ
              </Button>
            </Form>
          </div>
        </Tab>
        {role == 'company_owner' && <Tab eventKey="emps" title=" الموظفين" >
          {/* <Sonnet /> */}
          <div className="container">

            <Table striped>
              <thead>
                <tr>
                  <th>#</th>
                  <th>الاسم </th>
                  <th>اسم المستخدم </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {company_data && company_data.company_users && company_data.company_users.map(el =>
                  <tr>
                    <td>{el.id}</td>
                    <td>{el.name}</td>
                    <td>{el.username}</td>
                    <td> <button className="btn btn-transparent btn-change" onClick={() => resetPassword(el.id)}>اعاده تعيين كلمة السر</button></td>
                  </tr>
                )}


              </tbody>
            </Table>
          </div>

        </Tab>}
        {role == 'company_owner' && <Tab eventKey="subscribtion-plan" title="تغيير خطه الاشتراك" >
          {/* <Tab eventKey="subscribtion-plan" title="تغيير خطه الاشتراك" disabled> */}
          <div className="container" dir="rtl">

            <div className="snip1214">
              {packages && packages?.map(el => (
                <div className={selectedPackage.id == el.id ? 'plan featured' : 'plan'} key={el.id} onClick={() => onPackageChange(el)} >
                  <h3 className="plan-title">
                    {el.name}
                  </h3>
                  <div className="plan-cost">

                    <span className="plan-price">
                      {el.peroid + "  "}
                      {/* $19 */}
                    </span>
                    <span className="plan-type">


                      شهر
                    </span>

                  </div>
                  <ul className="plan-features">
                    {Object.entries(el.mainPrices).map(price =>
                      <li key={price[0]}>
                        {/* <i className="ion-checkmark">
                        </i> */}
                        <input type="radio" name={"plan" + el.id} id={el.id + price[0]} value={price[1]} onChange={(e) => onPlanChange(e, el, price)} checked={selectedPackage.id == el.id && price[0] == Object.keys(selectedPackage.plan)[0]} />
                        <label htmlFor={el.id + price[0]}>

                          {` $ ${price[1]}   لكل  ${price[0]} مستخدم  `}
                        </label>
                      </li>
                    )}
                    {/* <li><i className="ion-checkmark"> </i>5 MySQL Databases</li>
                    <li><i className="ion-checkmark"> </i>Unlimited Email</li>
                    <li><i className="ion-checkmark"> </i>250Gb Monthly Transfer</li>
                    <li><i className="ion-checkmark"> </i>24/7 Tech Support</li>
                    <li><i className="ion-checkmark"> </i>Daily Backups</li> */}
                  </ul>
                  <div className="plan-select"><button onClick={(e) => selectPlan(e)} disabled={el.id != selectedPackage.id}>ادفع </button></div>
                </div>
              )

              )}
              {/* <div className="plan">
                <h3 className="plan-title">
                  Basic
                </h3>
                <div className="plan-cost"><span className="plan-price">$29</span><span className="plan-type">/ Monthly</span></div>
                <ul className="plan-features">
                  <li><i className="ion-checkmark"> </i>10GB Linux Web Space</li>
                  <li><i className="ion-checkmark"> </i>10 MySQL Databases</li>
                  <li><i className="ion-checkmark"> </i>Unlimited Email</li>
                  <li><i className="ion-checkmark"> </i>500Gb Monthly Transfer</li>
                  <li><i className="ion-checkmark"> </i>24/7 Tech Support</li>
                  <li><i className="ion-checkmark"> </i>Daily Backups</li>
                </ul>
                <div className="plan-select"><a href="#">Select Plan</a></div>
              </div> */}
              {/* <div className="plan featured">
                <h3 className="plan-title">
                  Professional
                </h3>
                <div className="plan-cost"><span className="plan-price">$49</span><span className="plan-type">/ Monthly</span></div>
                <ul className="plan-features">
                  <li><i className="ion-checkmark"> </i>25GB Linux Web Space</li>
                  <li><i className="ion-checkmark"> </i>25 MySQL Databases</li>
                  <li><i className="ion-checkmark"> </i>Unlimited Email</li>
                  <li><i className="ion-checkmark"> </i>2000Gb Monthly Transfer</li>
                  <li><i className="ion-checkmark"> </i>24/7 Tech Support</li>
                  <li><i className="ion-checkmark"> </i>Daily Backups</li>
                </ul>
                <div className="plan-select"><a href="#">Select Plan</a></div>
              </div> */}
              {/* <div className="plan">
                <h3 className="plan-title">
                  Ultra
                </h3>
                <div className="plan-cost"><span className="plan-price">$99</span><span className="plan-type">/ Monthly</span></div>
                <ul className="plan-features">
                  <li><i className="ion-checkmark"> </i>100GB Linux Web Space</li>
                  <li><i className="ion-checkmark"> </i>Unlimited MySQL Databases</li>
                  <li><i className="ion-checkmark"> </i>Unlimited Email</li>
                  <li><i className="ion-checkmark"> </i>10000Gb Monthly Transfer</li>
                  <li><i className="ion-checkmark"> </i>24/7 Tech Support</li>
                  <li><i className="ion-checkmark"> </i>Daily Backups</li>
                </ul>
                <div className="plan-select"><a href="#">Select Plan</a></div>
              </div> */}
            </div>
          </div>

        </Tab>}
      </Tabs>}
    </div>
  </>)
}
export default Profile;