import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import cycloReducer from './store/reducers/cycloReducer';
import floorReducer from './store/reducers/floorReducer';
import userReducer from './store/reducers/userReducer';
import roomReducer from './store/reducers/roomReducer';
import wallReducer from './store/reducers/wallReducer';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { BrowserRouter } from 'react-router-dom';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  cycloReducer: cycloReducer,
  floorReducer: floorReducer,
  userReducer: userReducer,
  roomReducer: roomReducer,
  wallReducer: wallReducer
});
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
