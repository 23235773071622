import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import './floor.scss';
import { ReactComponent as Shape5 } from '../../assets/Path 4717.svg';
import { ReactComponent as Shape4 } from '../../assets/Path 4715.svg';
import { ReactComponent as Shape3 } from '../../assets/Path 4716.svg';
import { ReactComponent as Shape2 } from '../../assets/Rectangle 1056.svg';
import { ReactComponent as Shape1 } from '../../assets/Rectangle 1048.svg';
import { ReactComponent as Shape55 } from '../../assets/Group 3634.svg';

import { ReactComponent as Shape44 } from '../../assets/Group 3635.svg';
import { ReactComponent as Shape33 } from '../../assets/8l.svg';

import { ReactComponent as Shape22 } from '../../assets/Group 3636.svg';
import { ReactComponent as Close } from '../../assets/close.svg';
import { ReactComponent as Plus } from '../../assets/Icon feather-plus-circle.svg';
import InstallChoices from '../../components/installChoices/installChoices';
import SlabMeasure from '../../components/slabMeasure/slabMeasure';

import * as actionCreators from '../../store/actions/actionCreators';
import * as basicFn from '../../store/actions/basicFn';

import { useSelector, useDispatch } from 'react-redux';
import useSignUpForm from '../../hooks/useSignUpForm'
import axios from 'axios';
import { BASE_URL } from '../../constants/config'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
import MainSlab from './../../components/main-slab/mainSlab';
import { handleError } from '../../utilities/handleError';
import * as errorTypes from "../../utilities/errorTypes"

var doorId = 0;
var floorId = 0;

const Floor = () => {

    let params = useParams();
    let [userId, setUserId] = useState("")
    let history = useHistory()
    let currentFloors = useSelector(state => state.floorReducer.floors);
    const [localFloors, setLocalFloors] = useState([])

    const dispatch = useDispatch();
    const [tiles, setTiles] = useState([]);
    const [savedBtnStatus, setSavedBtnStatus] = useState({ id: null, saved: false, calculated: false });
    // walls section
    const [walls, setWalls] = useState([]);
    const [selectedShapeComponent, setSelectedShapeComponent] = useState(<Shape1 />);
    const [selectedShapeName, setSelectedShapeName] = useState('Shape1');

    const { handleInputChange, inputs, clearInputs, setInputsValues } = useSignUpForm(
        {
            name: '',
            nameIsValid: false,
            nameIsTouched: false,
            height: '',
            heightIsValid: false,
            heightIsTouched: false,
            // tile_name: "",
            // tile_nameIsValid: false,
            // tile_nameIsTouched: false,
            // tile_name_text: "",
            tile_length: '',
            tile_lengthIsValid: false,
            tile_lengthIsTouched: false,
            tile_width: '',
            tile_widthIsValid: false,
            tile_widthIsTouched: false,
            tile_spacing: '',
            tile_spacingIsValid: false,
            tile_spacingIsTouched: false,
            cycalo_height: '',
            cycalo_heightIsValid: false,
            cycalo_heightIsTouched: false,
            cutting_style_id: 1,
            cutting_style_idIsValid: false,
            cutting_style_idIsTouched: false,
            installation_angle_id: 1,
            installation_angle_idIsValid: false,
            installation_angle_idIsTouched: false,
            installation_start_id: 1,
            installation_start_idIsValid: false,
            installation_start_idIsTouched: false,
        }
    )

    const clearForm = () => {
        setWalls([])
        // setSelectedShapeComponent(<Shape1 />)
        setSelectedShape('Shape1', <Shape1 />, 4)
        setFloorResult({
            quantity: "",
            cycalo_mt: "",
            waste: "",
            before_waste: ""
        })
        setSelectedShapeName('Shape1')
        clearInputs()
    }

    const clearResults = () => {
        setFloorResult({
            quantity: "",
            cycalo_mt: "",
            waste: "",
            before_waste: ""
        })
    }

    const deleteFloor = (e, id) => {
        e.stopPropagation();
        dispatch(actionCreators.deleteFloor(id))
        clearForm();
        clearResults();
    }

    const getShape = (shapeName) => {
        switch (shapeName) {
            case 'Shape1':
                return <Shape1 />

            case 'Shape2':
                return <Shape22 />

            case 'Shape3':
                return <Shape33 />

            case 'Shape4':
                return <Shape44 />

            case 'Shape5':
                return <Shape55 />
        }
    }

    const resetResults = () => {
        setFloorResult({
            quantity: "",
            cycalo_mt: "",
            waste: "",
            before_waste: ""
        })

    }
    const resetMainInputs = (_name, _height) => {
        setInputsValues({
            name: _name,
            nameIsValid: _name ? true : false,
            nameIsTouched: _name ? true : false,
            height: _height,
            heightIsValid: _height ? true : false,
            heightIsTouched: _height ? true : false,
            tile_length: '',
            tile_lengthIsValid: false,
            tile_lengthIsTouched: false,
            tile_width: '',
            tile_widthIsValid: false,
            tile_widthIsTouched: false,
            tile_spacing: '',
            tile_spacingIsValid: false,
            tile_spacingIsTouched: false,

            installation_angle_id: 1,
            installation_angle_idIsValid: true,
            installation_angle_idIsTouched: false,
            installation_start_id: 1,
            installation_start_idIsValid: true,
            installation_start_idIsTouched: false
        })
    }
    const setMainInputs = (_name, _height, length, width, spacing, angle_id, start_id) => {
        setInputsValues({
            name: _name,
            nameIsValid: true,
            nameIsTouched: true,
            height: _height,
            heightIsValid: true,
            heightIsTouched: true,
            tile_length: length,
            tile_lengthIsValid: true,
            tile_lengthIsTouched: true,
            tile_width: width,
            tile_widthIsValid: true,
            tile_widthIsTouched: true,
            tile_spacing: spacing,
            tile_spacingIsValid: true,
            tile_spacingIsTouched: true,
            installation_angle_id: angle_id,
            installation_angle_idIsValid: true,
            installation_angle_idIsTouched: true,
            installation_start_id: start_id,
            installation_start_idIsValid: true,
            installation_start_idIsTouched: true
        })

    }
    const getFloor = (id) => {

        let floorToBeFound = currentFloors.find(singleFloor => singleFloor.id === id)

        floorToBeFound.output ?
            setSavedBtnStatus({ id: floorToBeFound.id, saved: floorToBeFound.output.saved, calculated: floorToBeFound.output.calculated })
            :
            setSavedBtnStatus({ id: floorToBeFound.id, saved: false, calculated: false })
        let wallsResult = [];

        if (floorToBeFound.floor === null) {



            Object.keys(floorToBeFound.walls).map(key => {
                if (key.includes('wall_') && floorToBeFound.walls[key]) {
                    wallsResult.push({ [`number`]: key.substr(5, 1), ['length']: floorToBeFound.walls[key] })
                }
            })
            wallsResult = wallsResult.slice(0, floorToBeFound.walls.length);



            //results
            resetResults()

            setSavedBtnStatus({ id: floorToBeFound.id, saved: false, calculated: false })

            setWalls(wallsResult)
            setSelectedShapeName(floorToBeFound.shape.name)
            setSelectedShapeComponent(getShape(floorToBeFound.shape.name))
            resetMainInputs(floorToBeFound.name, floorToBeFound.height)


        }
        //2-claculated saved

        else if (floorToBeFound.floor) {



            Object.keys(floorToBeFound.walls).map(key => {
                if (key.includes('wall_') && floorToBeFound.walls[key]) {
                    wallsResult.push({ [`number`]: key.substr(5, 1), ['length']: floorToBeFound.walls[key] })
                }
            })
            wallsResult = wallsResult.slice(0, floorToBeFound.walls.length);

            let floorFound = floorToBeFound.floor;

            //check ittttttttttttttttttt

            setFloorResult(floorFound?.output)

            setSavedBtnStatus({ id: floorToBeFound.id, saved: true, calculated: true })

            setWalls(wallsResult)
            setSelectedShapeName(floorToBeFound.shape.name)
            setSelectedShapeComponent(getShape(floorToBeFound.shape.name))
            setMainInputs(floorToBeFound.name, floorToBeFound.height, floorFound.tile_length, floorFound.tile_width, floorFound.tile_spacing, floorFound.installation_angle_id, floorFound.installation_start_id)
            // resetMainInputs(wallToBeFound.name, wallToBeFound.height)


        }
        else {

            // //results

            setFloorResult(floorToBeFound.output)

            setSavedBtnStatus({ id: floorToBeFound.id, saved: false, calculated: true })

            setWalls(floorToBeFound.walls)
            setSelectedShapeName(floorToBeFound.selectedShapeName)
            setSelectedShapeComponent(getShape(floorToBeFound.selectedShapeName))
            setMainInputs(floorToBeFound.name, floorToBeFound.height, floorToBeFound.tile_length,
                floorToBeFound.tile_width, floorToBeFound.tile_spacing, floorToBeFound.installation_angle_id,
                floorToBeFound.installation_start_id)
        }
    }

    useEffect(() => {

        if (params.clientId || localStorage.getItem("identifier")) {

            history.push(`/data/materials-cal/floor/${params.clientId || localStorage.getItem("identifier")}`);


            axios.get(`${BASE_URL}/buyer/${params.clientId ? params.clientId : localStorage.getItem("identifier")}/rooms`,
                {
                    headers: {
                        "Accept": "application/json",
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        "Accept-Language": 'ar'

                    },
                    params: {

                        withHeight: false
                    }
                }).then(res => {
                    setLocalFloors(JSON.parse(localStorage.getItem("floors")))


                    localStorage.setItem('id', res.data.id)
                    localStorage.setItem('identifier', res.data.identifier);
                    setUserId(res.data.identifier);
                    let allFloors = res.data.rooms.map(el => {
                        return { ...el, save_inputs: true }
                    })

                    if (actionCreators.getFloorsFromStorage()) {


                        let firstFloor = localFloors && localFloors.length > 0 && localFloors[0]
                        if (firstFloor) {


                            if (firstFloor.buyer_id == localStorage.getItem("id")) {

                                setLocalFloors(JSON.parse(localStorage.getItem("floors")))

                            } else {
                                localStorage.setItem("floors", [])
                            }
                        }
                    }

                    dispatch(actionCreators.setFloors(allFloors))

                })
                .catch(error => {
                    const errorResult = handleError(error)
                    const errMsg = errorResult.message;
                    toast.error(errMsg, {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (errorResult.type == errorTypes.unauthorized) {
                        setTimeout(() => {

                            localStorage.clear();
                            history.push("/home");
                        }, 1000);
                    }

                })
        }

        const cuttingStyle = basicFn.getCutting_style();
        if (!cuttingStyle) {

            axios.get(`${BASE_URL}/cutting_style`, {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    "Accept-Language": 'ar'

                }
            }).then(res => {
                basicFn.setCutting_style(res.data)
                setCutTypes(res.data)
            }).catch(error => {
                const errorResult = handleError(error)
                const errMsg = errorResult.message;
                toast.error(errMsg, {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                if (errorResult.type == errorTypes.unauthorized) {
                    setTimeout(() => {

                        localStorage.clear();
                        history.push("/home");
                    }, 1000);
                }

            })
        } else {
            setCutTypes(basicFn.getCutting_style())
        }
        const tilesArr = basicFn.getTiles();
        if (!tilesArr) {

            axios.get(`${BASE_URL}/tile?company_id=${localStorage.getItem('company_id')}`, {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    "Accept-Language": 'ar'

                }
            }).then(res => {
                setTiles(res.data)
                basicFn.setTiles(res.data)
            }).catch(error => {
                const errorResult = handleError(error)
                const errMsg = errorResult.message;
                toast.error(errMsg, {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                if (errorResult.type == errorTypes.unauthorized) {
                    setTimeout(() => {

                        localStorage.clear();
                        history.push("/home");
                    }, 1000);
                }

            })
        } else {
            setTiles(tilesArr)
        }

        const angles = basicFn.getInstlationAngle()
        if (!angles) {

            axios.get(`${BASE_URL}/installation_angle`, {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    "Accept-Language": 'ar'

                }
            }).then(res => {
                basicFn.setInstlationAngle(res.data)
                setInstallationAngles(res.data)
            })
        } else {
            setInstallationAngles(angles)
        }

        const starts = basicFn.getInstlationStart()
        if (!starts) {

            axios.get(`${BASE_URL}/installation_start`, {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    "Accept-Language": 'ar'

                }
            }).then(res => {
                basicFn.setInstlationStart(res.data)
                setInstallationStarts(res.data)
            }).catch(error => {
                const errorResult = handleError(error)
                const errMsg = errorResult.message;
                toast.error(errMsg, {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                if (errorResult.type == errorTypes.unauthorized) {
                    setTimeout(() => {

                        localStorage.clear();
                        history.push("/home");
                    }, 1000);
                }

            })
        } else {
            setInstallationStarts(starts)

        }

    }, []);

    const doorUniqueId = () => {
        doorId += 1
        return doorId;
    }

    const floorUniqueId = () => {
        floorId += 1
        return floorId;
    }

    const [cutTypes, setCutTypes] = useState([]);
    const [installationAngles, setInstallationAngles] = useState([]);
    const [installationStarts, setInstallationStarts] = useState([]);
    const [floorResult, setFloorResult] = useState({
        quantity: 0,
        cycalo_mt: 0,
        waste: 0,
        before_waste: 0
    });



    useEffect(() => {
        setSelectedShape('Shape1', <Shape1 />, 4)
    }, []);

    const checkWallLengthValidation = () => {
        let isValid = walls.every(el => el.length > 0)

        return isValid
    }
    const changeWallLength = (target) => {


        let wallsCopy = JSON.parse(JSON.stringify(walls))


        let index = wallsCopy.findIndex(wall => wall.number == parseInt(target.name))

        wallsCopy[index]['length'] = parseFloat(target.value);

        switch (selectedShapeName) {

            case 'Shape5':


                wallsCopy[0].length = Number.parseFloat(wallsCopy[2].length + wallsCopy[4].length + wallsCopy[6].length).toFixed(2)
                break;
            case 'Shape4':
                wallsCopy[0].length = Number.parseFloat(wallsCopy[2].length + wallsCopy[4].length).toFixed(2);
                wallsCopy[5].length = Number.parseFloat(wallsCopy[1].length + wallsCopy[3].length).toFixed(2);
                break;
            case 'Shape3':
                wallsCopy[0].length = Number.parseFloat(wallsCopy[2].length + wallsCopy[4].length + wallsCopy[6].length).toFixed(2);
                wallsCopy[5].length = wallsCopy[3].length
                wallsCopy[7].length = wallsCopy[1].length
                break;
            case 'Shape2':
                wallsCopy[2].length = wallsCopy[0].length;
                wallsCopy[3].length = wallsCopy[1].length
                break;
            case 'Shape1':
                wallsCopy[1].length = wallsCopy[0].length;
                wallsCopy[2].length = wallsCopy[0].length
                wallsCopy[3].length = wallsCopy[0].length
                break;
            default:
                break;
        }
        setWalls(wallsCopy)
    }

    const setDisabledEdges = (edgeNumber) => {
        let res = false
        if (selectedShapeName === 'Shape5' && edgeNumber === 1) {
            res = true;
        }
        else if (selectedShapeName === 'Shape4' && (edgeNumber === 1 || edgeNumber === 6)) {
            res = true
        }
        else if (selectedShapeName === 'Shape3' && (edgeNumber === 1 || edgeNumber === 6 || edgeNumber === 8)) {
            res = true
        }
        else if (selectedShapeName === 'Shape2' && (edgeNumber === 3 || edgeNumber === 4)) {
            res = true;
        }
        else if (selectedShapeName === 'Shape1' && (edgeNumber === 2 || edgeNumber === 3 || edgeNumber === 4)) {
            res = true;
        }
        else {
            res = false
        }
        return res;
    }

    const setSelectedShape = (name, component, values) => {
        let obj = [];
        for (let index = 0; index < values; index++) {
            const element = {
                number: index + 1,
                length: ""
            }
            obj.push(element)
        }
        setWalls(obj)
        setSelectedShapeComponent(component)
        setSelectedShapeName(name)
    }

    const saveHandler = ((saveInputs) => {
        let wallsObj = {};
        walls.map(wall => {
            wallsObj = Object.assign(wallsObj, { [`wall_${wall.number}`]: wall.length })
        })

        axios.post(BASE_URL + '/floor/calculate',
            {
                buyer_id: localStorage.getItem('id'),
                save_inputs: saveInputs,
                room: {
                    name: inputs.name,
                    height: '0',
                    shape: selectedShapeName,
                    walls: wallsObj,
                    windows: [
                        {
                            "width": 1.0,
                            "height": 1.0,
                            "count": 2
                        }
                    ]
                },
                // tile_name: inputs.tile_name_text !== '' ? inputs.tile_name_text : inputs.tile_name,
                tile_length: inputs.tile_length,
                tile_width: inputs.tile_width,
                tile_spacing: inputs.tile_spacing,
                cycalo_height: inputs.cycalo_height,
                cutting_style_id: inputs.cutting_style_id,
                installation_angle_id: inputs.installation_angle_id,
                installation_start_id: inputs.installation_start_id,
            },
            {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    "Accept-Language": 'ar'


                }
            }
        ).then(res => {
            if (!saveInputs) {
                // calculate only
                setFloorResult(res.data.output)
                setSavedBtnStatus({ id: res.data.id, saved: false, calculated: true })
                // dispatch(
                let floor = {
                    userId: userId,

                    buyer_id: localStorage.getItem('id'),
                    id: res.data.id,
                    name: inputs.name,
                    height: inputs.height,
                    // tile_name: inputs.tile_name,
                    tile_length: inputs.tile_length,
                    tile_width: inputs.tile_width,
                    tile_spacing: inputs.tile_spacing,
                    cycalo_height: inputs.cycalo_height,
                    cutting_style_id: inputs.cutting_style_id,
                    installation_angle_id: inputs.installation_angle_id,
                    installation_start_id: inputs.installation_start_id,
                    selectedShapeName: selectedShapeName,
                    selectedShapeComponent: selectedShapeComponent,
                    walls: walls,
                    output: {
                        ...res.data.output, saved: false, calculated: true
                    }
                    , save_inputs: false

                }
                actionCreators.addFloor(
                    floor
                );

                if (localFloors != null) {

                    setLocalFloors([...localFloors, floor])
                } else {
                    setLocalFloors([floor])

                }
                // localFloors.push(floor)
                // )
                toast.success('تمت عملية الحساب', {
                    position: "top-right",
                    // onClose: () => clearForm(),
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            else {
                let newFloor = {
                    id: res.data.id,
                    buyer_id: res.data.buyer_id, name: res.data.room.name,
                    floor: {
                        buyer_id: res.data.buyer_id,
                        installation_angle_id: res.data.installation_angle_id,
                        installation_start_id: res.data.installation_start_id,
                        output: {
                            ...res.data.output
                        },
                        tile_length: res.data.tile_length,
                        tile_width: res.data.tile_width,
                        tile_spacing: res.data.tile_spacing,

                    },
                    walls: wallsObj,
                    shape: selectedShapeName,
                    saved_inputs: true
                }

                // save


                if (localFloors != null) {

                    let copyLocal = [...localFloors];
                    let index = localFloors.findIndex(el => el.id == savedBtnStatus.id);
                    if (index !== -1) {

                        copyLocal.splice(index, 1)
                        setLocalFloors(copyLocal)

                    }
                }
                // currentFloors[index] = { ...newFloor, save_inputs: true };


                // currentFloors = [...copyCurr]
                // currentFloors=[...BASE_URL.]
                setSavedBtnStatus({ id: res.data.id, saved: true, calculated: true })
                setFloorResult(res.data.output)
                dispatch(actionCreators.editFloor({
                    buyer_id: localStorage.getItem('id'),
                    ...newFloor,
                    id: savedBtnStatus.id,
                    // name: inputs.name,
                    // height: inputs.height,
                    // // tile_name: inputs.tile_name,
                    // tile_length: inputs.tile_length,
                    // tile_width: inputs.tile_width,
                    // tile_spacing: inputs.tile_spacing,
                    // cycalo_height: inputs.cycalo_height,
                    // cutting_style_id: inputs.cutting_style_id,
                    // installation_angle_id: inputs.installation_angle_id,
                    // installation_start_id: inputs.installation_start_id,
                    // selectedShapeName: selectedShapeName,
                    // selectedShapeComponent: selectedShapeComponent,
                    // walls: walls,
                    // output: { ...res.data.output, saved: true },
                    // save_inputs: true

                }))
                clearForm()
                toast.success('تم الحفظ', {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }).catch(error => {
            const errorResult = handleError(error)
            const errMsg = errorResult.message;
            toast.error(errMsg, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            if (errorResult.type == errorTypes.unauthorized) {
                setTimeout(() => {

                    localStorage.clear();
                    history.push("/home");
                }, 1000);
            }

        })
    })

    const checkFormValidation = () => {

        return checkWallLengthValidation() &&

            inputs.nameIsValid &&
            // inputs.heightIsValid &&
            // (inputs.tile_nameIsValid || inputs.tile_name_text !== '') &&
            inputs.tile_lengthIsValid &&
            inputs.tile_widthIsValid &&
            inputs.tile_spacingIsValid
        // inputs.cycalo_heightIsValid &&
        // inputs.cutting_style_idIsValid &&
        // inputs.installation_angle_idIsValid &&
        // inputs.installation_start_idIsValid
    }

    const getLocalFloor = (id) => {
        let floorToBeFound = localFloors.find(singleFloor => singleFloor.id === id)

        setFloorResult(floorToBeFound.output)

        setSavedBtnStatus({ id: floorToBeFound.id, saved: false, calculated: true })

        setWalls(floorToBeFound.walls)
        setSelectedShapeName(floorToBeFound.selectedShapeName)
        setSelectedShapeComponent(getShape(floorToBeFound.selectedShapeName))
        setMainInputs(floorToBeFound.name, floorToBeFound.height, floorToBeFound.tile_length,
            floorToBeFound.tile_width, floorToBeFound.tile_spacing, floorToBeFound.installation_angle_id,
            floorToBeFound.installation_start_id)


    }
    const deleteFloorFromLocal = (e, id) => {

        e.stopPropagation()
        // let index = localFloors.findIndex(el => el.id == id);

        let filterd = localFloors.filter(el => el.id != id)
        setLocalFloors(filterd)
        actionCreators.deleteFloorFromLocal(id)
        if (id == savedBtnStatus.id) {
            clearForm()
        }
    }
    return (
        < div className="floor-container" >

            <div className="floor-section">
                <div className="floor-labels">
                    <div>
                        <span className="def">نتائج الحسابات للأرضيات</span>
                    </div>


                    {
                        currentFloors && currentFloors.length > 0 &&
                        <div className="floor-labels-btns">
                            {
                                currentFloors.map((singleFloor) => {

                                    return (
                                        <button onClick={() => getFloor(singleFloor.id)} key={singleFloor.id}
                                            className={`${singleFloor.id == savedBtnStatus.id ? "saved-btn active" : "saved-btn"}`}

                                        >
                                            {/* <Close onClick={(e) => deleteFloor(e, singleFloor.id)} className="close-btn" /> */}
                                            <span>{singleFloor.name}</span></button>


                                    )
                                })
                            }

                            {/* <span className="def">نتائج الحسابات للأرضيات</span> */}
                        </div>
                    }
                    {
                        localFloors && localFloors.length > 0 &&
                        <div className="floor-labels-btns">
                            {
                                localFloors.map(singleFloor => {

                                    return (
                                        <button
                                            className={`${singleFloor.id == savedBtnStatus.id ? "not-saved-btn active" : "not-saved-btn"}`}

                                            onClick={() => getLocalFloor(singleFloor.id)} key={singleFloor.id}
                                        // className={'not-saved-btn'}

                                        >
                                            <Close onClick={(e) => deleteFloorFromLocal(e, singleFloor.id)} className="close-btn" /><span>{singleFloor.name}</span></button>


                                    )
                                })
                            }

                            {/* <span className="def">نتائج الحسابات للأرضيات</span> */}
                        </div>
                    }
                </div>
                <div style={{ 'textAlign': 'right' }}>

                    <label >حساب كمية البلاط</label>
                </div>
                <div className="floor">
                    <div className="floor-name">
                        <div style={{ display: "flex", flexDirection: "column", width: "84%", position: "relative" }}>
                            <input
                                className="floor-name-input"
                                name="name"
                                value={inputs.name}
                                onChange={(e) => handleInputChange(e)} />
                            {!inputs.nameIsValid && inputs.nameIsTouched && <span className="error-msg">اسم الغرفة خطأ</span>}
                        </div>
                        <span className="def">اسم الغرفة</span>
                    </div>
                    <div className="floor-shape">
                        <Shape5 onClick={() => setSelectedShape('Shape5', <Shape55 />, 8)} className={selectedShapeName === 'Shape5' ? "shape selected" : "shape"} />
                        <Shape4 onClick={() => setSelectedShape('Shape4', <Shape44 />, 6)} className={selectedShapeName === 'Shape4' ? "shape selected" : "shape"} />
                        <Shape3 onClick={() => setSelectedShape('Shape3', <Shape33 />, 8)} className={selectedShapeName === 'Shape3' ? "shape selected" : "shape"} />
                        <Shape2 onClick={() => setSelectedShape('Shape2', <Shape22 />, 4)} className={selectedShapeName === 'Shape2' ? "shape selected" : "shape"} />
                        <Shape1 onClick={() => setSelectedShape('Shape1', <Shape1 />, 4)} className={selectedShapeName === 'Shape1' ? "shape selected" : "shape"} />
                        <span className="def"> اختيار شكل الغرفة  </span>
                    </div>
                    <div className="floor-details">
                        <div className="floor-details-selected-shape">
                            {selectedShapeComponent}
                        </div>
                        <div className="floor-details-selected-measure">
                            <label>قياسات الغرفة</label>
                            {/* {!checkWallLengthValidation() && <span className="error-msg">طول الضلع خطأ</span>
                            } */}
                            {
                                walls.map(wall => {
                                    return (
                                        <div key={wall.number} className="floor-side">
                                            <ToastContainer />
                                            <span className="def">ضلع {wall.number}</span>
                                            <input
                                                min={0}

                                                disabled={setDisabledEdges(wall.number)}
                                                type="number"
                                                onWheel={(e) => e.target.blur()}
                                                name={wall.number}
                                                value={wall.length}
                                                onChange={(e) => changeWallLength(e.target)} />
                                            <span className="unit">متر</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <SlabMeasure
                    parent='floor'
                    // tile_name={inputs.tile_name}
                    // tile_name_text={inputs.tile_name_text}
                    tile_length={inputs.tile_length}
                    tile_lengthIsValid={inputs.tile_lengthIsValid}
                    tile_lengthIsTouched={inputs.tile_lengthIsTouched}
                    tile_width={inputs.tile_width}
                    tile_widthIsValid={inputs.tile_widthIsValid}
                    tile_widthIsTouched={inputs.tile_widthIsTouched}
                    tile_spacing={inputs.tile_spacing}
                    tile_spacingIsValid={inputs.tile_spacingIsValid}
                    tile_spacingIsTouched={inputs.tile_spacingIsTouched}
                    cycalo_height={inputs.cycalo_height}
                    cutting_style_id={inputs.cutting_style_id}
                    cutTypes={cutTypes}
                    tiles={tiles}
                    handleInputChange={handleInputChange}
                />
                <InstallChoices
                    hideStackingMethod={true}
                    installationAngles={installationAngles}
                    installationStarts={installationStarts}
                    installation_start_id={inputs.installation_start_id}
                    installation_angle_id={inputs.installation_angle_id}
                    handleInputChange={handleInputChange}
                />
                <div className="floor-btns row">
                    {/* <div className="floor-btns-add-new col-5">
                        <span>اضافة غرفة جدبدة</span>
                        <Plus />
                    </div> */}
                    <div className="floor-btns-main col-7">
                        <button onClick={() => clearForm()} className="clear">مسح البيانات</button>
                        <button
                            disabled={!checkFormValidation()}
                            onClick={() => saveHandler(false)}
                            className={checkFormValidation() ? "calc" : "calc disabled"} >احسب</button>
                    </div>
                </div>
            </div>
            <div className="floor-wanted">
                <label>الكمية المطلوبة من البلاط</label>
                <div className="floor-wanted-section">
                    <div className="floor-wanted-section-labels">
                        <span className="def">الكمية قبل الهدر</span>
                        <span className="def">الكمية  بعد الهدر </span>
                        <span className="def">السيكلو</span>
                        <span className="def"> نسبة الهدر</span>
                    </div>
                    <div className="floor-wanted-section-inputs">
                        <div>
                            <span style={{ marginInlineEnd: "1rem" }} className="unit">(م2)</span>
                            <input disabled={true} value={floorResult.before_waste} />
                        </div>
                        <div>
                            <span style={{ marginInlineEnd: "1rem" }} className="unit">(م2)</span>
                            <input disabled={true} value={floorResult.quantity} />
                        </div>
                        <div>
                            <span style={{ marginInlineEnd: "1rem" }} className="unit">(م ط)</span>
                            <input disabled={true} value={floorResult.cycalo_mt} />
                        </div>
                        <div>
                            <span style={{ marginInlineEnd: "1rem" }} className="unit">%</span>
                            <input disabled={true} value={floorResult.waste} />
                        </div>
                    </div>
                </div>
                <button disabled={savedBtnStatus.saved || !savedBtnStatus.calculated}
                    onClick={() => saveHandler(true)} className={(savedBtnStatus.saved || !savedBtnStatus.calculated || !checkFormValidation()) ? "floor-wanted-btn disabled" : "floor-wanted-btn"}>حفظ</button>
            </div>
        </div >
    )
}

export default Floor;