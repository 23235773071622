import './package.scss'
import React, { useState, useEffect } from "react";

const Package = () => {
  const [packages, setPackages] = useState([])
  useEffect(() => {

    const packs = [
      { id: 1, name: "Package 1", peroid: '6', mainPrices: { 4: 600, 6: 810, 8: 1020 }, extra: { 1: 150, 3: 250, 5: 450 } },
      { id: 2, name: "Package 2", peroid: '12', mainPrices: { 4: 1000, 6: 1350, 8: 1700 }, extra: { 1: 100, 3: 300, 5: 500 } },
    ]


    setPackages(packs)


  }, [])

  return (<>
    <div className="container" dir="rtl">

      <div className="snip1214">
        {packages && packages?.map(el => (
          <div className="plan" key={el.id}
          // onClick={() => onPackageChange(el)}
          >
            <h3 className="plan-title">
              {el.name}
            </h3>
            <div className="plan-cost">

              <span className="plan-price">
                {el.peroid + "  "}
                {/* $19 */}
              </span>
              <span className="plan-type">


                شهر
              </span>

            </div>
            <ul className="plan-features">
              {Object.entries(el.mainPrices).map(price =>
                <li key={price[0]}>
                  <i className="ion-checkmark">
                  </i>

                  <label htmlFor={el.id + price[0]}>

                    {` $ ${price[1]}   لكل  ${price[0]} مستخدم  `}
                  </label>
                </li>
              )}

            </ul>
            {/* <div className="plan-select"><button onClick={(e) => selectPlan(e)} disabled={el.id != selectedPackage.id}>ادفع </button></div> */}
          </div>
        )

        )}



      </div>
    </div>
  </>)
}
export default Package;