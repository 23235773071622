import React, { useState } from 'react'
import "./landingPage.scss";
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import * as actionCreators from '../../store/actions/actionCreators';
import Img from '../../assets/NoPath.png';
import Img2 from '../../assets/logo3.jpg';
import { ReactComponent as Img4 } from '../../assets/Page 1.svg';

// import MasterCard from '../../assets/Mastercard-logo.svg.png';
import Visa from '../../assets/Visa_Inc._logo.svg.png';
import Login from '../../components/login/login'

import ClientSearch from './client-search/client-search';
import { useEffect } from 'react';

import Register from '../../components/register/register';
import { Link } from 'react-router-dom';

const LandingPage = () => {
    let history = useHistory();
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showRegister, setShowRegister] = useState(false);

    const handleCloseRegister = () => setShowRegister(false);
    const handleShowRegister = () => setShowRegister(true);
    const regYourDataHandler = () => {
        localStorage.clear();
        localStorage.setItem('currentUser', 'client')
        history.push("/data/client-data");
    }

    // const { handleInputChange, inputs, clearInputs } = useSignUpForm(
    //     {
    //         name: '',
    //         nameIsValid: false,
    //         nameIsTouched: false,
    //         alias: "",
    //         aliasIsValid: false,
    //         aliasIsTouched: false,
    //         email: "",
    //         emailIsValid: false,
    //         emailIsTouched: false,
    //         phone_number: "",
    //         phone_numberIsValid: false,
    //         phone_numberIsTouched: false,
    //         mobile: "",
    //         mobileIsValid: false,
    //         mobileIsTouched: false,
    //         country: '',
    //         countryIsValid: false,
    //         countryIsTouched: false,
    //         num_of_branches: '',
    //         num_of_branchesIsValid: false,
    //         num_of_branchesIsTouched: false,
    //         password: '',
    //         passwordIsValid: false,
    //         passwordIsTouched: false
    //     }
    // )



    useEffect(() => {
        let token = localStorage.getItem('token');
        let role = localStorage.getItem("role")
        if (token && role == 'company') {
            window.location.href = "/client-orders"
        }
        else
            if (token && role == 'admin') {
                window.location.href = "/dashboard"
            }

    }, [])
    return (
        <div className="landing-page-container">
            <Login show={show} handleClose={handleClose} />
            <div className="landing-page-container-header">
                {/* <button style={{ visibility: localStorage.getItem('token') ? "hidden" : "unset" }} onClick={() => handleShow()}>سجل الدخول</button> */}
                <button onClick={() => handleShow()}>سجل الدخول</button>
                <ClientSearch />
                <div className="header-logo ">
                    {/* <Img4 /> */}
                    {/* <img src={Img4} className="logo" /> */}
                    <Img4 className="logo" />
                    {/* <Logo className="logo" /> */}
                    {/* <Logo className="logo" /> */}
                    {/* <Logo2 className="logo" /> */}
                    {/* <span>Deem Smart</span> */}
                </div>
            </div>
            <div className="landing-page-container-overview">
                <div className="landing-page-container-overview-image">
                    <img src={Img} />
                </div>
                <div className="landing-page-container-overview-body">
                    <span className="body-header">   Deem Smart | ديم سمارت</span>
                    <p>
                        نظام سحابي لحساب كميات
                        السيراميك و السيكلو والجدران
                    </p>
                    <span className="body-text">سهولة ادخال البيانات والحصول على النتائج مع حفظ بيانات العميل</span>
                    <button onClick={handleShowRegister}>اطلب النظام لشركتك الان</button>
                </div>

            </div>
            <div className="landing-page-container-count" hidden>
                <div className="landing-page-container-count-unit">
                    <span className="unit-header">570</span>
                    <span className="unit-body">Download</span>
                </div>
                <div className="landing-page-container-count-unit">
                    <span className="unit-header">570</span>
                    <span className="unit-body">Active User</span>
                </div>
                <div className="landing-page-container-count-unit">
                    <span className="unit-header">570</span>
                    <span className="unit-body">Positive Feedback</span>
                </div>
                <div className="landing-page-container-count-unit">
                    <span className="unit-header">570</span>
                    <span className="unit-body">+ rating</span>
                </div>
            </div>

            <div className="landing-page-container-images">
                <div className="text-left">
                    <div className="line"></div>
                    <span className="text-header"> Deem Smart | ديم سمارت</span>
                    <span>جميع الأدوات التي تحتاجها معارض و مصانع السيراميك</span>
                    <span>حساب كمية وحجم بلاط الأرضيات وبلاط الجدران (متر مربع ، مربع)</span>
                    <span>حساب كميات السيراميك المطلوبة للحمامات و المطابخ</span>
                    <span>حساب الوزره أو شريط السيراميك</span>
                    <span>وضع رسم تخطيطي للمساحات و كمية البلاط أو السيراميك</span>
                    <span>حساب الكميات بالألوان المختلفة من السيراميك</span>
                </div>
                <div className="images-right">
                    <div className="image-large">
                        <div className="largeOpacity">
                            <span>حساب كمية السيكلو</span>
                        </div>
                    </div>
                    <div className="images-small">
                        <div className="small-left">
                            <div className="leftOpacity">
                                <span>حساب الجدران والأرضيات</span>
                            </div>
                        </div>
                        <div className="small-right">
                            <div className="rightOpacity">
                                <span>حساب الأرضيات</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="landing-page-container-reg">
                <div className="landing-page-container-reg-image">
                    <img src={Img} />
                </div>
                <div className="landing-page-container-reg-text">
                    <p className="reg-header">
                        سجل بياناتك وقياسات الغرف الخاصة
                        بمنزلك وبإمكانك استخدامها في اي فرع مع شركائنا
                    </p>
                    <p className="reg-text">
                        ادخل بيانات الغرف واحتفظ برقم النموذج الخاص بك
                        وتوجه لاي فرع شركة من شركائنا
                    </p>
                    <button onClick={() => regYourDataHandler()}>سجل بياناتك</button>
                </div>

            </div>

            <div className="landing-page-container-def">
                <p>لماذا نظام ديم سمارت</p>
                <div className="def-unit">
                    <div className="unit-text">
                        <span>توفير الوقت و الجهد للعاملين</span>
                        <p>نظام ديم سمارت يوفر لك الوقت و الجهد للعمال في معرضك او مصنعك</p>
                    </div>
                    <div className="unit-shape">
                        <div className="shape"></div>
                        <div className="line">
                        </div>
                    </div>
                </div>
                <div className="def-unit">
                    <div className="unit-text">
                        <span>سرعة في حساب الكميات المطلوبة</span>
                        <p>يقدم لك نظام ديم سمارت سرعة و دقة في حساب الكميات المطلوبة من البلاط و السيراميك في الأرضيات و الجدران و الوزره</p>
                    </div>
                    <div className="unit-shape">
                        <div className="shape"></div>
                        <div className="line">
                        </div>
                    </div>
                </div>
                <div className="def-unit">
                    <div className="unit-text">
                        <span>طباعة الحسابات  </span>
                        <p>يمكنك طباعة الحسابات و التقارير و مخططات موضع البلاط و السيراميك و الوزره</p>
                    </div>
                    <div className="unit-shape">
                        <div className="shape"></div>
                        {/* <div className="line"> */}
                        {/* </div> */}
                    </div>
                </div>
                {/* <div className="def-unit">
                    <div className="unit-text">
                        <span>تقارير مفصلة للعميل لعروض الأسعار</span>
                        <p>يقدم لك ديم سمارت تقارير مفصلة لك و لعميلك عن عروض أسعارك و نوعية منتجاتك و ألوان مختلفة</p>
                    </div>
                    <div className="unit-shape">
                        <div className="shape"></div>
                    </div>
                </div> */}
            </div>


            <div className="landing-page-container-footer" >
                <div className="credits">
                    {/* <Visa /> */}
                    <img className='credit' src='https://upload.wikimedia.org/wikipedia/commons/5/5e/Visa_Inc._logo.svg' />
                    <img className='credit' src="https://upload.wikimedia.org/wikipedia/commons/a/a4/Mastercard_2019_logo.svg" />
                </div>
                <div className="contact-us">
                    <h4>تواصل معنا</h4>
                    <a href="mailto:administrator@deemsmart.com">administrator@deemsmart.com</a>
                </div>
                <div className="footer-sub">
                    <ul>
                        <li>
                            <Link to={'/plans'}>
                                خطط الاستراك
                            </Link>

                        </li>
                        <li>
                            <Link to={'/privacy'}>
                                سياسة الخصوصية
                            </Link>

                        </li>
                        <li>
                            <Link to={'/return-refund'}>
                                سياسة الاسترجاع والاسترداد

                            </Link>

                        </li>
                        <li>
                            <Link to={'/terms'}>
                                الأحكام والشروط
                            </Link>

                        </li>
                    </ul>
                    {/*<span>اشترك في نشرتنا</span>*/}
                    {/*<div className="sub-btn">*/}
                    {/*    <button>اشترك الان</button>*/}
                    {/*    <input placeholder="اكتب بريدك الالكتروني" />*/}
                    {/*</div>*/}
                </div>
                <div className="footer-text">
                    <span>ديم سمارت</span>
                    {/*<p>يقدم لك ديم سمارت تقارير مفصلة لك و لعميلك عن عروض أسعارك و نوعية منتجاتك و ألوان مختلفة</p>*/}
                </div>


            </div>
            <Register showRegister={showRegister}
                handleShow={handleShow}
                handleCloseRegister={() => handleCloseRegister()}
                handleShowRegister={() => handleShowRegister()}

            />
        </div >
    )
}
export default LandingPage;
