import validator from 'validator';

const validate = (id, value, height = 0) => {

    switch (id) {
        case 'name':
        case 'username':
        case 'alias':
        case 'country':
            return (!validator.isEmpty(value) && value.length <= 30)
        case 'username':
            return (!validator.isEmpty(value) && value.length <= 30)
        case 'height':
            return (!validator.isEmpty(value) && value > 0)
        case 'cutting_style_id':
        case 'angle':
        case 'start':
            return (!validator.isEmpty(value))
        case 'tile_name':
            return (!validator.isEmpty(value))
        case 'tile_length':
        case 'length':
        case 'beltsLength':
            // case 'Spacelength':
           
            return (!validator.isEmpty(value) && value.length <= 10 && value > 0)
        case 'Spacelength':
            return (!validator.isEmpty(value) && value.length <= 10 && value > 0)
        case 'quantity':
        case 'colQuantity':
        case 'rowQuantity':

            return (!validator.isEmpty(value) && value > 0)
        case 'beltsCount':
            return (!validator.isEmpty(value) && value > 0)

        case 'tile_width':
        case 'beltsWidth':
        case 'width':
        case 'length':
            return (!validator.isEmpty(value) && value.length <= 10 && value > 0)
        case 'tile_spacing':
        case 'beltsSpacing':
        case 'space':
        case 'spacesCount':
            return (!validator.isEmpty(value) && value >= 0)
        case 'cycalo_height':
            return (!validator.isEmpty(value) && value.length <= 10 && value > 0)
        case 'address':
            return (value.length <= 20)
        case 'email':
            return (validator.isEmail(value))
        case 'password':
        case 'newPassword':
        case 'current_password':
            return (!validator.isEmpty(value))
        case 'phone':
        case 'phone_number':
        case 'mobile':
            return (validator.isMobilePhone(value))
        case 'status':
            return (isNaN(value))
        case 'role':
            return (isNaN(value))
        case 'doorsCount':
        case 'num_of_branches':
            return (validator.isInt(value) && value >= 0)
        case 'doorsWidth':
            return (value >= 0)
        case 'beltsName':
           
            return (!validator.isEmpty(value))

        default:
            break;
    }
}

export default validate;