import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { ReactComponent as Edit } from '../../assets/Icon awesome-edit.svg';
import { ReactComponent as Delete } from '../../assets/Icon material-delete.svg';
import EditCyclo from '../../pages/cyclo/editCyclo/editCyclo';
import EditFloor from '../../pages/floor/editFloor/editFloor';
import EditWall from '../../pages/wall/editWall/editWall'
import AddCompany from '../dashboard/company-list/add-company/add-company';
import AddUsersCompany from '../dashboard/company-list/add-users-company/add-users-company';
import Register from '../register/register';
import ResetPassword from '../resetPassword/resetPassword';
import "./contentTable.scss"
import axios from 'axios';
import { BASE_URL } from '../../constants/config'
import { ToastContainer, toast } from 'react-toastify';
import { handleError } from '../../utilities/handleError';
import * as errorTypes from "../../utilities/errorTypes"
const ContentTable = ({ parentComponent, deleteItem, headers, data, refresh }) => {
    let history = useHistory();

    const [showCycloModal, setShowCycloModal] = useState(false);
    const [showFloorModal, setShowFloorModal] = useState(false);
    const [showWallModal, setShowWallModal] = useState(false);
    const [showCompanyModal, setShowCompanyModal] = useState(false)
    const [showAddUsersCompanyModal, setShowAddUsersCompanyModal] = useState(false)
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

    const [mode, setMode] = useState("add")

    const handleCycloModalClose = () => {
        refresh();
        setShowCycloModal(false);
    }

    const handleFloorModalClose = () => {
        refresh();
        setShowFloorModal(false);
    }

    const handleWallModalClose = () => {
        refresh();
        setShowWallModal(false);
    }

    const handleCompanyModalClose = () => {
        refresh();
        setShowCompanyModal(false);
    }
    const handleAddUsersCompanyModalClose = () => {
        refresh();
        setShowAddUsersCompanyModal(false);
    }

    const handleResetPasswordModalClose = () => {
        refresh();
        setShowResetPasswordModal(false);
    }

    const handleCycloModalShow = () => setShowCycloModal(true);
    const handleFloorModalShow = () => setShowFloorModal(true);
    const handleWallModalShow = () => setShowWallModal(true);
    const handleCompanyModalShow = () => setShowCompanyModal(true);
    const handleAddUsersCompanyModalShow = () => setShowAddUsersCompanyModal(true);
    const handleResetPasswordModalShow = () => setShowResetPasswordModal(true);
    const [newPassword, setNewPAssword] = useState("")
    const resetPassword = (id) => {
        if (window.confirm(" هل تريد اعاده تعيين كلمه المرور ؟")) {
            axios.put(`${BASE_URL}/users/${id}/reset_password`, {

            }, {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    "Accept-Language": 'ar'

                }
            }).then(res => {

                setNewPAssword(res.data.new_password)
                handleResetPasswordModalShow()

            }).catch(error => {
                const errorResult = handleError(error)
                const errMsg = errorResult.message;
                toast.error(errMsg, {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                if (errorResult.type == errorTypes.unauthorized) {
                    setTimeout(() => {

                        localStorage.clear();
                        history.push("/home");
                    }, 1000);
                }

            })
        }
    }
    const [dataId, setDataId] = useState(null);
    // const [data, setData] = useState(null);

    const editCyclo = (id) => {
        setDataId(id)
        handleCycloModalShow();
    }

    const editFloor = (id) => {
        setDataId(id)
        handleFloorModalShow();
    }
    const editWall = (id) => {
        setDataId(id)
        // setData(data)
        handleWallModalShow();
    }
    const addCompany = (e, id, mode) => {
        e.stopPropagation()

        setDataId(id)
        // setData(data)
        setMode(mode)
        handleCompanyModalShow();
    }
    const addUsersCompany = (C_id) => {


        setDataId(C_id)
        // setData(data)
        // setMode(mode)
        handleAddUsersCompanyModalShow();
    }
    const ordersHandler = (identifier, data) => {

        localStorage.removeItem("floors")
        localStorage.removeItem("walls")
        localStorage.removeItem("cyclos")
        localStorage.setItem("clientData", JSON.stringify(data))

        localStorage.setItem("identifier", identifier)
        history.push(`/data/materials-cal/floor/${identifier}`);
    }
    //cyclos
    let totalCyclos = 0;
    let totalQty = 0
    let QtyMT = 0
    let QtyMsuqare = 0
    let QtyTiles = 0


    let roomArea = 0;
    let qtyAfterWaste = 0;
    let cyclo = 0;

    let totRoomAreaWith = 0
    let totRoomAreaWithout = 0
    let totWallAreaWith = 0
    let totCircWithout = 0
    return (
        <>
            <EditCyclo show={showCycloModal} handleClose={handleCycloModalClose} data={data} id={dataId} />
            <EditFloor show={showFloorModal} handleClose={handleFloorModalClose} data={data} id={dataId} />
            <EditWall show={showWallModal} handleClose={handleWallModalClose} data={data} id={dataId} />
            {/* <AddCompany show={showCompanyModal} handleClose={handleCompanyModalClose} id={dataId} mode={mode} /> */}
            <Register showRegister={showCompanyModal}
                // handleShow={handleShow}
                handleCloseRegister={() => handleCompanyModalClose()}
            // handleShowRegister={() => handleShowRegister()}

            />
            <ResetPassword
                show={showResetPasswordModal}
                handleClose={handleResetPasswordModalClose}
                password={newPassword}
            />
            <AddUsersCompany show={showAddUsersCompanyModal} handleClose={handleAddUsersCompanyModalClose} id={dataId} />

            {parentComponent == 'companies' && <button className='btn btn-transparent btn-add' onClick={(e) => addCompany(e, null, "add")}> + اضافه جديد</button>}

            <div className="clear"></div>
            <div className="Client-orders">
                <Table responsive>
                    <thead>
                        <tr>
                            {
                                headers.map(header => {
                                    return (
                                        <th key={header}>{header}</th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            parentComponent === 'roomWithHieght2' &&
                            data.filter(el => el.wallArea != 0).map(singleData => {
                                totRoomAreaWith = totRoomAreaWith + Number(singleData.floorArea);
                                totWallAreaWith = totWallAreaWith + Number(singleData.wallArea)
                                return (
                                    <tr key={singleData.id}>
                                        <td>{singleData.wallArea}</td>
                                        <td>{singleData.floorArea}</td>
                                        <td>{singleData.name}</td>
                                        {/* <td>{singleData.cyclo}</td> */}
                                        {/* <td><Edit /></td> */}
                                        {/* <td onClick={() => deleteItem(singleData.id)}><Delete /></td> */}
                                    </tr>
                                )
                            })}
                        {
                            parentComponent === 'roomWithHieght2' &&
                            <tr>
                                <td> {totWallAreaWith}</td>
                                <td>{totRoomAreaWith}</td>
                                <td>المجموع</td>
                                {/* <td></td> */}

                            </tr>
                        }
                        {
                            parentComponent === 'roomWithOutHieght2' &&
                            data.filter(el => el.wallArea == 0).map(singleData => {
                                totCircWithout = totCircWithout + Number(singleData.cyclo);
                                totRoomAreaWithout = totRoomAreaWithout + Number(singleData.floorArea)
                                return (
                                    <tr key={singleData.id}>
                                        <td>{singleData.floorArea}</td>
                                        <td>{singleData.cyclo}</td>
                                        <td>{singleData.name}</td>
                                        {/* <td>{singleData.wallArea}</td> */}
                                        {/* <td><Edit /></td> */}
                                        {/* <td onClick={() => deleteItem(singleData.id)}><Delete /></td> */}
                                    </tr>
                                )
                            })}
                        {
                            parentComponent === 'roomWithOutHieght2' &&
                            <tr>
                                <td> {totRoomAreaWithout}</td>
                                <td>{totCircWithout}</td>
                                <td>المجموع</td>
                                {/* <td></td> */}

                            </tr>
                        }

                        {
                            parentComponent === 'roomWithHieght' &&
                            data.filter(el => el.wallArea != 0).map(singleData => {
                                totRoomAreaWith = totRoomAreaWith + Number(singleData.floorArea);
                                totWallAreaWith = totWallAreaWith + Number(singleData.wallArea)
                                return (
                                    <tr key={singleData.id}>
                                        <td>{singleData.name}</td>
                                        {/* <td>{singleData.cyclo}</td> */}
                                        <td>{singleData.floorArea}</td>
                                        <td>{singleData.wallArea}</td>
                                        {/* <td><Edit /></td> */}
                                        <td onClick={() => deleteItem(singleData.id)}><Delete /></td>
                                    </tr>
                                )
                            })}
                        {
                            parentComponent === 'roomWithHieght' &&
                            <tr>
                                <td>المجموع</td>
                                {/* <td></td> */}
                                <td>{totRoomAreaWith}</td>
                                <td> {totWallAreaWith}</td>

                            </tr>
                        }
                        {
                            parentComponent === 'roomWithOutHieght' &&
                            data.filter(el => el.wallArea == 0).map(singleData => {
                                totCircWithout = totCircWithout + Number(singleData.cyclo);
                                totRoomAreaWithout = totRoomAreaWithout + Number(singleData.floorArea)
                                return (
                                    <tr key={singleData.id}>
                                        <td>{singleData.name}</td>
                                        <td>{singleData.cyclo}</td>
                                        <td>{singleData.floorArea}</td>
                                        {/* <td>{singleData.wallArea}</td> */}
                                        {/* <td><Edit /></td> */}
                                        <td onClick={() => deleteItem(singleData.id)}><Delete /></td>
                                    </tr>
                                )
                            })}
                        {
                            parentComponent === 'roomWithOutHieght' &&
                            <tr>
                                <td>المجموع</td>
                                {/* <td></td> */}
                                <td>{totCircWithout}</td>
                                <td> {totRoomAreaWithout}</td>

                            </tr>
                        }
                        {

                            parentComponent === 'groupedWallsFloorsTiles' &&
                            data.map((singleData, i) => {
                                // totalQty = totalQty + singleData.output?.no_of_cycalos;
                                // QtyMT = QtyMT + singleData.output?.quantity_mt;
                                // QtyTiles = QtyTiles + singleData.output?.no_of_tiles;
                                // QtyMsuqare = QtyMsuqare + singleData.output?.quantity_square;
                                return (
                                    <tr key={i}>
                                        <td>{singleData[0]}</td>
                                        <td>{singleData[1][0]}</td>
                                        <td>{singleData[1][1]}</td>
                                    </tr>
                                )
                            })
                        }
                        {

                            parentComponent === 'groupedFloorsTiles' &&
                            data.map((singleData, i) => {
                                // totalQty = totalQty + singleData.output?.no_of_cycalos;
                                // QtyMT = QtyMT + singleData.output?.quantity_mt;
                                // QtyTiles = QtyTiles + singleData.output?.no_of_tiles;
                                // QtyMsuqare = QtyMsuqare + singleData.output?.quantity_square;
                                return (
                                    <tr key={i}>
                                        <td>{singleData[0]}</td>
                                        <td>{
                                            parseFloat(Number.parseFloat(

                                                singleData[1][0]
                                            ).toFixed(2))
                                        }</td>
                                        <td>{
                                            parseFloat(Number.parseFloat(

                                                singleData[1][1]
                                            ).toFixed(2))
                                        }</td>
                                        <td>{
                                            parseFloat(Number.parseFloat(

                                                singleData[1][2]
                                            ).toFixed(2))
                                        }</td>
                                    </tr>
                                )
                            })
                        }
                        {/* cyclos */}
                        {

                            parentComponent === 'groupedCyclosTiles' &&
                            data.map((singleData, i) => {

                                // totalQty = totalQty + singleData.output?.no_of_cycalos;
                                // QtyMT = QtyMT + singleData.output?.quantity_mt;
                                // QtyTiles = QtyTiles + singleData.output?.no_of_tiles;
                                // QtyMsuqare = QtyMsuqare + singleData.output?.quantity_square;
                                return (
                                    <tr key={i}>
                                        <td>{singleData[0]}</td>
                                        <td>{
                                            parseFloat(Number.parseFloat(

                                                singleData[1][1]
                                            ).toFixed(2))
                                        }</td>
                                        <td>{
                                            parseFloat(Number.parseFloat(

                                                singleData[1][0]
                                            ).toFixed(2))
                                        }</td>
                                        <td>{
                                            parseFloat(Number.parseFloat(

                                                singleData[1][2]
                                            ).toFixed(2))
                                        }</td>
                                        <td>{
                                            parseFloat(Number.parseFloat(

                                                singleData[1][3]
                                            ).toFixed(2))
                                        }</td>
                                    </tr>
                                )
                            })
                        }
                        {/* cyclo */}
                        {

                            parentComponent === 'materials' &&
                            data.map(singleData => {
                                totalCyclos = totalCyclos + singleData.output?.no_of_cycalos;
                                totalQty = totalQty + singleData.output?.no_of_cycalos;
                                QtyMT = QtyMT + singleData.output?.quantity_mt;
                                QtyTiles = QtyTiles + singleData.output?.no_of_tiles;
                                QtyMsuqare = QtyMsuqare + singleData.output?.quantity_square;
                                return (
                                    <tr key={singleData.id}>
                                        {/* <td> -</td> */}
                                        <td>{singleData.tile_width}*{singleData.tile_length}</td>
                                        <td>{singleData.output?.no_of_cycalos}</td>
                                        <td>{singleData.output?.no_of_tiles}</td>
                                        <td>{singleData.output?.quantity_mt}</td>
                                        <td>{singleData.output?.quantity_square}</td>
                                        <td onClick={() => editCyclo(singleData.id)}><Edit /></td>
                                        <td onClick={() => deleteItem(singleData.id)}><Delete /></td>
                                    </tr>
                                )
                            })
                        }
                        {
                            parentComponent === 'materials' &&
                            <tr>
                                <td>المجموع</td>
                                {/* <td></td> */}
                                <td>{
                                    parseFloat(parseFloat(totalQty).toFixed(2))

                                }</td>
                                <td>
                                    {
                                        parseFloat(parseFloat(QtyTiles).toFixed(2))


                                    }</td>
                                <td>{
                                    parseFloat(parseFloat(QtyMT).toFixed(2))


                                }</td>
                                <td>{
                                    parseFloat(parseFloat(QtyMsuqare).toFixed(2))


                                }</td>

                            </tr>
                        }
                        {
                            parentComponent === 'roomMaterials' &&
                            data.map(singleData => {
                                qtyAfterWaste = qtyAfterWaste + parseFloat(singleData.output.quantity);
                                cyclo = cyclo + parseFloat(singleData.output.cycalo_mt);
                                roomArea = roomArea + parseFloat(singleData.output.before_waste)
                                return (
                                    <tr key={singleData.id}>
                                        <td>{singleData.room.name}</td>
                                        <td>
                                            {

                                            }
                                            {
                                                (singleData.room.shape.id == 1 || singleData.room.shape.id == 2) && <p>

                                                    {`${singleData.room.walls.wall_1} * ${singleData.room.walls.wall_2}`}

                                                </p>

                                            }
                                            {
                                                (singleData.room.shape.id == 3) && <p>

                                                    {`${singleData.room.walls.wall_1} * ${singleData.room.walls.wall_2} * ${singleData.room.walls.wall_3} * ${singleData.room.walls.wall_4}*
                                                    ${singleData.room.walls.wall_5} * ${singleData.room.walls.wall_6} * ${singleData.room.walls.wall_7} * ${singleData.room.walls.wall_8}
                                                    
                                                    `}
                                                </p>

                                            }
                                            {
                                                (singleData.room.shape.id == 4 || singleData.room.shape.id == 5) && <p>

                                                    {`${singleData.room.walls.wall_1} * ${singleData.room.walls.wall_2} * ${singleData.room.walls.wall_3} * ${singleData.room.walls.wall_4} *
                                                    ${singleData.room.walls.wall_5} * ${singleData.room.walls.wall_6}
                                                    
                                                    `}
                                                </p>

                                            }
                                        </td>
                                        <td>{singleData.output.before_waste}</td>
                                        <td>{singleData.output.quantity}</td>
                                        <td>{singleData.output.cycalo_mt}</td>
                                        <td>{singleData.tile_length}*{singleData.tile_width}</td>
                                        <td>{singleData.installation_angle.name_ar}</td>
                                        <td>{singleData.installation_start.name_ar}</td>
                                        <td onClick={() => editFloor(singleData.id)}><Edit /></td>
                                        <td onClick={() => deleteItem(singleData.id)}>< Delete /></td>
                                    </tr>
                                )
                            })
                        }
                        {
                            parentComponent === 'roomMaterials' &&
                            <tr>
                                <td>المجموع</td>
                                <td>-</td>
                                <td>{
                                    parseFloat(parseFloat(

                                        roomArea
                                    ).toFixed(2))
                                }</td>
                                <td>{
                                    parseFloat(parseFloat(

                                        qtyAfterWaste
                                    ).toFixed(2))
                                }</td>
                                <td>{
                                    parseFloat(parseFloat(

                                        cyclo
                                    ).toFixed(2))

                                }</td>
                            </tr>
                        }
                        {
                            parentComponent === 'wallsMaterials' &&



                            <tr >
                                <td>{data.room.name}</td>
                                <td>
                                    {

                                    }
                                    {
                                        (data.room.shape.id == 1 || data.room.shape.id == 2) && <p>

                                            {`${data.room.walls.wall_1} * ${data.room.walls.wall_2}`}

                                        </p>

                                    }
                                    {
                                        (data.room.shape.id == 3) && <p>

                                            {`${data.room.walls.wall_1} * ${data.room.walls.wall_2} * ${data.room.walls.wall_3} * ${data.room.walls.wall_4}*
                                                    ${data.room.walls.wall_5} * ${data.room.walls.wall_6} * ${data.room.walls.wall_7} * ${data.room.walls.wall_8}
                                                    
                                                    `}
                                        </p>

                                    }
                                    {
                                        (data.room.shape.id == 4 || data.room.shape.id == 5) && <p>

                                            {`${data.room.walls.wall_1} * ${data.room.walls.wall_2} * ${data.room.walls.wall_3} * ${data.room.walls.wall_4} *
                                                    ${data.room.walls.wall_5} * ${data.room.walls.wall_6}
                                                    
                                                    `}
                                        </p>

                                    }
                                </td>

                                <td>


                                    {data.output && data.output.wall_main_quantity}
                                </td>
                                <td>{data.output && data.output.wall_main_quantity_after_waste}</td>

                                <td>{data.wall_main_tile.tile_length}*{data.wall_main_tile.tile_width}</td>
                                <td>{data.wall_main_tile.installation_angle.name_ar}</td>
                                <td>{data.wall_main_tile.installation_start.name_ar}</td>

                                <td
                                ><Edit onClick={() => editWall(data.id)} /></td>
                                <td
                                    onClick={() => deleteItem(data.id)}
                                >
                                    < Delete /></td>
                            </tr>


                        }
                        {
                            parentComponent === 'WallsFloorsTiles' && <tr >
                                <td>{data.floor_quantity}</td>
                                <td>{data.floor_quantity_after_waste}</td>


                                <td>

                                    {data.floor_tile_length + "*" + data.floor_tile_width}
                                </td>

                                <td>{data.installation_angle}</td>
                                <td>{data.installation_start}</td>



                            </tr>
                        }

                        {
                            parentComponent === 'belt' &&
                            data.map((singleData, i) => {

                                return (
                                    <tr key={singleData.id}>
                                        <td> {"حزام رقم " + (i + 1)}</td>

                                        <td>{singleData.tile_length + "*" + singleData.tile_width}</td>
                                        {/* <td>{singleData.tile_name}</td> */}
                                        <td>{singleData.output}</td>
                                        {/* <td>{singleData.wallArea}</td> */}
                                        {/* <td><Edit /></td> */}
                                    </tr>
                                )
                            })}
                        {
                            parentComponent === 'belts' &&
                            data.map(singleData => {

                                return (
                                    <tr key={singleData.id}>
                                        <td>{singleData['width*height']}</td>
                                        {/* <td>{singleData.tile_name}</td> */}
                                        <td>{singleData.output}</td>
                                        {/* <td>{singleData.wallArea}</td> */}
                                        {/* <td><Edit /></td> */}
                                    </tr>
                                )
                            })}
                        {

                            parentComponent === 'groupedBeltsTiles' &&
                            data.map((singleData, i) => {
                                // totalQty = totalQty + singleData.output?.no_of_cycalos;
                                // QtyMT = QtyMT + singleData.output?.quantity_mt;
                                // QtyTiles = QtyTiles + singleData.output?.no_of_tiles;
                                // QtyMsuqare = QtyMsuqare + singleData.output?.quantity_square;
                                return (
                                    <tr key={i}>
                                        <td>{singleData[0]}</td>
                                        <td>{singleData[1][0]}</td>

                                    </tr>
                                )
                            })
                        }

                        {
                            parentComponent === 'spaces' &&
                            data.map((singleData, i) => {


                                return (
                                    <tr key={singleData.id}>
                                        <td>{"مساحه رقم " + (i + 1)}</td>
                                        <td>{singleData.length + "*" + singleData.width}</td>
                                        <td>{singleData.tile_length + "*" + singleData.tile_width}</td>
                                        <td>{singleData.after_waste}</td>
                                        <td>{singleData.before_waste}</td>
                                        <td>{singleData.installation_angle.name_ar}</td>
                                        <td>{singleData.installation_start.name_ar}</td>

                                    </tr>
                                )
                            })}
                        {

                            parentComponent === 'groupedSpacesTiles' &&
                            data.map((singleData, i) => {
                                // totalQty = totalQty + singleData.output?.no_of_cycalos;
                                // QtyMT = QtyMT + singleData.output?.quantity_mt;
                                // QtyTiles = QtyTiles + singleData.output?.no_of_tiles;
                                // QtyMsuqare = QtyMsuqare + singleData.output?.quantity_square;
                                return (
                                    <tr key={i}>
                                        <td>{singleData[0]}</td>
                                        <td>{singleData[1][0]}</td>
                                        <td>{singleData[1][1]}</td>

                                    </tr>
                                )
                            })
                        }
                        {
                            parentComponent === 'coloring' &&
                            data.map((singleData, i) => {

                                return (
                                    <tr key={singleData.id}>
                                        {/* <td>{"اللون رقم " + (i + 1)}</td> */}
                                        <td>{singleData.name}</td>
                                        <td>{singleData.num_of_tiles}</td>
                                        {/* <td>{singleData.columns}</td> */}
                                        {/* <td>{singleData.coloring_type}</td> */}
                                        {/* <td><Edit /></td> */}
                                    </tr>
                                )
                            })}
                        {/* {walls cols calculations} */}
                        {/* {
                            parentComponent === 'roomMaterials' &&
                            <tr>
                                <td>المجموع</td>
                                <td>-</td>
                                <td>{roomArea}</td>
                                <td>{qtyAfterWaste}</td>
                                <td>{cyclo}</td>
                            </tr>
                        } */}
                        {
                            parentComponent === 'orders' &&
                            data.map(singleData => {
                                return (
                                    <tr key={singleData.id} onClick={() => { ordersHandler(singleData.identifier, singleData) }} style={{ backgroundColor: singleData.company_id == null ? '#eee' : '#fff' }}>
                                        <td>{singleData.id}</td>
                                        <td>{singleData.identifier}</td>
                                        <td>{singleData.name}</td>
                                        <td>{singleData.email}</td>
                                        <td>{singleData.country_code}</td>
                                        <td>{singleData.phone}</td>
                                        <td>{singleData.address}</td>
                                        <td>{singleData.company_id}</td>
                                        <td>{singleData.created_at}</td>
                                        <td>{singleData.updated_at}</td>
                                        <td>{singleData.rooms_count}</td>

                                        {/* <td><Edit /></td> */}
                                        <td onClick={() => deleteItem(singleData.id)}><Delete /></td>
                                    </tr>
                                )
                            })}
                        {
                            parentComponent === 'companies' &&
                            data.map((singleData, i) => {

                                return (
                                    <tr key={singleData.id} >

                                        {/* <td onClick={() => deleteItem(singleData.id)}><Delete /></td> */}
                                        {/* <td onClick={(e) => addCompany(e, singleData.id, "edit")}><Edit /></td> */}
                                        <td > <button className='btn btn-add-user' onClick={() => resetPassword(singleData.owners[0].id)}> اعاده تعيين كلمه المرور  </button></td>
                                        <td>{singleData.users?.length}</td>
                                        <td>{singleData.num_of_branches}</td>
                                        <td>{singleData.country}</td>
                                        <td>{singleData.mobile}</td>
                                        <td>{singleData.phone_number}</td>
                                        <td>{singleData.email}</td>
                                        <td>{singleData.alias}</td>
                                        <td>{singleData.name}</td>
                                    </tr>
                                )
                            })}

                        {
                            parentComponent === 'clientsList' &&
                            data.map((singleData, i) => {

                                return (
                                    <tr key={singleData.id} >
                                        <td>{singleData.rooms_count}</td>
                                        <td>{singleData.address}</td>
                                        <td>{singleData.phone}</td>
                                        <td>{singleData.email}</td>
                                        <td>{singleData.name}</td>
                                        <td>{singleData.identifier}</td>
                                        <td>{singleData.id}</td>
                                    </tr>
                                )
                            })}



                    </tbody>
                </Table>
            </div>
        </>
    )
}
export default ContentTable;