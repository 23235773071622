import React, { useState } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import Header from './components/header/header';
import LandingPage from './pages/landingPage/landingPage';
import UserData from './pages/userData/userData';
import Login from './components/login/login'
import ClientOrders from './pages/clientOrders/clientOrders';
import UserInfo from './pages/user-info/user-info';
import { useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from './constants/config'
import * as basicFn from "../src/store/actions/basicFn"
import NotFound from './components/not-found/not-found';
import { ProtectedRoute } from './auth/protected-route';
import Dashboard from './components/dashboard/dashboard';
import { AdminProtectedRoute } from './auth/admin-protected-route';
import Profile from './pages/profile/profile';
import { ProtectedRouteAdminUser } from './auth/protected-route-admin-user';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import PrivacyPolicyComponent from "./pages/privacy-policy/privacy-policy";
import TermsComponent from "./pages/terms/terms";
import ReturnRefundPolicyComponent from "./pages/return-refund-policy/return-refund-policy";
import Package from './pages/packages/package';
import { handleError } from './utilities/handleError';
import * as errorTypes from "./utilities/errorTypes"
function App() {
  let history = useHistory();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let location = useLocation();
  useEffect(() => {

    axios.get(`${BASE_URL}/cutting_style`, {
      headers: {
        "Accept": "application/json",
        // Authorization: `Bearer ${localStorage.getItem('token')}`,

      }
    }).then(res => {

      basicFn.setCutting_style(res.data)
      // setCutTypes(res.data)
    }).catch(error => {
      const errorResult = handleError(error)
      const errMsg = errorResult.message;
      toast.error(errMsg, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (errorResult.type == errorTypes.unauthorized) {
        setTimeout(() => {

          localStorage.clear();
          history.push("/home");
        }, 1000);
      }
    })

    // axios.get(`${BASE_URL}/tile?company_id=${localStorage.getItem('company_id')}`, {
    //   headers: {
    //     "Accept": "application/json",
    //     Authorization: `Bearer ${localStorage.getItem('token')}`,
    //     "Accept-Language": 'ar'

    //   }
    // }).then(res => {
    //   basicFn.setTiles(res.data)
    // }).catch(error => {
    //   const errorResult = handleError(error)
    //   const errMsg = errorResult.message;
    //   toast.error(errMsg, {
    //     position: "top-right",
    //     autoClose: 1500,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    //   if (errorResult.type == errorTypes.unauthorized) {
    //     setTimeout(() => {

    //       localStorage.clear();
    //       history.push("/home");
    //     }, 1000);
    //   }

    // })

    axios.get(`${BASE_URL}/installation_angle`, {
      headers: {
        "Accept": "application/json",
        // Authorization: `Bearer ${localStorage.getItem('token')}`,
        "Accept-Language": 'ar'

      }
    }).then(res => {
      basicFn.setInstlationAngle(res.data)
      // setInstallationAngles(res.data)
    }).catch(error => {
      const errorResult = handleError(error)
      const errMsg = errorResult.message;
      toast.error(errMsg, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (errorResult.type == errorTypes.unauthorized) {
        setTimeout(() => {

          localStorage.clear();
          history.push("/home");
        }, 1000);
      }
      // if (err.response.status == 401) {
      //   toast.error(err.response.data.message, {
      //     position: "top-right",
      //     autoClose: 1500,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //   });
      //   setTimeout(() => {

      //     localStorage.clear();
      //     history.push("/home");
      //   }, 1000);
      // } else {
      //   if (err.response) {
      //     toast.error(err.response.data.message, {
      //       position: "top-right",
      //       autoClose: 1500,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //       draggable: true,
      //       progress: undefined,
      //     });
      //   }
      //   else {
      //     toast.error(err.message, {
      //       position: "top-right",
      //       autoClose: 1500,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //       draggable: true,
      //       progress: undefined,
      //     });
      //   }

      // }

    })

    axios.get(`${BASE_URL}/strap_installation_style`, {
      headers: {
        "Accept": "application/json",
        // Authorization: `Bearer ${localStorage.getItem('token')}`,
        "Accept-Language": 'ar'

      }
    }).then(res => {
      basicFn.setInstlationStyle(res.data)
      // setInstallationStyles(res.data)
    }).catch(error => {
      const errorResult = handleError(error)
      const errMsg = errorResult.message;
      toast.error(errMsg, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (errorResult.type == errorTypes.unauthorized) {
        setTimeout(() => {

          localStorage.clear();
          history.push("/home");
        }, 1000);
      }

    })
  }, [])
  return (
    <div className="App">
      {
        location.pathname !== '/home' && location.pathname !== '/' &&
        <div>


          <Header handleShow={handleShow}></Header>

        </div>

      }
      <div className="App-body">
        <Switch>
          <Route path="/data">
            <UserData />
          </Route>

          <ProtectedRoute path={`/client-orders`} component={ClientOrders} />
          <ProtectedRouteAdminUser path={`/profile`} component={Profile} />
          <Route path="/home" component={LandingPage}></Route>
          <AdminProtectedRoute path="/dashboard" component={Dashboard} />
          <Route path="/" exact>
            <Redirect to='/home' />
          </Route>

          <Route path="/user-info/:id" component={UserInfo}></Route>
          <Route path='/terms' component={TermsComponent}></Route>
          <Route path='/privacy' component={PrivacyPolicyComponent}></Route>
          <Route path='/return-refund' component={ReturnRefundPolicyComponent}></Route>
          <Route path='/plans' component={Package}></Route>



          <Route path="**" component={NotFound}></Route>
        </Switch>
      </div>
      <Login show={show} handleClose={handleClose} />
    </div >
  );
}

export default App;
