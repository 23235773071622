import * as actionTypes from '../actions/actionTypes';
import * as actions from '../actions/actionCreators';

const initialState = {
    cyclos: []
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        // case actionTypes.SETCYCLOS:
        //     return {
        //         cyclos: action.cyclos
        //     }

        // case actionTypes.GETCYCLO:
        //     return [...state.cyclos].find(cyclo => cyclo.id === action.cycloId);

        // case actionTypes.ADDCYCLO:
        //     let cyclosCopy = [...state.cyclos];
        //     cyclosCopy.push(action.cyclo)
        //     return {
        //         ...state,
        //         cyclos: cyclosCopy
        //     }

        // case actionTypes.EDITCYCLO:
        //     let cyclosCopyEdit = [...state.cyclos];
        //     let index = cyclosCopyEdit.findIndex(el => el.id != action.cyclo.id)
        //     if (index != -1) {

        //         cyclosCopyEdit.splice(index, 1)
        //     }
        //     // if (actions.getCyclosFromStorage()) {
        //     // let cyclosCopyEditFromStorage = [...actions.getCyclosFromStorage()];
        //     // let newCyclosCopyEditFromStorage = cyclosCopyEditFromStorage.filter(el => el.id != action.cyclo.id)
        //     // let toBeEditedCycloFromStorageIndex = cyclosCopyEditFromStorage.findIndex(cyclo => cyclo.id === action.cyclo.id);

        //     // cyclosCopyEditFromStorage[toBeEditedcycloFromStorageIndex].output.saved = true;
        //     // localStorage.setItem("cyclos", JSON.stringify(newCyclosCopyEditFromStorage))
        //     // }
        //     // let toBeEditedCycloIndex = cyclosCopyEdit.findIndex(cyclo => cyclo.id === action.cyclo.id)
        //     // cyclosCopyEdit[toBeEditedCycloIndex] = { ...action.cyclo }
        //     localStorage.setItem("cyclos", JSON.stringify(cyclosCopyEdit))

        //     return {
        //         ...state,
        //         cyclos: cyclosCopyEdit
        //     }

        // case actionTypes.DELETECYCLO:
        //     let cyclosCopyDelete = [...state.cyclos];
        //     // let toBeDeletedCycloIndex = cyclosCopyDelete.findIndex(cyclo => cyclo.id === action.cycloId)
        //     // cyclosCopyDelete.splice(toBeDeletedCycloIndex, 1)
        //     cyclosCopyDelete.splice(action.cycloId, 1)
        //     // if (actions.getCyclosFromStorage()) {
        //     //     let cyclosCopyEditFromStorage = [...actions.getCyclosFromStorage()];
        //     //     let newCyclosCopyEditFromStorage = cyclosCopyEditFromStorage.filter(el => el.id != action.cyclo.id)
        //     //     // let toBeEditedCycloFromStorageIndex = cyclosCopyEditFromStorage.findIndex(cyclo => cyclo.id === action.cyclo.id);

        //     //     // cyclosCopyEditFromStorage[toBeEditedcycloFromStorageIndex].output.saved = true;
        //     //     localStorage.setItem("cyclos", JSON.stringify(newCyclosCopyEditFromStorage))
        //     // }
        //     localStorage.setItem("cyclos", JSON.stringify(cyclosCopyDelete))

        //     return {
        //         ...state,
        //         cyclos: cyclosCopyDelete
        //     }

        default:
            return state;
    }
}

export default reducer;