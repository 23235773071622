import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { BASE_URL } from '../../constants/config';
import { ToastContainer, toast } from 'react-toastify';
import { ReactComponent as Close } from '../../assets/Icon ionic-ios-close-circle-outline.svg';
import useSignUpForm from '../../hooks/useSignUpForm'
import "./register.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { ReactComponent as Printlogo } from '../../assets/print.svg';
import { handleError } from '../../utilities/handleError';
import * as errorTypes from "./../../utilities/errorTypes"
import { useHistory } from "react-router-dom";

const Register = (props) => {
  let history = useHistory();

  const [show, setShow] = useState(false);
  const handleClose = () => {
    clearInputs()
    setShow(false)
  };
  const handleShow = () => setShow(true);
  const [username, setUsername] = useState("")
  const [company_users, setCompanyUsers] = useState([])
  const { handleInputChange, inputs, clearInputs } = useSignUpForm(
    {
      name: '',
      nameIsValid: false,
      nameIsTouched: false,
      alias: "",
      aliasIsValid: false,
      aliasIsTouched: false,
      email: "",
      emailIsValid: false,
      emailIsTouched: false,
      phone_number: "",
      phone_numberIsValid: false,
      phone_numberIsTouched: false,
      mobile: "",
      mobileIsValid: false,
      mobileIsTouched: false,
      country: '',
      countryIsValid: false,
      countryIsTouched: false,
      num_of_branches: '',
      num_of_branchesIsValid: false,
      num_of_branchesIsTouched: false,
      password: '',
      passwordIsValid: false,
      passwordIsTouched: false
    }
  )
  const [msg, setMsg] = useState("انسخ")
  const checkFormValidation = () => {
    return inputs.nameIsValid && inputs.aliasIsValid && inputs.countryIsValid && inputs.num_of_branchesIsValid &&
      inputs.emailIsValid && inputs.passwordIsValid && inputs.mobileIsValid && inputs.mobileIsValid;

  }

  const print = () => {
    var mywindow = window.open('', 'PRINT', 'height=400%,width=1000');

    mywindow.document.write('<html><head><title>' + document.title + '</title>');
    mywindow.document.write(`<style>
    .username-password{
      display:flex;
      margin-bottom:1rem;
      
    }
    .form-control-item{
      width:50%;
      padding:0.5rem
      
    }
    input{
      padding:0.5rem

    }
    span{
      margin:1rem
    }
    @media screen {
      div.divFooter {
        display: none;
      }
    }
    @media print {
      div.divFooter {

        position: fixed;
        bottom: 0;
        text-align:center;
        right:50%;
      
        
      }
    }
  </style>`)
    mywindow.document.write('</head><body>');
    var x = mywindow.document.createElement("IMG");
    x.setAttribute("src", "../smLogo.png");

    x.setAttribute("height", "100");

    mywindow.document.body.appendChild(x);
    mywindow.document.write('<div className="register"  dir="rtl">');
    // mywindow.document.write('<p> كلمه المرور هي التي تم ادخالها في النموذج لجميع الموظفين</p>');
    // mywindow.document.write('<h6>انسخ اسم المستخدم لتسجيل الدخول</h6>');
    // mywindow.document.write('<div class="form-control-item my-2"><span>اسم المستخدم</span>');
    // mywindow.document.write('<input type="text" name="alias" disabled value=' + username + ' />')
    // mywindow.document.write('</div>')
    mywindow.document.write('<div>')
    mywindow.document.write('<h5> اسماء المستخدمين لموظفين الشركه</h5>')

    company_users.forEach(el => {
      mywindow.document.write('<div class="username-password">')
      mywindow.document.write('<div class="form-control-item my-2">')
      mywindow.document.write('<span>اسم المستخدم</span>')
      mywindow.document.write('<input type="text" disabled value=' + el.username + ' />')
      mywindow.document.write('</div>')
      mywindow.document.write('<div class="form-control-item my-2">')
      mywindow.document.write('<span> كلمه المرور</span>')
      mywindow.document.write('<input type="text" disabled value=' + el.created_password + ' />')
      mywindow.document.write('</div>')
      mywindow.document.write('</div>')

    });


    mywindow.document.write('<div>')
    mywindow.document.write('<div>')

    mywindow.document.write(' <div class="divFooter">deemsmart.com</div>');

    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    setTimeout(() => {

      mywindow.print();
      mywindow.close();
    }, 250)


  }

  const copyText = () => {
    navigator.clipboard.writeText(username);
    setMsg("تم النسخ")
  }
  const onSubmit = () => {
    let { name, alias, email, phone_number, mobile, country, num_of_branches, password } = inputs

    axios.post(`${BASE_URL}/company/create`,
      {
        name, email, phone_number, mobile, country, num_of_branches,
        alias,
        password
      }, {
      headers: {

        "Accept-Language": 'ar'
      }

    }).then((res) => {

      setUsername(res.data.owners[0].username)
      setCompanyUsers(res.data.company_users)
      clearInputs()
      props.handleCloseRegister()
      handleShow()

    })
      .catch(error => {
        const errorResult = handleError(error)
        const errMsg = errorResult.message;
        toast.error(errMsg, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (errorResult.type == errorTypes.unauthorized) {
          setTimeout(() => {

            localStorage.clear();
            history.push("/home");
          }, 1000);
        }
        // if (error.response) {

        //   toast.error(error.response.data.message, {
        //     position: "top-right",
        //     autoClose: 1500,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //   });
        // }
        // else {
        //   toast.error(error.message, {
        //     position: "top-right",
        //     autoClose: 1500,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //   });
        // }
      })
  }

  return (<>
    <ToastContainer />
    <Modal show={props.showRegister} onHide={props.handleCloseRegister} dialogClassName="register-modal" dir="rtl">
      <Modal.Header>
        <Modal.Title>
          <h4> تسجيل بيانات الشركة</h4>
        </Modal.Title>
        <Close className="close-icon" onClick={() => props.handleCloseRegister()} />
      </Modal.Header>
      <Modal.Body>
        {

          <div className="register" >

            <h4>بيانات الشركة</h4>
            <div className='form-control-item my-2'>
              <span>اسم الشركة</span>
              <input name="name"
                value={inputs.name} onChange={(e) => handleInputChange(e)}
              />
              {/* onChange={(e)=>on} */}

            </div>
            {inputs.nameIsTouched && !inputs.nameIsValid &&

              <span className="error-msg">اسم الشركة  خطأ</span>
            }

            <div className='form-control-item my-2'>
              <span> البريد الالكتروني</span>
              <input name="email"
                value={inputs.email} onChange={(e) => handleInputChange(e)}

              />
            </div>
            {inputs.emailIsTouched && !inputs.emailIsValid &&
              <span className="error-msg">البريد الالكتروني خطأ</span>
            }
            <div className='form-control-item my-2'>
              <span> هاتف ثابت</span>
              <input type="text" name="phone_number"
                value={inputs.phone_number} onChange={(e) => handleInputChange(e)}

              />
            </div>
            {inputs.phone_numberIsTouched && !inputs.phone_numberIsValid &&

              <span className="error-msg">رقم الهاتف   خطأ</span>
            }
            <div className='form-control-item my-2'>
              <span>جوال</span>
              <input type='text' name="mobile"
                value={inputs.mobile} onChange={(e) => handleInputChange(e)}
              />
            </div>
            {inputs.mobileIsTouched && !inputs.mobileIsValid &&

              <span className="error-msg">الجوال    خطأ</span>
            }
            {/* <div className='form-control-item my-2'>
                        <span> اسم المفوض</span>
                        <input type="text" />
                    </div> */}
            <div className='form-control-item my-2' >
              <span>البلد </span>
              <input name="country"
                value={inputs.country} onChange={(e) => handleInputChange(e)}
              />
            </div>
            {inputs.countryIsTouched && !inputs.countryIsValid &&

              <span className="error-msg">البلد    خطأ</span>
            }
            <div className='form-control-item my-2'>
              <span>عدد الفروع </span>
              <input type="number" name="num_of_branches"
                value={inputs.num_of_branches} onChange={(e) => handleInputChange(e)}

              />
            </div>
            {inputs.num_of_branchesIsTouched &&
              !inputs.num_of_branchesIsValid &&

              <span className="error-msg">عدد الفروع    خطأ</span>
            }
            <h4>بيانات الدخول</h4>
            <div className='form-control-item my-2'>
              <span>الاسم المستعار </span>
              <input type='text' name="alias"
                value={inputs.alias} onChange={(e) => handleInputChange(e)}

              />
            </div>
            {inputs.aliasIsTouched &&
              !inputs.aliasIsValid &&
              <span className="error-msg">الاسم المستعار  خطأ</span>
            }
            <div className='form-control-item my-2'>
              <span>كلمة المرور</span>
              <input type="password" name="password"
                value={inputs.password} onChange={(e) => handleInputChange(e)}

              />
            </div>
            {inputs.passwordIsTouched &&
              !inputs.passwordIsValid &&
              <span className="error-msg">كلمة المرور   خطأ</span>
            }
          </div>

        }

        <Button
          variant="primary"
          onClick={() => onSubmit()}
          disabled={!checkFormValidation()}
          className={checkFormValidation() ? "register-btn" : "register-btn disabled"}

        >حفظ</Button>
      </Modal.Body>
      <Modal.Footer>

      </Modal.Footer>
    </Modal>


    <Modal show={show} onHide={handleClose} dialogClassName="register-modal" dir="rtl">
      <Modal.Header>
        <Modal.Title>
          <h4> تسجيل بيانات الشركة</h4>
          {/* <button onClick={print}>print</button> */}
        </Modal.Title>
        <Close className="close-icon" onClick={() => handleClose()} />
      </Modal.Header>
      <Modal.Body>
        {

          <div className="register" >

            {/* <p style={{ color: '#390239' }}>*** كلمه المرور هي التي تم ادخالها في النموذج لجميع الموظفين ***</p> */}
            {/* <div className='form-control-item my-2'>
                        <span> اسم المفوض</span>
                        <input type="text" />
                    </div> */}


            {/* <h6>انسخ اسم المستخدم لتسجيل الدخول</h6> */}
            {/* <div className='form-control-item my-2'>
              <span>اسم المستخدم للشركه</span>
              <input type='text' name="alias" disabled
                value={username}

              />
           
            </div> */}

            <div>
              <h5>
                اسماء المستخدمين لموظفين الشركه
              </h5>
              <div style={{ textAlign: "center" }}>

                <button className='btn btn-transparent' onClick={print} >
                  <Printlogo />
                </button>
              </div>
              {company_users && company_users.map(el =>
                <div className='username-password-container' style={{ display: 'flex' }}>

                  <div className=' col-6 form-control-item my-2'>
                    <span>اسم المستخدم</span>
                    <input type='text' disabled
                      value={el.username}

                    />
                  </div>
                  <div className=' col-6 form-control-item my-2'>
                    <span> كلمه المرور</span>
                    <input type='text' disabled
                      value={el.created_password}

                    />
                  </div>

                </div>
              )}
            </div>

          </div>

        }


      </Modal.Body>
      <Modal.Footer>

      </Modal.Footer>
    </Modal>
  </>)
}
export default Register;