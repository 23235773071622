import React, { useState } from 'react';
import './mainSlab.scss';
import useSignUpForm from '../../hooks/useSignUpForm'
import { ReactComponent as Width_45 } from '../../assets/45 3ardy.svg';
import { ReactComponent as Type } from '../../assets/Rectangle 1051.svg';
import { ReactComponent as Start } from '../../assets/Group 3520.svg';
import { ReactComponent as Width } from '../../assets/Group 3508.svg';
import SlabMeasure from '../slabMeasure/slabMeasure';
import InstallChoices from '../installChoices/installChoices';

const MainSlab = ({ slab, tiles, handleSlabInputChange, mainSlabChoices, installationAngles, installationStarts, handleSlabInstallChange }) => {
    return (
        slab &&
        <div className="container">
            <div className="main-slab">
                <div className="main-slab-body">
                    <SlabMeasure
                        parent='mainSlab'
                        tile_name={slab.tile_name}
                        tile_length={slab.tile_length}
                        tile_lengthIsValid={slab.tile_lengthIsValid}
                        tile_lengthIsTouched={slab.tile_lengthIsTouched}
                        tile_width={slab.tile_width}
                        tile_widthIsValid={slab.tile_widthIsValid}
                        tile_widthIsTouched={slab.tile_widthIsTouched}
                        tile_spacing={slab.tile_spacing}
                        tile_spacingIsValid={slab.tile_spacingIsValid}
                        tile_spacingIsTouched={slab.tile_spacingIsTouched}
                        tiles={tiles}
                        handleInputChange={handleSlabInputChange} />

                    <InstallChoices
                        hideStackingMethod={true}
                        installationAngles={installationAngles}
                        installationStarts={installationStarts}
                        installation_start_id={mainSlabChoices.installation_start_id}
                        installation_angle_id={mainSlabChoices.installation_angle_id}
                        handleInputChange={handleSlabInstallChange}
                    />


                </div>
            </div>
        </div>
    )

}
export default MainSlab;