import React from 'react';
import { ReactComponent as Printlogo } from '../../assets/print.svg';
import { ReactComponent as Logo } from '../../assets/logo2.svg';
import html2canvas from "html2canvas"
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const PrintComponent = (props) => {



  const print = () => {

    let userData = JSON.parse(localStorage.getItem("clientData"))
    if (localStorage.getItem("currentUser") == 'client') {
      userData = JSON.parse(sessionStorage.getItem("clientData"))
    }
    // console.log(userData)
    var mywindow = window.open('', 'PRINT', 'height=400%,width=1000');

    mywindow.document.write('<html><head><title>' + document.title + '</title>');
    mywindow.document.write(`<style>
    table { 
      width: 750px; 
      border-collapse: collapse; 
      margin:15px auto;
      }
    h3,h5{
      text-align: center; 
    }
    .sm-heading-spacing{
      padding:0.2rem;
      margin:0.2rem;
    }
    /* Zebra striping */
    tr:nth-of-type(odd) { 
      background: #eee; 
      }
    
    th { 
      background: #3498db; 
      color: white; 
      font-weight: bold; 
      }
    
    td, th { 
      padding: 10px; 
      border: 1px solid #ccc; 
      text-align: right; 
      font-size: 18px;
      }
      .container{
      padding: 10px; 
      /* border: 1px solid #eee; 
      box-shadow: 2px 5px #eee;
      border-radius:20px;*/
      margin:1rem;



      }
      .userData{
    display:flex;
    gap:1rem;
    justify-content: space-around;
  flex-wrap:wrap;
      
      }

      .group{
      margin-bottom:2rem;
      

      }
      .userData .group{
        h4,h5,h6{
          text-align:right
        }
      }
      form{
        width:100%;
        text-align:right;
      
      
      }
      form label{
        font-size:16px
      }
   
      form p{
        font-size:16px;
        // display:inline
      }
      @media screen {
        div.divFooter {
          display: none;
        }
      }
      @media print {
        div.divFooter {

          position: fixed;
          bottom: 0;
          text-align:center;
          right:50%;
        
          
        }
      }
    </style>`)
    mywindow.document.write('</head><body>');

    var x = mywindow.document.createElement("IMG");
    x.setAttribute("src", "../smLogo.png");

    x.setAttribute("height", "100");

    mywindow.document.body.appendChild(x);
    mywindow.document.write(`<form>
    <div class="userData">
    <div class="group">
    <label >
     رقم النموذج</label><p>${userData.identifier}</p>
     </div>
   <div class="group">

     <label >اسم العميل </label><p>${userData.name}</p>
   </div>
   <div class="group">

     <label >
     رقم التليفون</label><p>${userData.phone || ''}</p><label >العنوان </label><p>${userData.address || ''}</p>
   </div>
   <div class="group">
    
     <label >
     البريد الالكتروني </label><p>${userData.email || ''}</p>
   </div>
</div>
     </form>`)



    if (props && props.cyclos && props.cyclos.length > 0) {
      mywindow.document.write('<div class="container">')
      mywindow.document.write('<h3>' + 'اجمالي السيكلو' + '</h3>');


      let cyclosHeading = [

        'الكمية متر مربع',
        'الكمية متر طولي',
        'عدد الحبات المستخدمة',
        'عدد حبات السيكلو',
        'قياسات البلاطة',
        // 'مسمي البلاطة',
      ]
      let cyclosHeadingTotal = [
        'السيكلو',

        'عدد الحبات المستخدمة',
        'طول و عرض البلاطة',

      ];
      mywindow.document.write('<table>')
      mywindow.document.write('<tr>')
      for (let head of cyclosHeading) {
        mywindow.document.write('<th>' + head + '</th>')

      }
      mywindow.document.write('</tr>')
      let totalQty = 0;
      let QtyMT = 0;
      let QtyTiles = 0;
      let QtyMsuqare = 0
      let QtyCyclo = 0
      for (let item of props.cyclos) {
        totalQty = totalQty + item.output?.no_of_cycalos;
        QtyMT = QtyMT + item.output?.quantity_mt;
        QtyTiles = QtyTiles + item.output?.no_of_tiles;
        QtyMsuqare = QtyMsuqare + item.output?.quantity_square;
        QtyCyclo = QtyCyclo + item.output?.no_of_cycalos;

        mywindow.document.write('<tr>')
        // mywindow.document.write('<td>'+item+'</td>')

        mywindow.document.write('<td>' + item.output?.quantity_square + '</td>')
        mywindow.document.write('<td>' + item.output?.quantity_mt + '</td>')
        mywindow.document.write('<td>' + item.output?.no_of_tiles + '</td>')
        mywindow.document.write('<td>' + item.output?.no_of_cycalos + '</td>')
        mywindow.document.write('<td>' + item.tile_width + '*' + item.tile_length + '</td>')
        // mywindow.document.write('<td>' + ' -' + '</td>')
        mywindow.document.write('</tr>')

      }
      mywindow.document.write('<tr>')
      // mywindow.document.write('<td>'+item+'</td>')
      // mywindow.document.write(' <td>' + QtyCyclo + '</td>')

      mywindow.document.write(' <td>' +
        parseFloat(parseFloat(

          QtyMsuqare
        ).toFixed(2))
        + '</td>')
      mywindow.document.write(' <td>' +
        parseFloat(parseFloat(

          QtyMT
        ).toFixed(2))

        + '</td>')
      mywindow.document.write(' <td>' +
        parseFloat(parseFloat(

          QtyTiles
        ).toFixed(2))
        + '</td>')
      mywindow.document.write(' <td>' +
        parseFloat(parseFloat(

          totalQty
        ).toFixed(2))
        + '</td>')
      // mywindow.document.write(' <td>' + '' + '</td>')
      mywindow.document.write(' <td>' + 'المجموع' + '</td>')

      mywindow.document.write('</tr>')


      mywindow.document.write('</table>')
      mywindow.document.write('</div>')




    }

    if (props && props.floors && props.floors.length > 0) {
      mywindow.document.write('<div class="container">')

      mywindow.document.write('<h3>' + 'اجمالي الارضيات' + '</h3>');


      let floorsHeading = [
        'بداية التركيب',
        'زاوية التركيب',
        ' قياسات البلاطة',
        'السيكو',
        'الكمية بعد الهدر',
        'مساحة الغرفة',
        'قياسات الغرفه',
        'اسم الغرفة',


      ]
      let floorsHeadingTotal = [
        'السيكلو',

        'عدد الحبات المستخدمة',
        'طول و عرض البلاطة',
      ];
      mywindow.document.write('<table>')
      mywindow.document.write('<tr>')
      for (let head of floorsHeading) {
        mywindow.document.write('<th>' + head + '</th>')

      }
      mywindow.document.write('</tr>')
      let qtyAfterWaste = 0;
      let cyclo = 0;
      let roomArea = 0;


      for (let item of props.floors) {
        let shape = '';
        if (item.room.shape.id == 1 || item.room.shape.id == 2) {
          shape = `${item.room.walls.wall_1} * ${item.room.walls.wall_2}`
        } else if (item.room.shape.id == 3 || item.room.shape.id == 5) {
          shape = `${item.room.walls.wall_1} * ${item.room.walls.wall_2} * ${item.room.walls.wall_3} * ${item.room.walls.wall_4}*
          ${item.room.walls.wall_5} * ${item.room.walls.wall_6} * ${item.room.walls.wall_7}
           * ${item.room.walls.wall_8}
          
          `
        }
        else if (item.room.shape.id == 4) {
          shape = `${item.room.walls.wall_1} * ${item.room.walls.wall_2} * ${item.room.walls.wall_3} * ${item.room.walls.wall_4}*
          ${item.room.walls.wall_5} * ${item.room.walls.wall_6} `
        }
        qtyAfterWaste = qtyAfterWaste + parseFloat(item?.output?.quantity);
        cyclo = cyclo + parseFloat(item?.output?.cycalo_mt);
        roomArea = roomArea + parseFloat(item?.output?.before_waste)
        mywindow.document.write('<tr>')
        // mywindow.document.write('<td>'+item+'</td>')
        mywindow.document.write('<td>' + item.installation_start.name_ar + '</td>')
        mywindow.document.write('<td>' + item.installation_angle.name_ar + '</td>')

        mywindow.document.write('<td>' + item.tile_length + '*' + item.tile_width + '</td>')
        mywindow.document.write('<td>' + item.output?.cycalo_mt + '</td>')
        mywindow.document.write('<td>' + item.output?.quantity + '</td>')
        mywindow.document.write('<td>' + item.output?.before_waste || '' + '</td>')
        mywindow.document.write('<td>' + shape
          + '</td>')
        mywindow.document.write('<td>' + item.room.name + '</td>')
        mywindow.document.write('</tr>')

      }
      mywindow.document.write('<tr>')
      // mywindow.document.write('<td>'+item+'</td>')
      mywindow.document.write(' <td>' + '' + '</td>')
      mywindow.document.write(' <td>' + '' + '</td>')
      mywindow.document.write(' <td>' + '' + '</td>')

      mywindow.document.write(' <td>' +
        parseFloat(parseFloat(cyclo).toFixed(2))

        + '</td>')
      mywindow.document.write(' <td>' + parseFloat(parseFloat(qtyAfterWaste).toFixed(2)) + '</td>')
      mywindow.document.write(' <td>' + parseFloat(parseFloat(roomArea).toFixed(2)) + '</td>')
      mywindow.document.write(' <td>' + '' + '</td>')
      mywindow.document.write(' <td>' + 'المجموع' + '</td>')

      mywindow.document.write('</tr>')


      mywindow.document.write('</table>')
      mywindow.document.write('</div>')



    }


    //wall 

    if (props && props.walls && props.walls.length > 0) {
      mywindow.document.write('<div class="container">')

      mywindow.document.write('<h3>' + ' اجمالي الارضيات والجدران' + '</h3>');


      let wallsHeading = [
        'بداية التركيب',
        'زاوية التركيب',
        'طول وعرض البلاطة',
        'الكمية بعد الهدر',
        'الكمية قبل الهدر',

        'قياسات الغرفه',
        'اسم الغرفة',


      ]
      let floorsHeading = [
        'بداية التركيب',
        'زاوية التركيب',
        'طول وعرض البلاطة',
        'الارضيات بعد الهدر',
        'الارضيات قبل الهدر',



      ]
      let coloringHeading = [
        "المساحه م2",
        "التلوين"
      ]
      let beltsHeading = [
        "عدد الحبات المستخدمه",
        "طول وعرض البلاطه",
        ""
      ]

      let spacesHeading = [
        "المساحه بعد الهدر  ",
        "المساحه قبل الهدر  ",
        "طول وعرض البلاطه",
        "ارتفاع وعرض المساحه",
        "",
      ]
      let floorsHeadingTotal = [
        'السيكلو',

        'عدد الحبات المستخدمة',
        'طول و عرض البلاطة',
      ];


      for (let wall of props.walls) {
        let shape = '';
        if (wall.room.shape.id == 1 || wall.room.shape.id == 2) {
          shape = `${wall.room.walls.wall_1} * ${wall.room.walls.wall_2}`
        } else if (wall.room.shape.id == 3 || wall.room.shape.id == 5) {
          shape = `${wall.room.walls.wall_1} * ${wall.room.walls.wall_2} * ${wall.room.walls.wall_3} * ${wall.room.walls.wall_4}*
          ${wall.room.walls.wall_5} * ${wall.room.walls.wall_6} * ${wall.room.walls.wall_7}
           * ${wall.room.walls.wall_8}`
        } else if (wall.room.shape.id == 4) {
          shape = `${wall.room.walls.wall_1} * ${wall.room.walls.wall_2} * ${wall.room.walls.wall_3} * ${wall.room.walls.wall_4}*
          ${wall.room.walls.wall_5} * ${wall.room.walls.wall_6} `
        }

        mywindow.document.write('<div class="container">')
        mywindow.document.write('<h3>' + 'اجمالي الجدران' + '</h3>');

        mywindow.document.write('<table>')
        mywindow.document.write('<tr>')
        for (let head of wallsHeading) {
          mywindow.document.write('<th>' + head + '</th>')

        }
        mywindow.document.write('</tr>')
        mywindow.document.write('<tbody>')

        mywindow.document.write('<tr>')

        mywindow.document.write('<td>' + wall.wall_main_tile.installation_start.name_ar + '</td>')
        mywindow.document.write('<td>' + wall.wall_main_tile.installation_angle.name_ar + '</td>')
        mywindow.document.write('<td>' + wall.wall_main_tile.tile_length + "*" + wall.wall_main_tile.tile_width + '</td>')
        mywindow.document.write('<td>' + wall?.output?.wall_main_quantity_after_waste + '</td>')
        mywindow.document.write('<td>' + wall?.output?.wall_main_quantity + '</td>')
        mywindow.document.write('<td>' + shape + '</td>')
        mywindow.document.write('<td>' + wall.room.name + '</td>')



        mywindow.document.write('</tr>')
        mywindow.document.write('</tbody>')

        mywindow.document.write('</table>')


        if (wall.coloring.length > 0) {
          mywindow.document.write('<h3>' + ' التلوين' + '</h3>');
          mywindow.document.write('<h5>' + (wall.coloring_type == 'rowsOnly' ? 'تلوين : صفوف فقط   ' : wall.coloring_type == 'colsOnly' ? 'تلوين : اعمده فقط' : 'تلوين : حر') + '</h5>');


          mywindow.document.write('<table>')
          mywindow.document.write('<tr>')

          for (let head of coloringHeading) {
            mywindow.document.write('<th>' + head + '</th>')

          }
          mywindow.document.write('</tr>')
          mywindow.document.write('<tbody>')

          wall.coloring.forEach((color, i) => {

            mywindow.document.write('<tr>')
            mywindow.document.write('<td>' + color.num_of_tiles + '</td>')
            mywindow.document.write('<td>' + color.name + '</td>')
            mywindow.document.write('</tr>')
          });




          mywindow.document.write('</tbody>')

          mywindow.document.write('</table>')
        }



        mywindow.document.write('<h3>' + 'اجمالي الارضيات' + '</h3>');

        mywindow.document.write('<table>')
        mywindow.document.write('<tr>')
        for (let head of floorsHeading) {
          mywindow.document.write('<th>' + head + '</th>')

        }
        mywindow.document.write('</tr>')
        mywindow.document.write('<tbody>')
        // for (let head of wallsHeading) {
        mywindow.document.write('<td>' + wall.installation_start?.name_ar + '</td>')
        mywindow.document.write('<td>' + wall.installation_angle?.name_ar + '</td>')
        mywindow.document.write('<td>' + wall.floor_tile_length + "*" + wall.floor_tile_width + '</td>')
        mywindow.document.write('<td>' + wall?.output?.floor_quantity_after_waste + '</td>')
        mywindow.document.write('<td>' + wall?.output?.floor_quantity + '</td>')
        // mywindow.document.write('<td>' + "" + '</td>')
        // mywindow.document.write('<td>' + wall.room.name + '</td>')


        // }
        mywindow.document.write('</tr>')
        mywindow.document.write('</tbody>')

        mywindow.document.write('</table>')
        if (wall.wall_straps.length > 0) {
          mywindow.document.write('<h3>' + ' الاحزمه' + '</h3>');
          // mywindow.document.write('<h5>' + (wall.coloring_type == 'rowsOnly' ? 'تلوين : صفوف فقط   ' : wall.coloring_type == 'colsOnly' ? 'تلوين : اعمده فقط' : 'تلوين : حر') + '</h5>');


          mywindow.document.write('<table>')
          mywindow.document.write('<tr>')

          for (let head of beltsHeading) {
            mywindow.document.write('<th>' + head + '</th>')

          }
          mywindow.document.write('</tr>')
          mywindow.document.write('<tbody>')

          wall.wall_straps.forEach((strap, i) => {

            mywindow.document.write('<tr>')
            mywindow.document.write('<td>' + strap?.output + '</td>')

            mywindow.document.write('<td>' + strap.tile_length + '*' + strap.tile_width + '</td>')
            mywindow.document.write('<td>' + "حزام " + (i + 1) + '</td>')
            mywindow.document.write('</tr>')
          });




          mywindow.document.write('</tbody>')

          mywindow.document.write('</table>')
        }

        if (wall.wall_special_space.length > 0) {
          mywindow.document.write('<h3>' + ' المساحات الخاصه' + '</h3>');


          mywindow.document.write('<table>')
          mywindow.document.write('<tr>')

          for (let head of spacesHeading) {
            mywindow.document.write('<th>' + head + '</th>')

          }
          mywindow.document.write('</tr>')
          mywindow.document.write('<tbody>')
          // for (let  i of wall.coloring) {
          wall.wall_special_space.forEach((space, i) => {

            mywindow.document.write('<tr>')
            mywindow.document.write('<td>' + space.after_waste + '</td>')
            mywindow.document.write('<td>' + space.before_waste + '</td>')

            mywindow.document.write('<td>' + space.tile_length + '*' + space.tile_width + '</td>')
            mywindow.document.write('<td>' + space.length + '*' + space.width + '</td>')
            mywindow.document.write('<td>' + "مساحه " + (i + 1) + '</td>')
            mywindow.document.write('</tr>')
          });



          // }
          mywindow.document.write('</tbody>')

          mywindow.document.write('</table>')


        }
        mywindow.document.write('</div>')
        mywindow.document.write('<hr/>')


      }


      mywindow.document.write('</div>')




    }

    if (props && props.roomsWithHieght && props.roomsWithHieght.length > 0) {
      mywindow.document.write('<h3>' + 'اجمالي الجدران' + '</h3>');


      let roomsWithHieghtHeading = [
        // 'المحيط',
        'مساحة الجدران(م2)',
        'مساحة الارضيه(م2)',
        'مسمي الغرفة',
      ]

      mywindow.document.write('<table>')
      mywindow.document.write('<tr>')
      for (let head of roomsWithHieghtHeading) {
        mywindow.document.write('<th>' + head + '</th>')

      }
      mywindow.document.write('</tr>')

      let totWallAreaWith = 0;
      let totRoomAreaWith = 0
      for (let item of props.roomsWithHieght) {
        // totalQty = totalQty + item.output?.no_of_cycalos;
        // QtyMT = QtyMT + item.output?.quantity_mt;
        // QtyTiles = QtyTiles + item.output?.no_of_tiles;
        // QtyMsuqare = QtyMsuqare + item.output?.quantity_square;
        totRoomAreaWith = totRoomAreaWith + Number(item.floorArea);
        totWallAreaWith = totWallAreaWith + Number(item.wallArea)
        mywindow.document.write('<tr>')
        // mywindow.document.write('<td>'+item+'</td>')

        mywindow.document.write('<td>' + item.wallArea + '</td>')
        mywindow.document.write('<td>' + item.floorArea + '</td>')
        mywindow.document.write('<td>' + item.name + '</td>')
        mywindow.document.write('</tr>')

      }
      mywindow.document.write('<tr>')
      // mywindow.document.write('<td>'+item+'</td>')


      mywindow.document.write(' <td>' + totWallAreaWith + '</td>')
      mywindow.document.write(' <td>' + totRoomAreaWith + '</td>')
      mywindow.document.write(' <td>' + 'المجموع' + '</td>')

      mywindow.document.write('</tr>')


      mywindow.document.write('</table>')
      // mywindow.document.write('<h3>' + 'اجمالي البلاطات' + '</h3>');

      // mywindow.document.write('<table>')

      // mywindow.document.write('<tr>')
      // for (let head of cyclosHeadingTotal) {
      //   mywindow.document.write('<th>' + head + '</th>')

      // }
      // mywindow.document.write('</tr>')

      // for (let item of props.groupedCyclosTiles) {
      //   mywindow.document.write('<tr>')

      //   mywindow.document.write('  <td>' + item[1] + '</td>')
      //   mywindow.document.write('  <td>' + item[0] + '</td>')
      //   mywindow.document.write('</tr>')

      // }

      // mywindow.document.write('</table>')

    }

    if (props && props.roomsWithoutHieght && props.roomsWithoutHieght.length > 0) {
      mywindow.document.write('<h3>' + 'اجمالي الارضيات' + '</h3>');


      let roomsWithoutHieghtHeading = [
        'مساحة الارضيه (م2)',
        'مساحه السيكلو (م ط)',
        'مسمي الغرفة',
      ]

      mywindow.document.write('<table>')
      mywindow.document.write('<tr>')
      for (let head of roomsWithoutHieghtHeading) {
        mywindow.document.write('<th>' + head + '</th>')

      }
      mywindow.document.write('</tr>')

      let totCircWithout = 0;
      let totRoomAreaWithout = 0
      for (let item of props.roomsWithoutHieght) {
        // totalQty = totalQty + item.output?.no_of_cycalos;
        // QtyMT = QtyMT + item.output?.quantity_mt;
        // QtyTiles = QtyTiles + item.output?.no_of_tiles;
        // QtyMsuqare = QtyMsuqare + item.output?.quantity_square;
        totCircWithout = totCircWithout + Number(item.cyclo);
        totRoomAreaWithout = totRoomAreaWithout + Number(item.floorArea)
        mywindow.document.write('<tr>')
        // mywindow.document.write('<td>'+item+'</td>')

        mywindow.document.write('<td>' + item.floorArea + '</td>')
        mywindow.document.write('<td>' + item.cyclo + '</td>')
        mywindow.document.write('<td>' + item.name + '</td>')
        mywindow.document.write('</tr>')

      }
      mywindow.document.write('<tr>')
      // mywindow.document.write('<td>'+item+'</td>')


      mywindow.document.write(' <td>' + totRoomAreaWithout + '</td>')
      mywindow.document.write(' <td>' + totCircWithout + '</td>')
      mywindow.document.write(' <td>' + 'المجموع' + '</td>')

      mywindow.document.write('</tr>')


      mywindow.document.write('</table>')



    }
    mywindow.document.write(' <div class="divFooter">deemsmart.com</div>');
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    setTimeout(() => {

      mywindow.print();
      mywindow.close();
    }, 250)

    return true;
  }
  return (
    <>
      {/* <Printlogo onClick={handlePrint} /> */}
      <Printlogo onClick={print} />
    </>)
}
export default PrintComponent;