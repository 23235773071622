import React from 'react'
import { useHistory } from "react-router-dom";
import './header.scss'
import { Navbar, Nav, } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom'

import ClientSearch from '../../pages/landingPage/client-search/client-search';
import { ReactComponent as Img4 } from '../../assets/Page 1.svg';
import Dropdown from 'react-bootstrap/Dropdown';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import auth from '../../auth/auth';
const Header = (props) => {
    let history = useHistory();
    let location = useLocation();


    const createForm = () => {
        localStorage.removeItem('identifier')
        localStorage.removeItem('cyclos')
        localStorage.removeItem('walls')
        localStorage.removeItem('floors')
        localStorage.removeItem('id')
        history.push("/data/client-data");
    }

    const logoutHandler = () => {
        localStorage.clear();
        // localStorage.setItem('currentUser', 'client')
        history.push("/home");
    }

    const goToProfile = () => {
        history.push("/profile")
    }
    const handlePrint = () => {
        window.print()
    }

    return (
        // currentUser === 'client' 
        auth.isAuth() && (auth.getRole() == 'company_owner' || auth.getRole() == 'company_user') ?


            <div className={location.pathname.includes('home') ? 'header landing' : 'header'}>
                <div className="header-upper">
                    <Dropdown >
                        <Dropdown.Toggle variant="transparent" id="dropdown">
                            {/* <DropDownIcon /> */}
                            <i class="fa-solid fa-ellipsis-vertical"></i>
                            <FontAwesomeIcon icon={faEllipsisVertical} />
                            {/* <FontAwesomeIcon icon="fa-solid fa-ellipsis-stroke-vertical" /> */}
                            {/* <FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical" /> */}
                            {/* icon */}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => goToProfile()}>الصفحه الشخصيه</Dropdown.Item>
                            {/* <Dropdown.Item onClick={() => goToProfile()}>تغيير كلمه السر </Dropdown.Item> */}
                            <Dropdown.Item onClick={() => logoutHandler()}>تسجيل الخروج </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    {/* <Form.Control aria-label="Text input with dropdown button" /> */}


                    {/* <button onClick={() => logoutHandler()} className="logout">تسجيل الخروج</button> */}
                    <div className="header-upper-btns">
                        <Link className={location.pathname.includes('client-orders') ? "header-button active" : "header-button"} to="/client-orders">طلبات العملاء</Link>
                        <Link className={location.pathname.includes('data') ? "header-button active" : "header-button"} to="/data/client-data">حساب الخامات</Link>
                        <div style={{ textDecoration: "unset", color: "white", fontSize: "1.5rem", width: "36%" }} >
                            <a style={{ textDecoration: "none", color: "white" }}>
                                {/* <Logo className="logo" /> */}
                                {/* <span style={{ marginInlineStart: "5%" }}>Deem Smart</span> */}
                                <Img4 className="logo" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="header-lower">
                    {/* <div className="header-lower-ctrls">
                        <PrintLogo onClick={handlePrint} />
                        <Downlogo />
                        <input className="search-input" placeholder="رقم النموذج" value={searchVal} onChange={handleSearch} /><Search className="search-icon" />
                    </div> */}
                    <button onClick={() => { createForm() }}>انشاء نموذج</button>
                </div>
            </div >
            :
            auth.isAuth() && auth.getRole() == 'admin' ?
                <div className={location.pathname.includes('home') ? 'header landing' : 'header'}>
                    <div className="header-upper">
                        <Dropdown >
                            <Dropdown.Toggle variant="transparent" id="dropdown">
                                {/* <DropDownIcon /> */}
                                <i class="fa-solid fa-ellipsis-vertical"></i>
                                <FontAwesomeIcon icon={faEllipsisVertical} />
                                {/* <FontAwesomeIcon icon="fa-solid fa-ellipsis-stroke-vertical" /> */}
                                {/* <FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical" /> */}
                                {/* icon */}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => goToProfile()}>الصفحه الشخصيه</Dropdown.Item>
                                {/* <Dropdown.Item onClick={() => goToProfile()}>تغيير كلمه السر </Dropdown.Item> */}
                                <Dropdown.Item onClick={() => logoutHandler()}>تسجيل الخروج </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        {/* <button onClick={() => logoutHandler()} className="logout">تسجيل الخروج</button> */}

                        <div className="header-upper-btns">
                            <Link className={location.pathname.includes('clients') ? "header-button active" : "header-button"} to="/dashboard/clients"> العملاء</Link>
                            <Link className={location.pathname.includes('companies') ? "header-button active" : "header-button"} to="/dashboard/companies">الشركات </Link>
                            <div style={{ textDecoration: "unset", color: "white", fontSize: "1.5rem", width: "36%" }} >
                                <a style={{ textDecoration: "none", color: "white" }}>
                                    <Img4 className="logo" />

                                    {/* <Logo className="logo" /> */}
                                    {/* <span style={{ marginInlineStart: "5%" }}>Deem Smart</span> */}
                                </a>
                            </div>
                        </div>
                    </div>

                </div> :
                <Navbar bg="dark" expand="lg" >
                    <Navbar.Brand>
                        <Link to="/" style={{ textDecoration: "none" }}>
                            <div style={{ textDecoration: "unset", color: "white" }}>

                                <Img4 className="logo" />

                                {/* <Logo className="logo" />
                                <span style={{ marginInlineStart: "5%" }}>Deem Smart</span> */}
                            </div>
                        </Link>
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="nav">
                        {/* 
                        <NavDropdown title="العربية" id="basic-nav-dropdown">
                            <NavDropdown.Item href="#action/3.1">العربية</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.1">English</NavDropdown.Item>
                        </NavDropdown> */}
                        <Nav className="mr-auto">
                            <Nav.Link onClick={() => props.handleShow()}>تسجيل الدخول</Nav.Link>
                        </Nav>


                        <ClientSearch />
                    </Navbar.Collapse>
                </Navbar >
    )
}

export default Header;