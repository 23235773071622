import React, { useState } from 'react';
import './specialSpace.scss'
import SlabMeasure from '../slabMeasure/slabMeasure';
import InstallChoices from '../installChoices/installChoices';
import useSignUpForm from '../../hooks/useSignUpForm'
import { ReactComponent as Close } from '../../assets/close.svg';
import { ReactComponent as Width } from '../../assets/Group 3508.svg';
import { ReactComponent as Type } from '../../assets/Rectangle 1051.svg';
import { ReactComponent as Start } from '../../assets/Group 3520.svg';
import { ReactComponent as Half } from '../../assets/nos l 8orfa.svg';
import { ReactComponent as Width_45 } from '../../assets/45 3ardy.svg';
import { ReactComponent as Plus } from '../../assets/Icon feather-plus-circle.svg';

const SpecialSpace = ({
    // SpecialSpace,
    // handleSpecialSpaceInputChange,
    // tiles,
    // walls,
    // installationAngles,
    // installationStarts,
    // SpecialSpaceChoices,
    // handleInstallChoicesChange,
    // wallsToSpecialSpace,
    // addWallToSpecialSpace,
    // handleDimensions,
    // specialSpaceLength,
    // specialSpaceWidth

    specialSpace,
    specialSpaces,
    changeSpecialSpaceLength,
    changeSpecialSpaceWidth,
    changeSpecialSpaceName,
    changeSpecialSpaceTileLength,
    changeSpecialSpaceTileWidth,
    changeSpecialSpaceTileSpacing,
    // changeSpecialSpaceCount,
    changeSpecialSpaceAngle,
    changeSpecialSpaceStart,
    tiles,
    walls,
    deleteWallFromSpecialSpace,
    addWallToSpecialSpace,
    installationStarts,
    installationAngles,
    height,
    special_space_type

}) => {


    return (
        <div className="special-space">
            <div className="special-space-length">
                <br />
                {/* <button type="button" class="btn btn-secondary" data-toggle="tooltip" data-placement="left" title="Tooltip on left">
  Tooltip on left
</button> */}
                <span className="def">الارتفاع</span>
                <input type='number' name={specialSpace.id} min="0" value={specialSpace.specialSpaceLength} onChange={(e) => changeSpecialSpaceLength(e.target)} disabled={special_space_type == 'all_height'} />
                <span className="unit">متر</span>
                <br />
                {!specialSpace.specialSpaceLengthIsValid && specialSpace.specialSpaceLengthIsTouched && <span className="error-msg">
                    {specialSpace.specialSpaceLength < 1 ?
                        <span>
                            الارتفاع خطأ

                        </span>
                        :
                        <span>
                            تم تخطي الحد المسموح من  الارتفاعات
                        </span>
                    }
                </span>}

            </div>
            <div className="special-space-width">
                <span className="def">العرض</span>
                <input type='number' name={specialSpace.id} value={specialSpace.specialSpaceWidth} onChange={(e) => changeSpecialSpaceWidth(e.target)}
                    disabled={special_space_type == 'part_height'}
                />

                <span className="unit">متر</span>
                {!specialSpace.specialSpaceWidthIsValid && specialSpace.specialSpaceWidthIsTouched && <span className="error-msg">العرض خطأ</span>}

            </div>
            <SlabMeasure
                parent='specialSpace'
                specialSpace={specialSpace}
                changeSpecialSpaceName={changeSpecialSpaceName}
                changeSpecialSpaceTileLength={changeSpecialSpaceTileLength}
                changeSpecialSpaceTileWidth={changeSpecialSpaceTileWidth}
                changeSpecialSpaceTileSpacing={changeSpecialSpaceTileSpacing}

                // tile_name={specialSpace.tile_name}
                // tile_length={specialSpace.tile_length}
                // tile_lengthIsValid={specialSpace.tile_lengthIsValid}
                // tile_lengthIsTouched={specialSpace.tile_lengthIsTouched}
                // tile_width={specialSpace.tile_width}
                // tile_widthIsValid={SpecialSpace.tile_widthIsValid}
                // tile_widthIsTouched={SpecialSpace.tile_widthIsTouched}
                // tile_spacing={SpecialSpace.tile_spacing}
                // tile_spacingIsValid={SpecialSpace.tile_spacingIsValid}
                // tile_spacingIsTouched={SpecialSpace.tile_spacingIsTouched}
                tiles={tiles}
            // handleInputChange={handleSpecialSpaceInputChange} 
            />



            <InstallChoices
                parent='specialSpace'

                hideStackingMethod={true}
                specialSpace={specialSpace}
                specialSpaces={specialSpaces}

                installationAngles={installationAngles}
                installationStarts={installationStarts}
                changeSpecialSpaceAngle={changeSpecialSpaceAngle}
                changeSpecialSpaceStart={changeSpecialSpaceStart}
            // installation_start_id={SpecialSpaceChoices.installation_start_id}
            // installation_angle_id={SpecialSpaceChoices.installation_angle_id}
            // handleInputChange={handleInstallChoicesChange}
            // changeSpecialSpaceCount={changeSpecialSpaceCount}
            />
            <>
                <span>الجدران التي سيتم التركيب بها</span>
                <div style={{ display: "flex", flexDirection: "row-reverse", marginTop: "1rem" }} className='add-belt-walls'>
                    {
                        specialSpace && specialSpace.walls.map(wall => {
                            return (
                                <button className='close-button' disabled={special_space_type == "all_height"}>
                                    {special_space_type == "all_height" &&
                                        < Close className="close-btn" onClick={() => deleteWallFromSpecialSpace(specialSpace.id, wall)} />
                                    }

                                    {wall}</button>
                            )
                        })
                    }
                    <div className="wall-labels-btns">
                        {
                            special_space_type == "all_height" &&
                            < select onChange={(e) => addWallToSpecialSpace(specialSpace.id, e.target.value)}>
                                <option value={''} disabled={true} selected>اختر</option>

                                {
                                    walls.map((wall, index) => {
                                        return (
                                            <option key={wall.id}
                                                id={wall.id}
                                                value={wall.number}
                                                disabled={(specialSpace.walls).includes(String(wall.number)) || (specialSpace.walls).includes(wall.number)}
                                            >

                                                {wall.number}
                                            </option>
                                        );
                                    })}
                            </select>
                        }
                    </div>
                </div>
                {/* <div className="add-belt-choices-btns">
                    <span className="add-label">اضافة حزام</span>
                    <Plus />
                </div> */}
            </>
        </div >
    )
}
export default SpecialSpace;