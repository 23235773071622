import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import './login.scss';
import { Modal, Button } from "react-bootstrap";
import { ReactComponent as Close } from '../../assets/Icon ionic-ios-close-circle-outline.svg';
import axios from 'axios';
import { BASE_URL } from '../../constants/config';
import { ToastContainer, toast } from 'react-toastify';
import useSignUpForm from '../../hooks/useSignUpForm'
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from "react";
import { handleError } from "../../utilities/handleError";
import * as errorTypes from "../../utilities/errorTypes";

const Login = (props) => {
    let history = useHistory();
    const { handleInputChange, inputs, clearInputs } = useSignUpForm(
        {
            username: '',
            usernameIsValid: false,
            usernameIsTouched: false,
            password: '',
            passwordIsValid: false,
            passwordIsTouched: false
        }
    )

    const checkFormValidation = () => {
        return inputs.passwordIsValid && inputs.usernameIsValid;
    }

    const loginHandler = () => {
        axios.post(`${BASE_URL}/login`,
            {
                username: inputs.username,
                password: inputs.password
            }, {
            headers: {

                "Accept-Language": 'ar'
            }

        }).then((res) => {

            localStorage.clear()
            sessionStorage.clear()

            if (res.data.roles[0].name == 'company_owner' || res.data.roles[0].name == "company_user") {

                localStorage.setItem('currentUser', 'employee')
            }
            localStorage.setItem('company_id', res.data.company_id);
            localStorage.setItem('token', res.data.token);
            localStorage.setItem("role", res.data.roles[0].name)
            clearInputs();
            props.handleClose()
            if (res.data.roles[0].name == 'company_owner' || res.data.roles[0].name == "company_user") {

                history.push("/data/client-data");
            } else if (res.data.roles[0].name == 'admin') {
                history.push("/dashboard");

            }
        })
            .catch(error => {
                const errorResult = handleError(error)
                const errMsg = errorResult.message;
                toast.error(errMsg, {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                if (errorResult.type == errorTypes.unauthorized) {
                    setTimeout(() => {

                        localStorage.clear();
                        history.push("/home");
                    }, 1000);
                }
            })
    }

    const handleModalClose = () => {
        clearInputs();
        props.handleClose();
    }

    const [modalType, setModalType] = useState('login');
    return (
        <>
            <ToastContainer />
            <Modal show={props.show} onHide={props.handleClose} dialogClassName="login-modal">
                <Modal.Header>
                    <Modal.Title><Close className="close-icon" onClick={() => handleModalClose()} /><h4>{modalType === 'login' ? 'تسجيل الدخول' : 'إنشاء حساب'}</h4></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        modalType === 'login' ?
                            <div className="login">
                                <div className="login-username">
                                    <input name="username" value={inputs.username} onChange={(e) => handleInputChange(e)} />
                                    <span>اسم المستخدم </span>
                                    {!inputs.usernameIsValid && inputs.usernameIsTouched && <span className="error-msg"> اسم المستخدم خطأ</span>}
                                </div>
                                <div className="login-password">
                                    <input type="password" name="password" value={inputs.password} onChange={(e) => handleInputChange(e)} />
                                    <span>كلمة المرور</span>
                                </div>
                            </div>
                            :
                            // register section
                            <div className="login">
                                <div className="login-username">
                                    <input />
                                    <span>اسم الشركة</span>
                                </div>
                                <div className="login-email">
                                    <input />
                                    <span>رقم التليفون</span>
                                </div>
                                <div className="login-email">
                                    <input />
                                    <span>كلمة المرور</span>
                                </div>
                                <div className="login-password">
                                    <input />
                                    <span>اعادة كلمة المرور</span>
                                </div>
                            </div>
                    }

                    <Button disabled={!checkFormValidation()} onClick={() => loginHandler()}
                        className={checkFormValidation() ? "login-btn" : "login-btn disabled"}
                        variant="primary">{modalType === 'login' ? 'تسجيل الدخول' : 'إنشاء الحساب'}</Button>
                </Modal.Body>
                <Modal.Footer>
                    {/* {
                        modalType === 'login' ?
                            <span>ليس لديك حساب ؟ <Link to="" onClick={() => setModalType('reg')} className="">انشاء حساب</Link></span> :
                            <span>لديك حساب ؟<Link to="" onClick={() => setModalType('login')} className="">تسجيل الدخول</Link></span>
                    } */}
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default Login;