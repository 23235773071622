import React from 'react';
import './doors.scss';
import { ReactComponent as Plus } from '../../assets/Icon feather-plus-circle.svg';

const Doors = (props) => {
    const deleteDoor = (id) => {

        props.deleteDoor(id)
    }
    return (
        <div className="doors">
            <label>الأبواب</label>
            <div className="single-door">
                <div onClick={() => props.addDoor()} className="single-door-add-door">
                    <span className="add-label">اضافة مقاس باب جديد</span>
                    <Plus className="add-icon" />
                </div>
                <div style={{ width: "100%", direction: "rtl", marginRight: "1rem" }}>
                    {
                        props.doors.map(door => {
                            return (
                                <div key={door.id} className="single-door-details">
                                    <div style={{ width: "50%" }}>
                                        <span className="def">عدد الأبواب</span>
                                        <div style={{ display: "flex", flexDirection: "column", height: "66%", position: "relative" }}>
                                            <input
                                                type="number"
                                                onWheel={(e) => e.target.blur()}
                                                min="1"
                                                // placeholder='1'
                                                name={door.id}
                                                value={door.count}
                                                onChange={(e) => props.changeDoorCount(e.target)} />
                                            {!door.countValidation && <span className="error-msg">عدد الأبواب خطأ</span>}
                                        </div>
                                    </div>

                                    <div className="door-width" style={{ marginInlineStart: "1rem", display: "flex", flexDirection: "column" }}>

                                        <span className="def">عرض الباب</span>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", height: "160%", position: "relative" }}>
                                                <input
                                                    // placeholder='1'
                                                    type="number"
                                                    onWheel={(e) => e.target.blur()}
                                                    name={door.id}
                                                    value={door.width}
                                                    onChange={(e) => props.changeDoorValue(e.target)} />
                                                {!door.widthValidation && <span className="error-msg" style={{ position: "absolute", bottom: "0" }}> عرض الأبواب خطأ</span>}
                                            </div>
                                            <span className="unit">متر</span>
                                        </div>
                                    </div>
                                    <div>
                                        {props.doors.length > 1 && <button className='btn btn-danger' onClick={() => deleteDoor(door.id)}>X</button>
                                        } </div>
                                </div>

                            )
                        })
                    }
                </div>
            </div>
            {/* {
                props.parentComponent !== 'cyclo' &&
                                        <div className="obstacles">
                                            <label>عوائق</label>
                                            <div className="single-obstacle">
                                                <div className="single-obstacle-add-obstacle">
                                                    <Plus className="add-icon" />
                                                    <span className="add-label">اضافة عائق</span>
                                                </div>
                                                <div className="single-obstacle-details">
                                                    <div className="single-obstacle-details-width">
                                                        <span className="def">عرض الوحدة</span>
                                                        <input />
                                                        <span className="unit">متر</span>
                                                    </div>
                                                    <div className="single-obstacle-details-length">
                                                        <span className="def">طول الوحدة</span>
                                                        <input />
                                                        <span className="unit">متر</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
            } */}
        </div >
    )
}
export default Doors;