import React, { useState } from 'react';
import './addBelt.scss';
import SlabMeasure from '../slabMeasure/slabMeasure';
import InstallChoices from '../installChoices/installChoices';
import { ReactComponent as WidthShape } from '../../assets/Group 3501.svg';
import { ReactComponent as Plus } from '../../assets/Icon feather-plus-circle.svg';
import { ReactComponent as Close } from '../../assets/close.svg';
import { ReactComponent as Width } from '../../assets/Group 3508.svg';



const AddBelt = ({
    belt,
    // handleBeltInputChange,
    addWallToBelt,
    wallsToBelt,
    tiles,
    walls,
    addBeltChoices,
    installationAngles,

    installationStarts,
    installationStyles,
    handleInstallChoicesChange,
    changeBeltName,
    changeBeltTileLength,
    changeBeltTileWidth,
    changeBeltTileSpacing,
    changeBeltCount,
    changeBeltStyle,
    deleteWallFromBelt
    // , deleteWallFromBelt
    // addNewBelt,
    , belts
}) => {

    return (
        <div className="add-belt">
            {/* <div onClick={() => props.addDoor()} className="single-door-add-door">
                    <span className="add-label">اضافة مقاس باب جديد</span>
                    <Plus className="add-icon" />
                </div> */}
            {/* {belts && belts.map(beltObj => { */}
            {/* <div key={beltObj.id}> */}
            <>
                {/* <h1>{beltObj.id}</h1> */}
                <SlabMeasure
                    parent='belt'
                    tile_name={belt.tile_name}
                    tile_length={belt.tile_length}
                    tile_lengthIsValid={belt.tile_lengthIsValid}
                    tile_lengthIsTouched={belt.tile_lengthIsTouched}
                    tile_width={belt.tile_width}
                    tile_widthIsValid={belt.tile_widthIsValid}
                    tile_widthIsTouched={belt.tile_widthIsTouched}
                    tile_spacing={belt.tile_spacing}
                    tile_spacingIsValid={belt.tile_spacingIsValid}
                    tile_spacingIsTouched={belt.tile_spacingIsTouched}
                    tiles={tiles}
                    // handleInputChange={handleBeltInputChange}
                    changeBeltName={changeBeltName}
                    changeBeltTileLength={changeBeltTileLength}
                    changeBeltTileWidth={changeBeltTileWidth}
                    changeBeltTileSpacing={changeBeltTileSpacing}
                    belt={belt}
                />

                <InstallChoices
                    parent='belt'
                    hideStackingMethod={false}
                    // installationAngles={installationAngles}
                    // installationStarts={installationStarts}
                    installationStyles={installationStyles}
                    // installation_start_id={addBeltChoices.installation_start_id}
                    // installation_angle_id={addBeltChoices.installation_angle_id}
                    // installation_style_id={addBeltChoices.installation_style_id}
                    // beltsCount={addBeltChoices.beltsCount}
                    // handleInputChange={handleInstallChoicesChange}
                    belt={belt}
                    changeBeltCount={changeBeltCount}
                    changeBeltStyle={changeBeltStyle}
                    belts={belts}
                />

                <span>الجدران التي سيتم التركيب بها</span>
                <br />
                <div style={{ display: "flex", flexDirection: "row-reverse", marginTop: "1rem" }} className='add-belt-walls'>
                    {
                        belt && belt.length > 0 && belt.wals.map(wall => {
                            return (
                                <button className='close-button'><Close className="close-btn" />{wall.number}</button>
                            )
                        })
                    }
                    {/* <div className="wall-labels-btns">
                        <select onChange={(e) => addWallToBelt(e.target.value)}>
                            {
                                walls.map((wall, index) => {
                                    return (
                                        <option id={wall.id} value={wall.number}>
                                            {wall.number}
                                        </option>
                                    );
                                })}
                        </select>
                    </div> */}
                </div>
                {belt.installation_style_id == 2 && "سيتم التركيب في جميع الجدران"}
                <div style={{ display: "flex", flexDirection: "row-reverse", marginTop: "1rem" }} className='add-belt-walls'>
                    {
                        belt.walls.map(wall => {
                            return (
                                <button className='close-button'>
                                    {belt.installation_style_id == 1 && <Close className="close-btn"
                                        onClick={() => deleteWallFromBelt(belt.id, wall)}
                                    />}
                                    {wall}</button>
                            )
                        })
                    }
                    <div className="wall-labels-btns">

                        {belt.installation_style_id == 1 &&
                            <select onChange={(e) => addWallToBelt(belt.id, e.target.value)}>
                                <option value={''} disabled={true} selected>اختر</option>

                                {
                                    walls.map((wall, index) => {

                                        return (

                                            <option key={wall.id} id={wall.id} value={wall.number}
                                                disabled={(belt.walls).includes(String(wall.number))}
                                            >
                                                {wall.number}
                                            </option>
                                        );
                                    })}
                            </select>}
                    </div>
                </div>
            </>

            {/* })} */}




        </div>
    )
}
export default AddBelt;