import React from 'react';
import "./terms.scss"
const TermsComponent = (props) => {
    let terms=['تتعارض مع صورة ديم سمارت العامة أو النية الحسنة أو السمعة.',
        'الترويج لأي نشاط أو محتوى غير قانوني، بما في ذلك على سبيل المثال لا الحصر إساءة معاملة الأطفال أو الحيوانات والعنف والاستخدام غير المشروع للمخدرات والشرب دون السن القانونية.'
       ,'تنتهك حقوق الطبع والنشر أو براءات الاختراع أو العلامات التجارية أو الأسرار التجارية أو غيرها من حقوق الملكية أو حقوق الدعاية أو الخصوصية الخاصة بنا أو بشركات أخري.'
    ,'عدم مشاركة صريحة أو ضمنية أيًا من بياناتك أو أنشطتك أو أسبابك قد أقرناها، دون موافقتنا الخطية المسبقة في كل حالة.'
    ,'انتهاك أي قانون أو قانون أو مرسوم أو لائحة معمول بها في البلاد، أو تشجيع أي سلوك يمكن أن يشكل جريمة جنائية أو ينشأ عنه مسؤولية مدنية.'
  ,'التشهير أو تشهيرية أو تهديدية أو مضايقة أو اقتحام للخصوصية أو مسيئة أو مؤذية أو كراهية أو تمييزية أو إباحية أو فاحشة.'
    ,'إرسال أي سر تجاري أو معلومات أخرى أو معلومات غير عامة عن أي شخص أو شركة أو كيان دون الحصول على إذن بذلك.'
    ,'تقييد أو منع أي زائر آخر من استخدام منصة ديم سمارت ، بما في ذلك، على سبيل المثال لا الحصر، عن طريق "اختراق" أو تشويه أي جزء من منصة ديم سمارت أو تعديل، تتغيير، ترخيص فرعي، ترجمة، بيع، هندسة عكسية، إلغاء ترجمة، أو فك أي جزء من منصة ديم سمارت'
    ,' إزالة أي حقوق نشر أو علامة تجارية أو غيرها من إشعارات حقوق الملكية الواردة في منصة ديم سمارت.'
    ,'الباطن أو البيع أو الإيجار أو التأجير أو النقل أو التنازل عن أو نقل أي حقوق بموجب الشروط إلى أي طرف ثالث، أو الاستغلال التجاري أو الربح بطريقة أخرى من المعلومات أو محتوى منصة ديم سمارت ، أو أي جزء منها، بأي شكل من الأشكال، باستثناء ما هو مسموح به صراحة هنا.'
    ,'"طباعه" أو "نسخ" أي جزء من منصة ديم سمارت دون إذن خطي مسبق منا.'
    ,'توزيع أي فيروس أو فيروس متنقل أو أي ملفات أو نصوص برمجية أو برامج مشابهة أو ضارة.'
   ,'التدخل مع أو تعطل أي خدمات أو معدات بقصد التسبب في حمل زائد أو غير متناسب على البنية التحتية لشركة ديم سمارت أو الجهات المرخصة لها أو مورديها.'
    ,'تنطوي على إعداد أو توزيع البريد غير المرغوب فيه أو الرسائل غير المرغوب فيها أو سلسلة الرسائل أو المخططات الهرمية أو غيرها من رسائل البريد الإلكتروني التجارية الضخمة أو غير المرغوب فيها أو تنتهك بأي شكل من الأشكال قانون مكافحة الاعتداء على المواد الإباحية والتسويق غير المسموح بها (كان سبام أكت ٢٠٠٣) أو ما يعادلها، المعمول بها، القانون الأجنبي.'
    ,'استخدام أي روبوت، أو شبكة، أو تطبيق للبحث / استرجاع الموقع، أو أي جهاز أو عملية أخرى يدوية أو أوتوماتيكية لاسترداد أو فهرسة "مخزن البيانات" في منصة ديم سمارت أو إعادة إنتاج أو التحايل على أي شكل من الأشكال الهيكل الملاحي أو العرض التقديمي لمنصة ديم سمارت أو المحتويات والمصادقة والتدابير الأمنية.'
    ,'تزوير العناوين أو الهويات أو التلاعب بمعرفات الهوية من أجل إخفاء أصل أي إرسال.'
    ,'تنفيذ أي شكل من أشكال مراقبة الشبكة أو تشغيل محلل شبكة أو حزمة بحث أو تقنية أخرى لاعتراض أو فك شفرة أو استخراج أو عرض أي حزم تستخدم للاتصال بين خوادم ديم سمارت للنظام الأساسي أو أي بيانات غير مخصصة لك ، الصورة. حصاد أو جمع معلومات حول أي زائر أو أعضاء من منصة ديم سمارت دون موافقتهم الصريحة؛ و / أو'
    ,'يحتوي على أي إشارة إلى أو تشبه أي أطراف ثالثة محددة، ما لم يتم الحصول على موافقة من جميع هؤلاء الأفراد وأولياء أمورهم / الوصي القانوني إذا كانوا تحت سن الرشد حسب قانون الدولة..'
    ]
    return(<>
    <div className='container terms' dir="rtl">

        <h4 className='heading'> سياسة الاستخدام </h4>
        <p className='desc'>
            كجزء من سياسة الاستخدام المقبول لدى ديم سمارت، فإنك توافق على عدم استخدام المعلومات أو الخدمات أو أي جزء آخر من منصة ديم سمارت لاتخاذ أي إجراء أو إجراءات من شأنها:
        </p>
        <ol>
            {terms&&terms.map((el,i)=><li key={i}>{el}</li>)}
            )}


        </ol>
    </div>
    </>)
}

export default TermsComponent;
