import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import './editFloor.scss';
import { ReactComponent as Shape5 } from '../../../assets/Path 4717.svg';
import { ReactComponent as Shape4 } from '../../../assets/Path 4715.svg';
import { ReactComponent as Shape3 } from '../../../assets/Path 4716.svg';
import { ReactComponent as Shape2 } from '../../../assets/Rectangle 1056.svg';
import { ReactComponent as Shape1 } from '../../../assets/Rectangle 1048.svg';
import { ReactComponent as Shape55 } from '../../../assets/Group 3634.svg';
import { ReactComponent as Shape44 } from '../../../assets/Group 3635.svg';
import { ReactComponent as Shape33 } from '../../../assets/8l.svg';

import { ReactComponent as Shape22 } from '../../../assets/Group 3636.svg';
import { ReactComponent as Close } from '../../../assets/Icon ionic-ios-close-circle-outline.svg';
import InstallChoices from '../../../components/installChoices/installChoices';
import SlabMeasure from '../../../components/slabMeasure/slabMeasure';
import * as actionCreators from '../../../store/actions/actionCreators';
import * as basicFn from '../../../store/actions/basicFn';
import { useSelector, useDispatch } from 'react-redux';
import useSignUpForm from '../../../hooks/useSignUpForm'
import axios from 'axios';
import { BASE_URL } from '../../../constants/config'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom'
import { handleError } from '../../../utilities/handleError';
import * as errorTypes from "../../../utilities/errorTypes"
var doorId = 0;
var floorId = 0;


const EditFloor = (props) => {
    let history = useHistory()
    const handleModalClose = () => {
        props.handleClose();
    }

    let params = useParams();

    const currentFloors = useSelector(state => state.floorReducer.floors);
    const dispatch = useDispatch();
    const [tiles, setTiles] = useState([]);
    const [savedBtnStatus, setSavedBtnStatus] = useState({ id: null, saved: false });

    const { handleInputChange, inputs, clearInputs, setInputsValues } = useSignUpForm(
        {
            name: '',
            nameIsValid: true,
            nameIsTouched: true,
            height: '',
            heightIsValid: true,
            heightIsTouched: true,
            // tile_name: "",
            // tile_nameIsValid: false,
            // tile_nameIsTouched: false,
            // tile_name_text: "",
            tile_length: '',
            tile_lengthIsValid: true,
            tile_lengthIsTouched: true,
            tile_width: '',
            tile_widthIsValid: true,
            tile_widthIsTouched: true,
            tile_spacing: '',
            tile_spacingIsValid: true,
            tile_spacingIsTouched: true,
            cycalo_height: '',
            cycalo_heightIsValid: true,
            cycalo_heightIsTouched: true,
            cutting_style_id: 1,
            cutting_style_idIsValid: true,
            cutting_style_idIsTouched: true,
            installation_angle_id: 1,
            installation_angle_idIsValid: true,
            installation_angle_idIsTouched: true,
            installation_start_id: 1,
            installation_start_idIsValid: true,
            installation_start_idIsTouched: true,
        }
    )

    const clearForm = () => {
        setWalls([])
        setSelectedShapeComponent(<Shape1 />)
        setSelectedShapeName('Shape1')
        clearInputs()
    }

    const clearResults = () => {
        setFloorResult({
            quantity: 0,
            cycalo_mt: 0,
            waste: 0,
            before_waste: 0
        })
    }

    const deleteFloor = (e, id) => {
        e.stopPropagation();
        dispatch(actionCreators.deleteFloor(id))
        clearForm();
        clearResults();
    }

    const getShape = (shapeName) => {
        switch (shapeName) {
            case 'Shape1':
                return <Shape1 />

            case 'Shape2':
                return <Shape22 />

            case 'Shape3':
                return <Shape33 />

            case 'Shape4':
                return <Shape44 />

            case 'Shape5':
                return <Shape55 />
        }
    }

    const getFloor = () => {

        let floorToBeFound = props.data.find(singleFloor => singleFloor.id === props.id)

        setSavedBtnStatus({ id: floorToBeFound.id, saved: floorToBeFound.output.saved })
        let wallsResult = [];
        if (floorToBeFound.room.shape) {
            Object.keys(floorToBeFound.room.walls).map(key => {
                if (key.includes('wall_')) {
                    wallsResult.push({ [`number`]: key.substr(5, 1), ['length']: floorToBeFound.room.walls[key] })
                }
            })
            wallsResult = wallsResult.slice(0, floorToBeFound.room.shape.number_of_walls);
        }
        setWalls(wallsResult)
        setSelectedShapeName(floorToBeFound.room.shape ? floorToBeFound.room.shape.name : floorToBeFound.selectedShapeName)
        setSelectedShapeComponent(floorToBeFound.room.shape ? getShape(floorToBeFound.room.shape.name) : floorToBeFound.selectedShapeComponent)
        setInputsValues({
            name: floorToBeFound.room.name,
            nameIsValid: true,
            nameIsTouched: true,
            height: floorToBeFound.room.height,
            heightIsValid: true,
            heightIsTouched: true,
            tile_length: floorToBeFound.tile_length ? floorToBeFound.tile_length : '',
            tile_lengthIsValid: true,
            tile_lengthIsTouched: false,
            tile_width: floorToBeFound.tile_width ? floorToBeFound.tile_width : '',
            tile_widthIsValid: true,
            tile_widthIsTouched: false,
            // tile_spacing: floorToBeFound.tile_spacing ? floorToBeFound.tile_spacing : '',
            tile_spacing: floorToBeFound.tile_spacing,
            tile_spacingIsValid: true,
            tile_spacingIsTouched: false,
            cycalo_height: floorToBeFound.cycalo_height,
            cycalo_heightIsValid: true,
            cycalo_heightIsTouched: true,
            cutting_style_id: floorToBeFound.cutting_style_id ? floorToBeFound.cutting_style_id : 1,
            cutting_style_idIsValid: true,
            cutting_style_idIsTouched: false,
            installation_angle_id: floorToBeFound.installation_angle_id ? floorToBeFound.installation_angle_id : 1,
            installation_angle_idIsValid: true,
            installation_angle_idIsTouched: false,
            installation_start_id: floorToBeFound.installation_start_id ? floorToBeFound.installation_start_id : 1,
            installation_start_idIsValid: true,
            installation_start_idIsTouched: false
        })
        setFloorResult(floorToBeFound.output)
    }

    useEffect(() => {
        if (params.clientId) {
            axios.get(`${BASE_URL}/buyer/${params.clientId}/rooms`, {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    "Accept-Language": 'ar'

                }
            }).then(res => {
                localStorage.setItem('id', res.data.id)
                dispatch(actionCreators.setFloors(res.data.rooms))
            })
                .catch(error => {
                    const errorResult = handleError(error)
                    const errMsg = errorResult.message;
                    toast.error(errMsg, {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (errorResult.type == errorTypes.unauthorized) {
                        setTimeout(() => {

                            localStorage.clear();
                            history.push("/home");
                        }, 1000);
                    }

                })
        }
        const cuttingStyle = basicFn.getCutting_style();
        if (!cuttingStyle) {

            axios.get(`${BASE_URL}/cutting_style`, {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    "Accept-Language": 'ar'

                }
            }).then(res => {
                basicFn.setCutting_style(res.data)
                setCutTypes(res.data)
            })
                .catch(error => {
                    const errorResult = handleError(error)
                    const errMsg = errorResult.message;
                    toast.error(errMsg, {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (errorResult.type == errorTypes.unauthorized) {
                        setTimeout(() => {

                            localStorage.clear();
                            history.push("/home");
                        }, 1000);
                    }

                })
        } else {
            setCutTypes(basicFn.getCutting_style())
        }
        const tilesArr = basicFn.getTiles();
        if (!tilesArr) {

            axios.get(`${BASE_URL}/tile?company_id=${localStorage.getItem('company_id')}`, {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    "Accept-Language": 'ar'

                }
            }).then(res => {
                setTiles(res.data)
                basicFn.setTiles(res.data)
            })
                .catch(error => {
                    const errorResult = handleError(error)
                    const errMsg = errorResult.message;
                    toast.error(errMsg, {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (errorResult.type == errorTypes.unauthorized) {
                        setTimeout(() => {

                            localStorage.clear();
                            history.push("/home");
                        }, 1000);
                    }

                })
        } else {
            setTiles(tilesArr)
        }

        const angles = basicFn.getInstlationAngle()
        if (!angles) {

            axios.get(`${BASE_URL}/installation_angle`, {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    "Accept-Language": 'ar'

                }
            }).then(res => {
                basicFn.setInstlationAngle(res.data)
                setInstallationAngles(res.data)
            })
                .catch(error => {
                    const errorResult = handleError(error)
                    const errMsg = errorResult.message;
                    toast.error(errMsg, {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (errorResult.type == errorTypes.unauthorized) {
                        setTimeout(() => {

                            localStorage.clear();
                            history.push("/home");
                        }, 1000);
                    }

                })
        } else {
            setInstallationAngles(angles)
        }

        const starts = basicFn.getInstlationStart()
        if (!starts) {

            axios.get(`${BASE_URL}/installation_start`, {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    "Accept-Language": 'ar'

                }
            }).then(res => {
                basicFn.setInstlationStart(res.data)
                setInstallationStarts(res.data)
            })
                .catch(error => {
                    const errorResult = handleError(error)
                    const errMsg = errorResult.message;
                    toast.error(errMsg, {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (errorResult.type == errorTypes.unauthorized) {
                        setTimeout(() => {

                            localStorage.clear();
                            history.push("/home");
                        }, 1000);
                    }

                })
        } else {
            setInstallationStarts(starts)

        }

    }, []);

    const doorUniqueId = () => {
        doorId += 1
        return doorId;
    }

    const floorUniqueId = () => {
        floorId += 1
        return floorId;
    }

    const [cutTypes, setCutTypes] = useState([]);
    const [installationAngles, setInstallationAngles] = useState([]);
    const [installationStarts, setInstallationStarts] = useState([]);
    const [floorResult, setFloorResult] = useState({
        quantity: 0,
        cycalo_mt: 0,
        waste: 0
    });

    // walls section
    const [walls, setWalls] = useState([]);
    const [selectedShapeComponent, setSelectedShapeComponent] = useState(<Shape1 />);
    const [selectedShapeName, setSelectedShapeName] = useState('Shape1');


    useEffect(() => {
        setSelectedShape('Shape1', <Shape1 />, 4)
    }, []);

    const checkWallLengthValidation = () => {
        let isValid = walls.every(el => el.length > 0)

        return isValid
    }
    const changeWallLength = (target) => {


        let wallsCopy = JSON.parse(JSON.stringify(walls))


        let index = wallsCopy.findIndex(wall => wall.number == parseInt(target.name))

        wallsCopy[index]['length'] = parseFloat(target.value);

        switch (selectedShapeName) {

            case 'Shape5':


                wallsCopy[0].length = Number.parseFloat(wallsCopy[2].length + wallsCopy[4].length + wallsCopy[6].length).toFixed(2)
                break;
            case 'Shape4':
                wallsCopy[0].length = Number.parseFloat(wallsCopy[2].length + wallsCopy[4].length).toFixed(2);
                wallsCopy[5].length = Number.parseFloat(wallsCopy[1].length + wallsCopy[3].length).toFixed(2);
                break;
            case 'Shape3':
                wallsCopy[0].length = Number.parseFloat(wallsCopy[2].length + wallsCopy[4].length + wallsCopy[6].length).toFixed(2);
                wallsCopy[5].length = wallsCopy[3].length
                wallsCopy[7].length = wallsCopy[1].length
                break;
            case 'Shape2':
                wallsCopy[2].length = wallsCopy[0].length;
                wallsCopy[3].length = wallsCopy[1].length
                break;
            case 'Shape1':
                wallsCopy[1].length = wallsCopy[0].length;
                wallsCopy[2].length = wallsCopy[0].length
                wallsCopy[3].length = wallsCopy[0].length
                break;
            default:
                break;
        }
        setWalls(wallsCopy)
    }

    const setDisabledEdges = (edgeNumber) => {
        let res = false
        if (selectedShapeName === 'Shape5' && edgeNumber === 1) {
            res = true;
        }
        else if (selectedShapeName === 'Shape4' && (edgeNumber === 1 || edgeNumber === 6)) {
            res = true
        }
        else if (selectedShapeName === 'Shape3' && (edgeNumber === 1 || edgeNumber === 6 || edgeNumber === 8)) {
            res = true
        }
        else if (selectedShapeName === 'Shape2' && (edgeNumber === 3 || edgeNumber === 4)) {
            res = true;
        }
        else if (selectedShapeName === 'Shape1' && (edgeNumber === 2 || edgeNumber === 3 || edgeNumber === 4)) {
            res = true;
        }
        else {
            res = false
        }
        return res;
    }

    const setSelectedShape = (name, component, values) => {
        let obj = [];
        for (let index = 0; index < values; index++) {
            const element = {
                number: index + 1,
                length: ""
            }
            obj.push(element)
        }
        setWalls(obj)
        setSelectedShapeComponent(component)
        setSelectedShapeName(name)
    }

    const saveHandler = ((saveInputs) => {
        let wallsObj = {};
        walls.map(wall => {
            wallsObj = Object.assign(wallsObj, { [`wall_${wall.number}`]: wall.length })
        })
        axios.put(BASE_URL + `/floor/${props.id}`,

            {
                buyer_id: localStorage.getItem('id'),
                save_inputs: saveInputs,
                room: {
                    name: inputs.name,
                    height: '9',
                    shape: selectedShapeName,
                    walls: wallsObj,
                    windows: [
                        {
                            "width": 1.0,
                            "height": 1.0,
                            "count": 2
                        }
                    ]
                },
                tile_length: inputs.tile_length,
                tile_width: inputs.tile_width,
                tile_spacing: inputs.tile_spacing,
                cycalo_height: inputs.cycalo_height,
                cutting_style_id: inputs.cutting_style_id,
                installation_angle_id: inputs.installation_angle_id,
                installation_start_id: inputs.installation_start_id,
            },
            {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    "Accept-Language": 'ar'

                }
            }
        ).then(res => {
            toast.success('تم الحفظ', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

        }).catch(error => {
            const errorResult = handleError(error)
            const errMsg = errorResult.message;
            toast.error(errMsg, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            if (errorResult.type == errorTypes.unauthorized) {
                setTimeout(() => {

                    localStorage.clear();
                    history.push("/home");
                }, 1000);
            }

        })
    })

    const checkFormValidation = () => {

        return checkWallLengthValidation() &&

            inputs.nameIsValid &&
            // inputs.heightIsValid &&
            // (inputs.tile_nameIsValid || inputs.tile_name_text !== '') &&
            inputs.tile_lengthIsValid &&
            inputs.tile_widthIsValid &&
            inputs.tile_spacingIsValid
        // inputs.cycalo_heightIsValid &&
        // inputs.cutting_style_idIsValid &&
        // inputs.installation_angle_idIsValid &&
        // inputs.installation_start_idIsValid
    }

    return (
        <Modal show={props.show} onShow={() => getFloor()} dialogClassName="floor-edit modal-lg">
            <ToastContainer />
            <Modal.Header>
                <Modal.Title>
                    <Close className="close-icon" onClick={() => handleModalClose()} />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="floor-edit-container">
                    <div className="section">
                        {/* <div className="floor-labels">
                        </div> */}
                        <div className="floor-edit">
                            <label style={{ "textAlign": "right" }}>حساب كمية البلاط</label>
                            <div className="floor-name">
                                <div style={{ display: "flex", flexDirection: "column", width: "84%", position: "relative" }}>
                                    <input
                                        className="floor-name-input"
                                        name="name"
                                        value={inputs.name}
                                        onChange={(e) => handleInputChange(e)} />
                                    {!inputs.nameIsValid && inputs.nameIsTouched && <span className="error-msg">اسم الغرفة خطأ</span>}
                                </div>
                                <span className="def">اسم الغرفة</span>
                            </div>
                            <div className="floor-shape">
                                <Shape5 onClick={() => setSelectedShape('Shape5', <Shape55 />, 8)} className={selectedShapeName === 'Shape5' ? "shape selected" : "shape"} />
                                <Shape4 onClick={() => setSelectedShape('Shape4', <Shape44 />, 6)} className={selectedShapeName === 'Shape4' ? "shape selected" : "shape"} />
                                <Shape3 onClick={() => setSelectedShape('Shape3', <Shape33 />, 8)} className={selectedShapeName === 'Shape3' ? "shape selected" : "shape"} />
                                <Shape2 onClick={() => setSelectedShape('Shape2', <Shape22 />, 4)} className={selectedShapeName === 'Shape2' ? "shape selected" : "shape"} />
                                <Shape1 onClick={() => setSelectedShape('Shape1', <Shape1 />, 4)} className={selectedShapeName === 'Shape1' ? "shape selected" : "shape"} />
                                <span className="def"> اختيار شكل الغرفة</span>
                            </div>
                            <div className="floor-details">
                                <div className="floor-details-selected-shape">
                                    {selectedShapeComponent}
                                </div>
                                <div className="floor-details-selected-measure">
                                    <label>قياسات الغرفة</label>
                                    {/* {!checkWallLengthValidation() && <span className="error-msg">طول الضلع خطأ</span>
                                    } */}
                                    {
                                        walls.map(wall => {
                                            return (
                                                <div key={wall.number} className="floor-side">
                                                    <ToastContainer />
                                                    <span className="def">ضلع {wall.number}</span>
                                                    <input

                                                        min={0}
                                                        disabled={setDisabledEdges(wall.number)}
                                                        type="number"
                                                        onWheel={(e) => e.target.blur()}
                                                        name={wall.number}
                                                        value={wall.length}
                                                        onChange={(e) => changeWallLength(e.target)} />
                                                    <span className="unit">متر</span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <SlabMeasure
                            parent='floor'
                            // tile_name={inputs.tile_name}
                            // tile_name_text={inputs.tile_name_text}
                            tile_length={inputs.tile_length}
                            tile_lengthIsValid={inputs.tile_lengthIsValid}
                            tile_lengthIsTouched={inputs.tile_lengthIsTouched}
                            tile_width={inputs.tile_width}
                            tile_widthIsValid={inputs.tile_widthIsValid}
                            tile_widthIsTouched={inputs.tile_widthIsTouched}
                            tile_spacing={inputs.tile_spacing}
                            tile_spacingIsValid={inputs.tile_spacingIsValid}
                            tile_spacingIsTouched={inputs.tile_spacingIsTouched}
                            cycalo_height={inputs.cycalo_height}
                            cutting_style_id={inputs.cutting_style_id}
                            cutTypes={cutTypes}
                            tiles={tiles}
                            handleInputChange={handleInputChange}
                        />
                        <InstallChoices
                            hideStackingMethod={true}
                            installationAngles={installationAngles}
                            installationStarts={installationStarts}
                            installation_start_id={inputs.installation_start_id}
                            installation_angle_id={inputs.installation_angle_id}
                            handleInputChange={handleInputChange}
                        />
                        <div className="floor-btns row">
                            <div className="floor-btns-main col-7">
                                <button
                                    disabled={!checkFormValidation()}
                                    onClick={() => saveHandler(false)}
                                    className={checkFormValidation() ? "calc" : "calc disabled"} >احسب</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )

}
export default EditFloor;