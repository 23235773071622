import * as actionTypes from '../actions/actionTypes';
import * as actions from '../actions/actionCreators';

const initialState = {
    floors: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SETFLOORS:
            return {
                floors: action.floors
            }

        case actionTypes.GETFLOOR:
            return [...state.floors].find(floor => floor.id === action.floorId);

        // case actionTypes.ADDFLOOR:
        //     // let floorsCopy = [...state.floors];
        
        //     // floorsCopy.push(action.floor)
        //     actions.addFloor(action.floor)
        //     return {
        //         ...state,
        //         // floors: floorsCopy
        //     }

        case actionTypes.EDITFLOOR:
         
            let floorsCopyEdit = [...state.floors];
            floorsCopyEdit.push(action.floor)
           
           
            
            return {
                ...state,
                floors: floorsCopyEdit
            }

        case actionTypes.DELETEFLOOR:
            let floorsCopyDelete = [...state.floors];
            let toBeDeletedFloorIndex = floorsCopyDelete.findIndex(floor => floor.id === action.floorId)
            floorsCopyDelete.splice(toBeDeletedFloorIndex, 1)
            if (actions.getFloorsFromStorage()) {
                let floorsCopyEditFromStorage = [...actions.getFloorsFromStorage()];
                let newFloorsCopyEditFromStorage = floorsCopyEditFromStorage.filter(el => el.id != action.floor.id)
                
                localStorage.setItem("floors", JSON.stringify(newFloorsCopyEditFromStorage))
            }
            return {
                ...state,
                floors: floorsCopyDelete
            }
        default:
            return state;
    }
}
export default reducer;