import * as actionTypes from '../actions/actionTypes';

const initialState = {
    rooms: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GETROOM:
            return [...state.rooms].find(room => room.id === action.roomId);

        case actionTypes.ADDROOM:
            let roomsCopy = [...state.rooms];
            roomsCopy.push(action.room)
            return {
                ...state,
                rooms: roomsCopy
            }

        case actionTypes.DELETEROOM:
            let roomsCopyDelete = [...state.rooms];
            let toBeDeletedRoomIndex = roomsCopyDelete.findIndex(room => room.id == action.roomId)
            roomsCopyDelete.splice(toBeDeletedRoomIndex, 1)
            return {
                ...state,
                rooms: roomsCopyDelete
            }

        default:
            return state;
    }
}
export default reducer;