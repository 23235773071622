import React from 'react';
import "./return-refund-policy.scss"
const ReturnRefundPolicyComponent = (props) => {
    return(<>
    <div>
        <div className='container policy' dir="rtl">

            <h4 className='heading'>  سياسة الإرجاع </h4>
            <p className='desc bold-600'>
                استمتع بفترة تجريبية مع سياسة الإرجاع التالية:
            </p>
            <p className='desc'>

                 لديك
               <span className='bold-800'>
                   15
               </span>
                  يومًا لتجربة حساب مجاني على الباقة الاساسية لمنصة ديم سمارت -
                <br/>

                اذا انقضت الفترة التجربيبة عليك الانتقال الى الصفحة الشخصية و اختيار الباقات المناسبة لشركتك
               <br/>

            </p>
            <p className='desc bold-600'>
                لا يوجد استرجاع إلا في حالة حدوث خلل داخلي بمنصة ديم سمارت لمدة لاتقل عن 60 يوم ،
                <br/>
                بالإمكان الإرجاع للقيمة المتبقية من المدة خلال مدة اقصاها 20 يوم عمل من خلال التقدم بطلب إرجاع عبر الإيميل .

            </p>

        </div>
    </div>
    </>)
}

export default ReturnRefundPolicyComponent;
