import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "./auth";

export const ProtectedRouteAdminUser = ({
  component: Component,
  ...rest
}) => {

  return (
    <Route
      {...rest}
      render={props => {
        if (auth.isAuth() && (auth.getRole() == 'company_owner' || auth.getRole() == 'company_user' || auth.getRole() == 'admin')) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
};
