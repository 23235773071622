class Auth {
  constructor() {
    this.auth = false
  }
  isAuth() {
    let token = localStorage.getItem("token")
    if (token) {
      this.auth = true
    } else {
      this.auth = false

    }
    return this.auth
  }
  getRole() {
    let role = localStorage.getItem("role");
  
    return role
  }
}
export default new Auth();