import React from 'react';
import './slabMeasure.scss';
import { ReactComponent as Width } from '../../assets/Group 3508.svg';
import { ReactComponent as Length } from '../../assets/Rectangle 1051.svg';

const SlabMeasure = ({

    parent,
    tile_name,
    tile_name_text,
    tile_length,
    tile_lengthIsValid,
    tile_lengthIsTouched,
    tile_width,
    tile_widthIsValid,
    tile_widthIsTouched,
    tile_spacing,
    tile_spacingIsValid,
    tile_spacingIsTouched,
    cycalo_height,
    cutting_style_id,
    cutTypes,
    tiles,
    handleInputChange,
    changeBeltName,
    changeBeltTileLength,
    changeBeltTileWidth,
    changeBeltTileSpacing,
    belt,
    //special space
    specialSpace,
    changeSpecialSpaceName,
    changeSpecialSpaceTileLength,
    changeSpecialSpaceTileWidth,
    changeSpecialSpaceTileSpacing
}) => {

    return (
        <div className="slab-container">
            <label>مقاس البلاطة</label>
            <div className="slab-measure">
                {
                    parent &&
                    parent === 'mainSlab' &&

                    <div className="slab-measure-tile-name" style={{ display: "none" }}>
                        <span>مسمي البلاطه</span>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            {/* <input style={{ marginInlineStart: "2rem", marginBottom: "1rem" }} name="tile_name_text" value={tile_name_text}
                                onChange={(e) => handleInputChange(e)} placeholder="مسمي البلاطة" /> */}
                            {/* <input type="text" name='tile_name' onChange={(e) => handleInputChange(e)} value={tile_name} /> */}
                            <select name="tile_name" value={tile_name} onChange={(e) => handleInputChange(e)} className="select">
                                <option value={''}>اختر</option>
                                {
                                    tiles.map(tile => {
                                        return (
                                            <option key={tile.id}>{tile.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                }
                {
                    belt && parent &&
                    parent === 'belt' &&

                    <>
                        <div className="slab-measure-tile-name" style={{ display: "none" }}>
                            <span>مسمي البلاطه</span>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                              
                                <select name={belt.id} value={belt.tile_name} onChange={(e) => changeBeltName(e.target)} className="select">
                                    <option value={''}>اختر</option>
                                   
                                    {
                                        tiles && tiles.map(tile => {
                                            return (
                                                <option value={tile.name} key={tile.id}>{tile.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                {!belt.tile_nameIsValid && belt.tile_nameIsTouched && <span className="error-msg">'مسمي البلاطة خطأ'</span>}

                            </div>
                        </div>
                        <div className="slab-measure-tile-length">
                            <span>طول البلاطة</span>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginInlineStart: "2.5rem" }}>
                                <div style={{ display: "flex", flexDirection: "column", position: "relative" }}>
                                    <input type='number' name={belt.id} value={belt.tile_length} onChange={(e) => changeBeltTileLength(e.target)} />
                                    {!belt.tile_lengthIsValid && belt.tile_lengthIsTouched && <span className="error-msg">'طول البلاطة خطأ'</span>}
                                </div>
                                <span className="unit">متر</span>
                            </div>
                        </div>

                        <div className="slab-measure-tile-width">
                            <span>عرض البلاطة</span>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginInlineStart: "2.5rem" }}>
                                <div style={{ display: "flex", flexDirection: "column", position: "relative" }}>
                                    <input type='number' name={belt.id} value={belt.tile_width} onChange={(e) => changeBeltTileWidth(e.target)} />
                                    {!belt.tile_widthIsValid && belt.tile_widthIsTouched && <span className="error-msg">'عرض البلاطة خطأ'</span>}
                                </div>
                                <span className="unit">متر</span>
                            </div>
                        </div>
                        <div className="slab-measure-tile-spacing">
                            <span>مسافة الفواصل</span>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginInlineStart: "1rem" }}>
                                <div style={{ display: "flex", flexDirection: "column", position: "relative" }}>
                                    <input type='number' name={belt.id} value={belt.tile_spacing} onChange={(e) => changeBeltTileSpacing(e.target)} />
                                    {!belt.tile_spacingIsValid && belt.tile_spacingIsTouched && <span className="error-msg">مسافة الفواصل خطأ'</span>}
                                </div>
                                <span className="unit">ملم</span>
                            </div>
                        </div>
                    </>
                }

                {
                    specialSpace && parent &&
                    parent === 'specialSpace' &&

                    <>
                        <div className="slab-measure-tile-name" style={{ display: "none" }}>
                            <span>مسمي البلاطه</span>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                            
                                <select name={specialSpace.id} value={specialSpace.tile_name} onChange={(e) => changeSpecialSpaceName(e.target)} className="select">
                                    <option value={''}>اختر</option>
                                
                                    {
                                        tiles && tiles.map(tile => {
                                            return (
                                                <option value={tile.name} key={tile.id}>{tile.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                {!specialSpace.tile_nameIsValid && specialSpace.tile_nameIsTouched && <span className="error-msg">'مسمي البلاطة خطأ'</span>}

                            </div>
                        </div>
                        <div className="slab-measure-tile-length">
                            <span>طول البلاطة</span>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginInlineStart: "2.5rem" }}>
                                <div style={{ display: "flex", flexDirection: "column", position: "relative" }}>
                                    <input type='number' name={specialSpace.id} value={specialSpace.tile_length} onChange={(e) => changeSpecialSpaceTileLength(e.target)} />
                                    {!specialSpace.tile_lengthIsValid && specialSpace.tile_lengthIsTouched && <span className="error-msg">'طول البلاطة خطأ'</span>}
                                </div>
                                <span className="unit">متر</span>
                            </div>
                        </div>

                        <div className="slab-measure-tile-width">
                            <span>عرض البلاطة</span>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginInlineStart: "2.5rem" }}>
                                <div style={{ display: "flex", flexDirection: "column", position: "relative" }}>
                                    <input type='number' name={specialSpace.id} value={specialSpace.tile_width} onChange={(e) => changeSpecialSpaceTileWidth(e.target)} />
                                    {!specialSpace.tile_widthIsValid && specialSpace.tile_widthIsTouched && <span className="error-msg">'عرض البلاطة خطأ'</span>}
                                </div>
                                <span className="unit">متر</span>
                            </div>
                        </div>
                        <div className="slab-measure-tile-spacing">
                            <span>مسافة الفواصل</span>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginInlineStart: "1rem" }}>
                                <div style={{ display: "flex", flexDirection: "column", position: "relative" }}>
                                    <input type='number' name={specialSpace.id} value={specialSpace.tile_spacing} onChange={(e) => changeSpecialSpaceTileSpacing(e.target)} />
                                    {!specialSpace.tile_spacingIsValid && specialSpace.tile_spacingIsTouched && <span className="error-msg">مسافة الفواصل خطأ'</span>}
                                </div>
                                <span className="unit">ملم</span>
                            </div>
                        </div>
                    </>
                }
                {
                    (parent != 'belt' && parent != 'specialSpace')

                    &&
                    <>
                        <div className="slab-measure-tile-length">
                            <span>طول البلاطة</span>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginInlineStart: "2.5rem" }}>
                                <div style={{ display: "flex", flexDirection: "column", position: "relative" }}>
                                    <input name="tile_length" value={tile_length} onChange={(e) => handleInputChange(e)} />
                                    {!tile_lengthIsValid && tile_lengthIsTouched && <span className="error-msg">'طول البلاطة خطأ'</span>}
                                </div>
                                <span className="unit">متر</span>
                            </div>
                        </div>

                        <div className="slab-measure-tile-width">
                            
                            <span>عرض البلاطة</span>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginInlineStart: "2.5rem" }}>
                                <div style={{ display: "flex", flexDirection: "column", position: "relative" }}>
                                    <input name="tile_width" value={tile_width} onChange={(e) => handleInputChange(e)} />
                                    {!tile_widthIsValid && tile_widthIsTouched && <span className="error-msg">'عرض البلاطة خطأ'</span>}
                                </div>
                                <span className="unit">متر</span>
                            </div>
                        </div>
                        <div className="slab-measure-tile-spacing">
                            <span>مسافة الفواصل</span>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginInlineStart: "1rem" }}>
                                <div style={{ display: "flex", flexDirection: "column", position: "relative" }}>
                                    <input name="tile_spacing" value={tile_spacing} onChange={(e) => handleInputChange(e)} />
                                    {!tile_spacingIsValid && tile_spacingIsTouched && <span className="error-msg">مسافة الفواصل خطأ'</span>}
                                </div>
                                <span className="unit">ملم</span>
                            </div>
                        </div>
                    </>
                }
                {/* <div className="slab-measure-tile-width">
                    <span>عرض البلاطة</span>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginInlineStart: "2.5rem" }}>
                        <div style={{ display: "flex", flexDirection: "column", position: "relative" }}>
                            <input name="tile_width" value={tile_width} onChange={(e) => handleInputChange(e)} />
                            {!tile_widthIsValid && tile_widthIsTouched && <span className="error-msg">'عرض البلاطة خطأ'</span>}
                        </div>
                        <span className="unit">متر</span>
                    </div>
                </div> */}

            </div>
            {
                parent &&
                parent !== 'floor' &&
                parent !== 'mainSlab' &&
                parent !== 'belt' &&
                parent !== "specialSpace" &&
                <>
                    <label>مقاس وحدة السيكلو</label>
                    <div className="cyclo-measure">
                        <div className="cyclo-measure-labels">
                            <span className="def">ارتفاع الوحدة</span>
                            <span className="def">طريقة القص</span>
                        </div>
                        <div className="cyclo-measure-inputs">
                            <div>
                                <input name="cycalo_height" value={cycalo_height} onChange={(e) => handleInputChange(e)} />
                                {/* <span className="unit">متر</span> */}
                            </div>
                            <div className="cyclo-measure-inputs--fig">
                                <select onChange={(e) => handleInputChange(e)} name="cutting_style_id" value={cutting_style_id} className="select">
                                    {
                                        cutTypes && cutTypes.Length > 0 && cutTypes.map(type => {
                                            return (
                                                <option value={type.id} key={type.id}>{type.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                {
                                    parseInt(cutting_style_id) === 1 ?
                                        <Width className="fig" /> :
                                        <Length className="fig" />
                                }
                            </div>
                            {/* <input /> */}
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default SlabMeasure;