import React, { useState, useEffect } from 'react';
import './cyclo.scss';
import * as actionCreators from '../../store/actions/actionCreators';
import * as basicFn from '../../store/actions/basicFn';
import { ReactComponent as Close } from '../../assets/close.svg';
import Doors from '../../components/doors/doors';
import useSignUpForm from '../../hooks/useSignUpForm'
import axios from 'axios';
import { BASE_URL } from '../../constants/config'
import { ReactComponent as Width } from '../../assets/Group 3508.svg';
import { ReactComponent as Length } from '../../assets/tall.svg';
import validate from '../../utilities/validation';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom'
import { handleError } from '../../utilities/handleError';
import * as errorTypes from "../../utilities/errorTypes";

var doorId = 0;
var cycloId = 0;

const Cyclo = () => {
    let history = useHistory();
    const doorUniqueId = () => {
        doorId += 1
        return doorId;
    }

    const cycloUniqueId = () => {
        cycloId += 1
        return cycloId;
    }

    const [localCyclos, setLocalCyclos] = useState([])
    const [currCyclos, setCurrCyclos] = useState([])

    const { handleInputChange, inputs, clearInputs, setInputsValues } = useSignUpForm(
        {
            tile_length: '',
            tile_lengthIsValid: false,
            tile_lengthIsTouched: false,
            tile_width: '',
            tile_widthIsValid: false,
            tile_widthIsTouched: false,
            cutting_style_id: '1',
            cutting_style_idIsValid: true,
            cutting_style_idIsTouched: false,
            cycalo_height: '',
            cycalo_heightIsValid: false,
            cycalo_heightIsTouched: false,
            quantity: '',
            quantityIsValid: false,
            quantityIsTouched: false
        }
    )

    const [doors, setDoors] = useState([
        {
            id: doorUniqueId(),
            width: 1,
            // width: '',
            count: 1,
            widthValidation: true,
            countValidation: true,
        }
    ]);

    const [cutTypes, setCutTypes] = useState([]);
    const [savedBtnStatus, setSavedBtnStatus] = useState({ id: null, saved: false, calculated: false });
    const [cycloResults, setCycloResults] = useState({
        no_of_tiles: "",
        no_of_cycalos: "",
        no_of_cycalos_per_tile: "",
        quantity_mt: "",
        quantity_square: "",
        waste: ""
    });

    const addDoor = () => {
        // let doorsCopy = [...doors]
        let doorsCopy = JSON.parse(JSON.stringify(doors))

        doorsCopy.push({
            id: doorUniqueId(), width: 1, count: 1, widthValidation: true, countValidation: true,
        })
        setDoors(doorsCopy)
    }
    const deleteDoor = (id) => {
        let doorsCopy = JSON.parse(JSON.stringify(doors))
        let filteredDoors = doorsCopy.filter(el => el.id != id)
        setDoors(filteredDoors)

    }

    const getCyclos = () => {
        const arr = []
        axios.get(`${BASE_URL}/buyer/${localStorage.getItem('identifier')}/cycalo_calculations`, {
            headers: {
                "Accept": "application/json",
                Authorization: `Bearer ${localStorage.getItem('token')}`,

                "Accept-Language": 'ar'

            }
        }).then(res => {


            // getTilesFromCyclos(res?.data)
            setCurrCyclos(res.data)
        }).catch(error => {
            const errorResult = handleError(error)
            const errMsg = errorResult.message;
            toast.error(errMsg, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            if (errorResult.type == errorTypes.unauthorized) {
                setTimeout(() => {

                    localStorage.clear();
                    history.push("/home");
                }, 1000);
            }

        })
    }
    const changeDoorValue = (target) => {

        let doorsCopy = JSON.parse(JSON.stringify(doors))
        doorsCopy[doorsCopy.findIndex(door => door.id === parseInt(target.name))].widthValidation = validate('doorsWidth', target.value);
        doorsCopy[doorsCopy.findIndex(door => door.id === parseInt(target.name))].width = parseFloat(target.value);
        setDoors(doorsCopy)
    }

    const changeDoorCount = (target) => {
        let doorsCopy = JSON.parse(JSON.stringify(doors))
        doorsCopy[doorsCopy.findIndex(door => door.id === parseInt(target.name))].countValidation = validate('doorsCount', target.value);
        doorsCopy[doorsCopy.findIndex(door => door.id === parseInt(target.name))].count = parseFloat(target.value);
        setDoors(doorsCopy)
    }

    const clearForm = () => {
        setDoors([{
            id: doorUniqueId(),
            width: 1,
            count: 1,
            widthValidation: true,
            countValidation: true
        }])
        setCycloResults({

            no_of_tiles: "",
            no_of_cycalos: "",
            no_of_cycalos_per_tile: "",
            quantity_mt: "",
            quantity_square: "",
            waste: "",
        })
        clearInputs();
    }

    const clearResults = () => {
        setCycloResults(
            {
                no_of_tiles: "",
                no_of_cycalos: "",
                no_of_cycalos_per_tile: "",
                quantity_mt: "",
                quantity_square: "",
                waste: "",
                // before_waste: 0
            }
        )
    }

    const deleteCyclo = (e, index) => {
        e.stopPropagation();

        let curr = [...localCyclos]
        curr.splice(index, 1);
        setLocalCyclos(curr);
        actionCreators.setCyclosToStorage(curr)
        clearForm();
        clearResults();
    }

    const getCyclo = (id) => {
        let cycloToBeFound = localCyclos.find(singleCyclo => singleCyclo.id === id)
        setSavedBtnStatus({ id: cycloToBeFound.id, saved: cycloToBeFound.output.saved, calculated: cycloToBeFound.output.calculated })
        setDoors(cycloToBeFound.doors)
        setInputsValues({
            tile_length: cycloToBeFound.tile_length,
            tile_lengthIsValid: true,
            tile_lengthIsTouched: true,
            tile_width: cycloToBeFound.tile_width,
            tile_widthIsValid: true,
            tile_widthIsTouched: true,
            cutting_style_id: cycloToBeFound.cutting_style_id,
            cutting_style_idIsValid: true,
            cutting_style_idIsTouched: true,
            cycalo_height: cycloToBeFound.cycalo_height,
            cycalo_heightIsValid: true,
            cycalo_heightIsTouched: true,
            quantity: cycloToBeFound.quantity,
            quantityIsValid: true,
            quantityIsTouched: true
        })
        setCycloResults(cycloToBeFound.output)
    }

    const saveHandler = ((saveInputs) => {

        axios.post(BASE_URL + '/cycalo/calculate',
            {
                save_inputs: saveInputs,
                quantity: inputs.quantity,
                tile_length: inputs.tile_length,
                tile_width: inputs.tile_width,
                cycalo_height: inputs.cycalo_height,
                cutting_style_id: inputs.cutting_style_id,
                buyer_id: localStorage.getItem('id'),
                doors: doors.map(({ id, ...item }) => item),
            },
            {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    "Accept-Language": 'ar'

                }
            })
            .then(res => {
                if (!saveInputs) {

                    setCycloResults(res.data.output)
                    setSavedBtnStatus({ id: res.data.id, saved: false, calculated: true })
                    let curr = [...localCyclos,
                    {
                        id: res.data.id,
                        name: `كمية${cycloUniqueId()}`,
                        quantity: inputs.quantity,
                        tile_length: inputs.tile_length,
                        tile_width: inputs.tile_width,
                        cycalo_height: inputs.cycalo_height,
                        cutting_style_id: inputs.cutting_style_id,
                        buyer_id: localStorage.getItem('id'),
                        doors: doors,
                        // output: res.data.output
                        output: { ...res.data.output, saved: false, calculated: true }
                    }
                    ]
                    setLocalCyclos(curr)

                    actionCreators.setCyclosToStorage(curr)
                    toast.success('تمت عملية الحساب', {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                else {


                    let curr = localCyclos.slice();
                    let index = localCyclos.findIndex(el => el.id == savedBtnStatus.id);
                    setCurrCyclos([...currCyclos, {

                        id: res.data.id,
                        name: localCyclos[index].name,
                        quantity: inputs.quantity,
                        tile_length: inputs.tile_length,
                        tile_width: inputs.tile_width,
                        cycalo_height: inputs.cycalo_height,
                        cutting_style_id: inputs.cutting_style_id,
                        buyer_id: localStorage.getItem('id'),
                        doors: doors,
                        // output: res.data.output
                        output: { ...res.data.output, saved: true, calculated: true }
                    }])
                    curr.splice(index, 1);
                    setLocalCyclos(curr);
                    actionCreators.setCyclosToStorage(curr)


                    setSavedBtnStatus({ id: null, saved: false, calculated: false })

                    clearForm();

                    toast.success('تم الحفظ', {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }).catch(error => {
                const errorResult = handleError(error)
                const errMsg = errorResult.message;
                toast.error(errMsg, {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                if (errorResult.type == errorTypes.unauthorized) {
                    setTimeout(() => {

                        localStorage.clear();
                        history.push("/home");
                    }, 1000);
                }

            })
    })

    const checkFormValidation = () => {
        return inputs.tile_lengthIsValid &&
            inputs.tile_widthIsValid &&
            inputs.cutting_style_idIsValid &&
            inputs.cycalo_heightIsValid &&
            inputs.quantityIsValid &&
            doors.map(door => door.widthValidation && door.countValidation)[0];
    }

    useEffect(() => {
        getCyclos()
        if (localStorage.getItem("identifier")) {
            // setUserId(localStorage.getItem("identifier"));
            let allCyclos = []

            if (actionCreators.getCyclosFromStorage() && actionCreators.getCyclosFromStorage().length >= 1) {
                let firstCyclo = actionCreators?.getCyclosFromStorage()[0]

                if (firstCyclo) {

                    if (firstCyclo.buyer_id == localStorage.getItem("id")) {
                        allCyclos = [...actionCreators.getCyclosFromStorage()]
                        setLocalCyclos([...actionCreators.getCyclosFromStorage()])
                    } else {

                        localStorage.setItem("cyclos", [])
                    }
                }
            }
            // dispatch(actionCreators.setCyclos(allCyclos))


        }
        const cuttingStyle = basicFn.getCutting_style();
        if (!cuttingStyle) {

            axios.get(`${BASE_URL}/cutting_style`, {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    "Accept-Language": 'ar'

                }
            }).then(res => {
                basicFn.setCutting_style(res.data)
                setCutTypes(res.data)
            })
                .catch(error => {
                    const errorResult = handleError(error)
                    const errMsg = errorResult.message;
                    toast.error(errMsg, {
                        position: "top-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (errorResult.type == errorTypes.unauthorized) {
                        setTimeout(() => {

                            localStorage.clear();
                            history.push("/home");
                        }, 1000);
                    }

                })
        } else {
            setCutTypes(basicFn.getCutting_style())
        }
    }, []);
    const getCurrentCyclo = (id) => {
        let item = currCyclos.find(el => el.id == id);
        setSavedBtnStatus({ id: item.id, saved: item.output.saved, calculated: item.output.calculated })
        setDoors(item.doors.map(el => ({
            ...el, widthValidation: true,
            countValidation: true,
        })))
        setInputsValues({
            tile_length: item.tile_length,
            tile_lengthIsValid: true,
            tile_lengthIsTouched: true,
            tile_width: item.tile_width,
            tile_widthIsValid: true,
            tile_widthIsTouched: true,
            cutting_style_id: item.cutting_style_id,
            cutting_style_idIsValid: true,
            cutting_style_idIsTouched: true,
            cycalo_height: item.cycalo_height,
            cycalo_heightIsValid: true,
            cycalo_heightIsTouched: true,
            quantity: item.quantity,
            quantityIsValid: true,
            quantityIsTouched: true
        })
        setCycloResults(item.output)
    }
    const getNumber = (str) => {
        var matches = str.match(/(\d+)/);


        return +matches[0];

    }
    return (
        <div className="cyclo-container">
            <ToastContainer />
            <div className="cyclo">
                <div className="cyclo-labels">
                    <div>
                        <span className="def">نتائج الحسابات للسيكلو</span>

                    </div>
                    {
                        currCyclos && currCyclos.length > 0 &&
                        <div className="cyclo-labels-btns ">
                            {
                                currCyclos.map((singleCyclo, i) => {
                                    return (
                                        <button
                                            className={`${singleCyclo.id == savedBtnStatus.id ? "saved-btn active" : "saved-btn"}`}

                                            onClick={() => getCurrentCyclo(singleCyclo.id)}
                                            key={singleCyclo.id}>
                                            {"كميه  " + (i + 1)}
                                            {/* {singleCyclo.name} */}
                                        </button>
                                    )
                                })
                            }
                        </div>
                    }
                    {
                        localCyclos && localCyclos.length > 0 &&
                        <div className="cyclo-labels-btns ">
                            {
                                localCyclos.map((singleCyclo, i) => {
                                    return (
                                        <button
                                            className={`${singleCyclo.id == savedBtnStatus.id ? ' not-saved-btn active' : "not-saved-btn"}`}
                                            onClick={() => getCyclo(singleCyclo.id)}
                                            key={singleCyclo.id}>
                                            <Close onClick={(e) => deleteCyclo(e, i)} className="close-btn" />{singleCyclo.name}</button>
                                    )
                                })
                            }
                        </div>
                    }
                    <label>حساب كمية السيكلو</label>
                </div>
                <div className="quantity-doors-container" style={{ backgroundColor: "white" }}>
                    <div className="quantity">
                        <span className="def">كمية السيكلو</span>
                        <input
                            name="quantity"
                            value={inputs.quantity}
                            onChange={(e) => handleInputChange(e)} />

                        <span style={{ marginInlineEnd: "1rem" }} className="unit">  متر طولي</span>
                        {!inputs.quantityIsValid && inputs.quantityIsTouched && <span className="error-msg"> الكمية خطأ</span>}
                    </div>
                    <Doors
                        doors={doors}
                        addDoor={addDoor}
                        deleteDoor={deleteDoor}

                        changeDoorValue={changeDoorValue}
                        changeDoorCount={changeDoorCount}
                        parentComponent={'cyclo'}
                    />
                </div>
                <div className="slab-measure">
                    <label>مقاس البلاطة</label>
                    <div className="slab-measure-length">
                        <span className="def">طول الوحدة</span>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <input
                                value={inputs.tile_length}
                                name="tile_length"
                                onChange={(e) => handleInputChange(e)} />
                            {!inputs.tile_lengthIsValid && inputs.tile_lengthIsTouched && <span className="error-msg">طول البلاطة خطأ</span>}
                        </div>
                        <span className="unit">متر</span>
                    </div>
                    <div className="slab-measure-width">
                        <span className="def">عرض الوحدة</span>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <input
                                value={inputs.tile_width}
                                name="tile_width"
                                onChange={(e) => handleInputChange(e)} />
                            {!inputs.tile_widthIsValid && inputs.tile_widthIsTouched && <span className="error-msg">عرض البلاطة خطأ</span>}
                        </div>
                        <span className="unit">متر</span>
                    </div>
                    <div className="slab-measure-cut-type">
                        <span className="def">طريقة القص</span>
                        <select
                            className="select"
                            name="cutting_style_id"
                            value={inputs.cutting_style_id}
                            onChange={(e) => handleInputChange(e)} >
                            {
                                cutTypes.length > 0 &&
                                cutTypes.map(type => {
                                    return (
                                        <option value={type.id} key={type.id}>{type.name_ar}</option>
                                    )
                                })
                            }
                        </select>
                        {
                            parseInt(inputs.cutting_style_id) === 1 ?
                                <Length className="fig" />
                                :
                                <Width className="fig" />
                        }
                        {/* <span className="unit">متر</span> */}
                    </div>
                    <div className="cyclo-measure">
                        <label>مقاس وحدة السيكلو</label>
                        <div className="cyclo-measure-height">
                            <span className="def">ارتفاع الوحدة</span>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <input
                                    value={inputs.cycalo_height}
                                    name="cycalo_height"
                                    onChange={(e) => handleInputChange(e)} />
                                {!inputs.cycalo_heightIsValid && inputs.cycalo_heightIsTouched && <span className="error-msg">ارتفاع السيكلو خطأ</span>}
                            </div>
                            <span className="unit">متر</span>
                        </div>
                    </div>
                </div>
                <div className="cyclo-wanted-btns">
                    <button
                        onClick={() => clearForm()}
                        className="cyclo-wanted-btns-clear">مسح البيانات</button>
                    <button onClick={() => saveHandler(false)}
                        disabled={!checkFormValidation()}
                        className={checkFormValidation() ? "cyclo-wanted-btns-calc" : "cyclo-wanted-btns-calc disabled"}>احسب</button>
                </div>
            </div>
            <div className="cyclo-wanted">
                <label>الكمية المطلوبة من السيكلو</label>
                <div className="cyclo-wanted-section">
                    <div className="cyclo-wanted-section-labels">
                        {/* <span className="def">الكمية قبل الهدر</span> */}
                        <span className="def">الكمية المطلوبة من البلاطات</span>
                        <span className="def">عدد وحدات السيكلو</span>
                        <span className="def">عدد الوحدات من البلاطة الوحدة</span>
                        <span className="def">  الكمية متر طولي</span>
                        <span className="def">الكمية متر مربع</span>
                        <span className="def">نسبة الهدر</span>
                    </div>
                    <div className="cyclo-wanted-section-inputs">
                        {/* <div>
                            <input disabled={true} value={cycloResults.before_waste} />
                        </div> */}
                        <div>
                            <span style={{ marginInlineEnd: "1rem" }} className="unit">بلاطة</span>
                            <input disabled={true} value={cycloResults.no_of_tiles} />
                        </div>
                        <div>
                            <span style={{ marginInlineEnd: "1rem" }} className="unit">وحدة</span>
                            <input disabled={true} value={cycloResults.no_of_cycalos} />
                        </div>
                        <div>
                            <span style={{ marginInlineEnd: "1rem" }} className="unit">وحدة</span>
                            <input disabled={true} value={cycloResults.no_of_cycalos_per_tile} />
                        </div>
                        <div>
                            <span style={{ marginInlineEnd: "1rem" }} className="unit">(م ط)</span>
                            <input disabled={true} value={cycloResults.quantity_mt} />
                        </div>
                        <div>
                            <span style={{ marginInlineEnd: "1rem" }} className="unit">( م٢ )</span>
                            <input disabled={true} value={cycloResults.quantity_square} />
                        </div>
                        <div>
                            <span style={{ marginInlineEnd: "1rem" }} className="unit">%</span>
                            <input disabled={true} value={cycloResults.waste} />
                        </div>
                    </div>
                </div>
                <button
                    onClick={() => saveHandler(true)}
                    disabled={savedBtnStatus.saved || !savedBtnStatus.calculated}
                    className={(savedBtnStatus.saved || !savedBtnStatus.calculated || !checkFormValidation()) ? "cyclo-wanted-btn disabled" : "cyclo-wanted-btn"}>حفظ</button>
            </div>
        </div>
    )
}

export default Cyclo;