import React from 'react';
import { useLocation } from "react-router-dom";
import './sideMenu.scss';
import { ReactComponent as Client } from '../../assets/Path 4694.svg';
import { ReactComponent as Accounting } from '../../assets/accounting.svg';
import { ReactComponent as Paper } from '../../assets/paper.svg';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';

const SideMenu = () => {
    const currentUser = localStorage.getItem('currentUser')
    let location = useLocation();

    const getDivClass = (routeName) => {
        let clazz = '';
        clazz = location.pathname.includes(routeName) ? "side-menu-item show" : "side-menu-item"
        clazz = localStorage.getItem('identifier') !== null || sessionStorage.getItem('identifier') !== null ? clazz : `${clazz} disabled`
       
        return clazz;
    }

    return (
        <div className="side-menu">
            <div className={location.pathname.includes('client-data') ? "side-menu-item show" : "side-menu-item"}>
                <div className="borderDiv">
                    <Link className="side-menu-link" to="/data/client-data">
                        <span style={{ marginRight: '1.5rem' }}>بيانات العميل</span>
                        <Client className={location.pathname.includes('client-data') ? "active-icon" : "icon"} />
                    </Link>
                </div>
                <div className="pointer"></div>
            </div>
            {
                currentUser === 'employee' &&
                <div className={getDivClass('materials-cal')}>
                    <div className="borderDiv">
                        <Link className={localStorage.getItem('identifier') !== null ? "side-menu-link" : "side-menu-link disabled"}
                            to="/data/materials-cal/cyclo">
                            <span>حساب الخامات</span>
                            <Accounting className={location.pathname.includes('materials-cal') ? "active-icon" : "icon"} />
                        </Link>
                    </div>
                    <div className="pointer"></div>
                </div>
            }
            {
                currentUser === 'employee' &&
                <div className={getDivClass('total')}>
                    <div className="borderDiv no-border">
                        <Link className={localStorage.getItem('identifier') !== null ? "side-menu-link" : "side-menu-link disabled"}
                            to="/data/total-materials">
                            <span>اجمالي الخامات</span>
                        </Link>
                        <Paper className={location.pathname.includes('total') ? "active-icon" : "icon"} />
                    </div>
                    <div className="pointer"></div>
                </div>
            }
            {
                currentUser === 'client' &&
                // <div className={location.pathname.includes('room') ? "side-menu-item show" : "side-menu-item"}>
                <div className={getDivClass('room')}>
                    <div className="borderDiv no-border">
                        <Link className="side-menu-link" to="/data/room">
                            <span style={{ marginRight: '2rem' }}>بيانات الغرف</span>
                        </Link>
                        <Paper className={location.pathname.includes('room') ? "active-icon" : "icon"} />
                    </div>
                    <div className="pointer"></div>
                </div>
            }
        </div>
    )
}

export default SideMenu;