import React from 'react';
import './userData.scss';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import ClientData from '../../pages/clientData/clientData';
import MaterialsCal from '../../components/materials/materialsCal';
import TotalMaterials from '../../pages/totalMaterials/totalMaterials';
import SideMenu from '../../components/sideMenu/sideMenu';
import Room from '../room/room';
import UserInfo from '../user-info/user-info';
import { ProtectedRoute } from '../../auth/protected-route';
import NotFound from '../../components/not-found/not-found';

const UserData = () => {
    let { path } = useRouteMatch();
    return (
        <div className="user-data-container row">
            <div className="col-sm-12 col-md-9 col-lg-2">
                <SideMenu></SideMenu>
            </div>
            <div className="col-sm-12 col-md-9 col-lg-10">
                <Switch>
                    <Route path={path} exact>
                        <Redirect to={`${path}/client-data`} />
                    </Route>

                    <Route path={`${path}/client-data`} component={ClientData} />
                    <ProtectedRoute path={`${path}/materials-cal/:id/:clientId`} component={MaterialsCal} />
                    <ProtectedRoute path={`${path}/materials-cal/:id`} component={MaterialsCal} />
                    <ProtectedRoute path={`${path}/total-materials`} component={TotalMaterials} />
                    <Route path={`${path}/room`} component={Room} />

                    <Route path="**" component={NotFound}></Route>
                </Switch>
            </div>
        </div>
    )

}
export default UserData;