import React, {useEffect,useRef} from 'react';
import "./privacy-policy.scss"
const PrivacyPolicyComponent = (props) => {
    const myRef = useRef(null);
    const list1=[
        'سياسة الخصوصية لدينا هي طريقة جمعنا وحفظنا واستخدامنا وطريقة حماية معلوماتك الشخصية ونعني بمعلومات الشخصية هي المعلومات التي ترتبط بشخص معين والتي تستخدم في التعريف عنه',
        'نقوم بجمع المعلومات الشخصية من الراغبين بإستخدام موقع ديم سمارت او صفحات الانترنت المرتبطة. وبمجرد تزويدك لنا بمعلوماتك الشخصية تكون قد فوضتنا لمعالجة هذه المعلومات وفق بنود وشروط (سياسة الخصوصية)'
    ,'قد نقوم بتعديل (سياسة الخصوصية) في اي وقت وذلك من خلال الإعلان عن هذا التعديل بنشر النسخة المعدلة على الموقع. وتكون النسخة المعدلة من (سياسة الخصوصية) سارية المفعول اعتبارا من تاريخ نشرها. في اعقاب النشر يعتبر استمرارك في استخدام الموقع موافقة منك بالشروط والبنود الواردة في النسخة المعدلة لطريقة معالجتنا وتعاملنا مع معلوماتك الشخصية التي كنت قد زودتنا بها'
    ,'نأمل منكم الإطلاع على الموقع من حين لأخر للإعلانات عن اية تعديلات على سياسة الخصوصية'
    ]
    const list2=[
        'كجزء من التسجيل على الموقع أو استخدامه سوف يطب منك تزويدنا بمعلومات شخصية محددة مثل اسمك وعنوانك وبريدك الالكتروني و رقم هاتفك ومعلومات اخرى مشابهة وبعض المعلومات الاضافية عنك مثل تاريخ ميلادك أو اي معلومات محددة لهويتك. إضافة إلى ذلك وبهدف توثيقنا لهويتك'
   ,'قد نحتاج ايضا الى جمع معلومات مالية محددة منك، مثلا بطاقتك الائتمانية و/أو تفاصيل حسابك المصرفي'
    ,'يجب عليك ادخال هذه المعلومات المالية عند اتمام عملية الدفع على الموقع. وسوف نستخدم هذه المعلومات لغايات اصدار الفوانير واسنكمال طلباتك. عند تسجيلك في الموقع، ينبغى عليك عدم نشر اي معلومات شخصية (بما في ذلك اي معلومات مالية) على اي جزء من الموقع باستثناء جزء" إدارة الحساب" أو صفحة اتمام الاوردر وهذا من شأنه حمايتك من امكانية تعرضك للاحتيال او سرقة معلومات هويتك'
    ,'سوف يتم جمع معلومات عن عملياتك وأنشطتك على الموقع'
        ,' يرجى ملاحظة أننا قد نستخدم عنوان بروتوكول الإنترنت (أو IP) الخاص بك (رقم فريد يتم تعيينه لخادم الكمبيوتر أو مزود خدمة الإنترنت أو مزود خدمة الإنترنت) لتحليل أنشطتك وتحسين إدارة الموقع.'
    ,'قد نجمع معلومات اضافية حول جهاز الكمبيوتر الخاص بك (على سبيل المثال نوع المتصفح) ومعلومات التصفح (على سبيل المثال الصفحات التي تزورها على الموقع) بالإضافة إلى عدد المرات التي تستخدم بها الموقع'
    ,'أخيرًا، قد نجمع معلومات إضافية منك أو عنك بطرق أخرى غير موصوفة هنا تحديدًا. على سبيل المثال، قد نقوم بجمع المعلومات المتعلقة بتواصلك مع فريق دعم العملاء أو من خلال اجابتك على الاستبيانات. قد نجمع أيضًا التقييمات والتعليقات الأخرى المتعلقة باستخدامك للموقع. عندما نقوم بتجميع المعلومات الشخصية للأغراض الإحصائية، يجب أن تكون أسماء أصحاب المعلومات مجهولة'
    ]
    const list3=[
        'نستخدم معلوماتك الشخصية لتقديم خدمات ودعم من فريق العملاء وبهدف قياس مستوى خدماتنا وتحسينها لك ومنع النشاطات غير القانونية وتنفيذ لبنود اتفاقية الاستخدام الموفقة معك ("اتفاقية الاستخدام")، اضافة الى حل المشاكل وجمع الرسوم وتزويدك برسائل الكترونية ترويجية وكذلك من اجل توثيق المعلومات التي زودتنا بها مع اطراف ثالثة مثلا قد نلجأ الى مشاركة بعض من معلوماتك الشخصية مع البنوك او التفويض لبطاقات الائتمان لمعالجة وتوثيق خدمات أو مع اطراف ثالثة لغايات التحقق من عمليات الاحتيال'
    ,'برغم حرصنا للمحافظة على سرّيتك، الا اننا قد نحتاج الى الافصاح عن معلوماتك الشخصية لاجهزة تنفيذ القانون والهيئات الحكومية او اطراف ثالثة، نكون ملزمين بفعل ذلك باوامر من المحكمة اوغيرها من الدوائر القانونية لنكون ملتزمين ببنود القانون أو عند اعتقادنا ان الافصاح عن معلوماتك الشخصية ربما يقي من أذى جسدي او خسارة مالية او للاخبار عن نشاط مشبوه او للتحقيق في امكانية انتهاك لبنود وشروط اتفاقية المستخدم واية اتفاقيات أو لحماية الحقوق أو ملكية أو سلامة أومستخدمينا أو الغير'
    ,'سيتم عرض المعلومات المتعلقة بالمنتجات التي تقوم بشرائها على الموقع. يمكن أن تتضمن هذه المعلومات تفاصيل حول هوية المستخدم الخاصة بك والتقييمات والتعليقات المرتبطة باستخدامك للموقع'
    ,'لا نبيع أو نؤجر اي من معلوماتك الشخصية لطرف ثالث ضمن نطاق عملنا التجاري المعتاد وسوف نشارك فقط معلوماتك الشخصية فقط وفق ما جاء في (سياسة الخصوصية) هذا'
    ,'بمجرد تسجيلك في أو استخدامك للموقع تعتبر أنك أعطيتنا تفويضا واضحا لتسلمك رسائل إلكترونية ترويجية حول خدماتنا إضافة الى رسائل إلكترونية بخصوص التغيرات والملامح الجديدة على الموقع ، بحال عدم رغبتك في تلقي مثل هذه الرسائل ,ماعليك سوى إختيار الرابط الخاص بإيقاف إرسال هذه الرسائل إلى عنوانك البريدي والمتوفر في اسفل اي من هذه الرسائل الإلكترونية'
    ,'بالاضافة الى ذلك نقوم باستخدام ملاحظاتك حول الموقع لغايات التسويق ومن خلال تقديمك لهذه الملاحظات تكون وافقت على أن نستخدم هذه الملاحظات لغايات التسويق']

    const list4=['باستطاعتك الوصول ومراجعة معلوماتك الشخصية من خلال صفحتك الشخصية على الموقع، فاذا تغيرت معلوماتك الشخصية باي طريقة او اعطيت معلومات غير صحيحة على الموقع فعليك القيام بتحديثها او تصحيحها حالا، من خلال الاتصال بفريق خدمة العملاء. موضح على الموقع رقم التليفون والبريد الاكتروني الخاصين بخدمة العملاء'
    ,'يرجى العلم أننا سوف نحتفظ بمعلوماتك الشخصية خلال وبعد انتهائك من استخدام الموقع بحسب ما هو مطلوب قانونا، وذلك بهدف التغلب على العوائق التقنية ,ولمنع الاحتيال، و للمساعدة في اي تحقيق قانوني ولاتخاذ اي اجراءات اخرى ينص عليها القانون'
    ]
    const list5=[
        'مثل العديد من مواقع الانترنت فان موقعنا يستخدم ملفات تعريف الإرتباط وهي ملفات حاسوب صغيرة يتم تثبيتها على الجزء الصلب من حاسوبك، فحين تذهب لزيارة صفحات انترنت معينة على الموقع, تتعرف السجل على المتصفح الخاص بك من خلال رقم فريد وعشوائي'
    ,'كما ان ملفات تعريف الإرتباط التي نستخدمها لا تكشف اي من معلوماتك الشخصية.ان ملفات تعريف الإرتباط هذه تساعدنا في تحسين ادائك على الموقع وتعيننا على تفهم اي اجزاء من الموقع هي الاكثر استخداما,انت لك مطلق الحرية في الغاء هذه ملفات تعريف الإرتباط اذا أتيح لك ذلك من المتصفح ولا يؤثر ذلك على استخدامك للموقع'

    ]
    const list6=[
        'عندما تزودنا بمعلوماتك الشخصية نتخذ كافة الاحتياطات للمحافظة على معلوماتك وذلك بعدم الوصول اليها او استخدامها او الافصاح عنها بشكل غير مخول'
            ,'كافة المعلومات الشخصية مشفرة، وعلى اي حال فان الانترنت وسيلة غير امنة ونحن لا نضمن سرية معلوماتك الشخصية'
    ,'يجب ان يتم ادخال اسم المستخدام وكلمة السر في كل مرة عند الدخول الى حسابك أو القيام باية معامللات في الموقع'
    ,'يجب ان يتم اختيار كلمة السر بحذر باستخدام ارقام وحروف واشكال مميزة'
    ,'لا تشارك اسم المستخدم وكلمة السر مع اي احد'
    ,'في حال كان لديك شك حول اختراق اسم المستخدم وكلمة السر، يرجى على الفور الاتصال بفريق خدمة العملاء والعمل على تغيير كلمة السر وذلك بالدخول الى صفحتك الشخصية على الموقع'
    ]
   // useEffect(()=>{
   //     console.log(("priv",myRef))
   //     myRef.current.scrollTo(0, 0);
   // },[])
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return(<>

        <div className='container policy' dir="rtl" ref={myRef}>

            <h4 className='heading'>  سياسة الخصوصية </h4>

            <ul>
                {list1&&list1.map((el,i)=><li key={i}>{el}</li>)}


            </ul>

            <p className='desc'>
                جمعنا لمعلوماتك الشخصية
            </p>
            <ul>
                {list2&&list2.map((el,i)=><li key={i}>{el}</li>)}


            </ul>
            <p className='desc'>
                استخدامنا لمعلوماتك الشخصية
            </p>
            <ul>
                {list3&&list3.map((el,i)=><li key={i}>{el}</li>)}


            </ul>
            <p className='desc'>
                إستخدام ودخول وتصفح وتعديل معلوماتك الشخصية            </p>
            <ul>
                {list4&&list4.map((el,i)=><li key={i}>{el}</li>)}


            </ul>
            <p className='desc'>
                  ملفات تعريف الارتباط (cookies)
            </p>
            <ul>
                {list5&&list5.map((el,i)=><li key={i}>{el}</li>)}


            </ul>
        </div>
    </>)
}

export default PrivacyPolicyComponent;
