import React, { useState } from 'react';
import "./client-search.scss"
import { useHistory } from "react-router-dom";
import { useEffect } from 'react';

const ClientSearch = () => {
  let history = useHistory()

  const [val, setVal] = useState("")
  const onSearch = (e) => {
    e.preventDefault()
    localStorage.setItem('currentUser', 'client')

    history.push("/user-info/" + val);

  }
  return (<>


    <form className="form d-flex justify-content-center" onSubmit={(e) => onSearch(e)}>
      <button className="btn-search" type="submit" disabled={!val} >بحث</button>
      <input className="form-control mr-sm-2" type="search" placeholder="بحث" aria-label="Search" value={val} onChange={(e) => setVal(e.target.value)} />
    </form>

  </>)

}
export default ClientSearch